
import { useSelector } from 'react-redux';
import { Invoice } from '../../../../../../../models/invoice.model';
import { AppState } from '../../../../../../../store/app-state';
import { CompanyName, Container, Contract, Label, PlanTypeTitle } from './plan-card.styled';

interface PlanCardProps {
    invoice?: Invoice | null;
    loading?: boolean;
}

const PlanCard: React.FC<PlanCardProps> = ({ invoice, loading }) => {
    const user = useSelector((state: AppState) => state.authState.user);

    return (
        <Container>
            <PlanTypeTitle>
                {loading ? 'Carregando...' : invoice?.subscription?.plan}
            </PlanTypeTitle>

            <CompanyName>
                {!loading && user?.name}
            </CompanyName>

            <Contract>
                <Label>{!loading && 'Contrato'}</Label>
                {!loading && invoice?.number}
            </Contract>
        </Container>
    );
}

export default PlanCard;
