import { useSelector } from "react-redux";
import { AppState } from "../store/app-state";

export interface UseCandidateStatusProps {
    candidateId: string;
}

const useCandidateStatus = ({ candidateId }: UseCandidateStatusProps) => {
    return useSelector((appState: AppState) => appState.eventState.candidatesStatus[candidateId]);
};

export default useCandidateStatus;
