import { useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Container, MessageContainer, Backdrop } from "./index.styled";

const ToolTip: React.FC<{message: string, error?: boolean}> = ({ message, error }) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <Container error={error}>
            {open && (<Backdrop onClick={() => setOpen(false)}/>)}
            <AiOutlineQuestionCircle onClick={() => setOpen(!open)}/>
            <MessageContainer open={open} error={error}>
                <span className="message">{message}</span>
            </MessageContainer>
        </Container>
    );
}

export default ToolTip;