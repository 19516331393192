import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import ApplicationRoutes from './routes/application-routes';
import { persistor, store } from './store/store-config';
import './styles/global.scss';
import GlobalStyle from './styles/global.styled';
import { DialogProvider } from './contexts/dialog';

import MAIN_THEME from './styles/themes/main-theme';

const App: React.FC = () => {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={MAIN_THEME}>
          <DialogProvider>
            <GlobalStyle />
            <ApplicationRoutes />
          </DialogProvider>
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;
