import React from 'react';

// import { Container } from './styles';

const LockIco: React.FC = () => {
    return (
        <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.33268 5.33464V4.0013C9.33268 2.1613 7.83935 0.667969 5.99935 0.667969C4.15935 0.667969 2.66602 2.1613 2.66602 4.0013V5.33464H0.666016V14.668H11.3327V5.33464H9.33268ZM3.99935 4.0013C3.99935 2.89464 4.89268 2.0013 5.99935 2.0013C7.10602 2.0013 7.99935 2.89464 7.99935 4.0013V5.33464H3.99935V4.0013ZM9.99935 13.3346H1.99935V6.66797H9.99935V13.3346ZM5.99935 11.3346C6.73268 11.3346 7.33268 10.7346 7.33268 10.0013C7.33268 9.26797 6.73268 8.66797 5.99935 8.66797C5.26602 8.66797 4.66602 9.26797 4.66602 10.0013C4.66602 10.7346 5.26602 11.3346 5.99935 11.3346Z" fill="#6A6A6A" />
        </svg>
    );
}

export default LockIco;