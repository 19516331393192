import styled, { css } from 'styled-components';
import { Backdrop as BackdropModal } from '../../../../../components/modal/modal.styled';


interface ContainerProps {
    theme: Object;
    width?: number;
    isShown: boolean;
}

export const Container = styled.div<ContainerProps>`
    background-color: ${(props) => props.theme.containers.cards.backgroundColor};
    border-radius: 0.625rem;
    border: 0;
    bottom: 0;
    font-size: 1rem;
    height: -webkit-fill-available;
    left: 0;
    margin: 0 auto;
    max-width: ${(props) => props.width}px;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: translate(-100%, 100%); 
    transition: all 0.2s ease-in-out;
    visibility: hidden;
    z-index: 100;

    ${({ isShown }) => isShown && css`
        visibility: visible;
        transform: translate(0, 0); 
        padding: 0;
        top: 8.75rem;
        right: 2.625rem;
        bottom: -8rem;
    `};
`;


export const Backdrop = styled(BackdropModal)`
    z-index: 2;
`;

export const HeaderDrawer = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding: 0;
    height: 3.125rem;
`;

export const CloseButton = styled.button`
    border: 0;
    background: 0;
    position: absolute;
    right: 1rem;
    top: 1rem;
    padding: 0;
    z-index: 1;
`;

export const Content = styled.div`
    overflow: auto;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 8.75rem;
    right: 1.5rem;

        
    &::-webkit-scrollbar-track {
        background: transparent;
        width: 1rem;
        height: 100px;
        margin-top: 3rem;
        
    }

    &::-webkit-scrollbar {
        background: transparent;
        border-radius: 50px;
        width: 0.3125rem;

    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0.625rem;
        background-color: #D9D9D9;
    }
`;