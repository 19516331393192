import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RiCheckboxCircleLine, RiCloseLine, RiErrorWarningLine, RiQuestionLine, RiSendPlaneFill } from "react-icons/ri";
import Error from "../error";
import Confirm from "./components/confirm-element";
import Loading from "./components/loading-element";
import Success from "./components/success-element";
import { Backdrop, CloseButton, Header, ModalContainer } from "./index.styled";
import { JobInterface } from "../../job-board-form.model";


interface ConfirmModalProps {
    onAccept: () => void;
    onDecline: () => void;
    job: JobInterface;
    new_job_id?: string;
    loading: boolean;
    error: boolean;
}
const ConfirmModal: React.FC<ConfirmModalProps> = ({ onAccept, onDecline, job, loading, new_job_id, error }) => {
    const { t } = useTranslation('private-home-page');

    const [header, currentElement, icon]: [string, JSX.Element, JSX.Element?] = useMemo(() => {
        if(loading) {
            return ['loading', <Loading />, <RiSendPlaneFill />];
        }
        if (error) {
            return ['error', <Error message={t('job-board.confirm-modal.error')}/>, <RiErrorWarningLine />];
        }
        if (!!new_job_id) {
            return ['success', <Success job_id={new_job_id}/>, <RiCheckboxCircleLine />];
        }
        return [
            'confirm',
            <Confirm job={job} onAccept={onAccept} onDecline={onDecline} />,
            <RiQuestionLine />
        ];
    }, [loading, error, new_job_id, job, onAccept, onDecline, t]);

    return (<>
        <Backdrop onClick={onDecline} />
        <ModalContainer>
            <Header>
                <div className="icon-label">
                    {icon}
                    {t(`job-board.confirm-modal.title.${header}`)}
                </div>
                {!loading && (<CloseButton onClick={onDecline}><RiCloseLine /></CloseButton>)}
            </Header>
            {currentElement}
        </ModalContainer>
    </>);
}

export default ConfirmModal;