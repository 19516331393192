import React from 'react';
import Header, { HeaderItem } from './components/header';
import { ContainerTable } from './index.styled';

interface TableProps {
    headerItems: HeaderItem[];
    children: React.ReactNode;
}
const Table: React.FC<TableProps> = ({ headerItems, children }) => {
    return (
        <ContainerTable>
            <Header headerItems={headerItems}/>
            {children}
        </ContainerTable>
    );
}

export default Table;