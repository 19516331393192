import styled from 'styled-components';

export const Content = styled.div`
`;


export const TitleAbout = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const BulletTitle = styled.h5`
  align-items: center;
  color: #272727;
  display: flex;
  font-size: 1rem;
  margin: 0.75rem 0 0.625rem;
  padding-left: 0.75rem;
  position: relative;

  &:before {
    content: "";
    width: 0.375rem;
    height: 0.375rem;
    background: #452470;
    border-radius: 50%;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
  }
`;