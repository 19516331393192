import User from '../../models/user.model';
import { ReducerBuilder } from "../../utils/redux/reducer-builder";
import { GlobalTypes } from './../global-types';
import { AuthActionTypes, AuthState, INITIAL_STATE } from "./auth-types";

const reduxBuilder = new ReducerBuilder<AuthState>(INITIAL_STATE);

reduxBuilder.addReducer(GlobalTypes.CLEAR, () => {
    return INITIAL_STATE;
});

reduxBuilder.addMutableReducer<{ token: string, user: User }>(AuthActionTypes.UPDATE_AUTHENTICATION_TOKEN, (state, payload) => {
    state.authenticated = true;
    state.token = payload.token;
    state.user = payload.user;
});

export default reduxBuilder.build();
