import { JobBoardFormOptions, JobInterface, JobListInterface } from "../../pages/private/job-board/job-board-form.model";
import { ReducerBuilder } from "../../utils/redux/reducer-builder";
import { City, INITIAL_STATE, JobBoardActionTypes, JobBoardState } from "./job-board-types";

const reduxBuilder = new ReducerBuilder<JobBoardState>(INITIAL_STATE);

reduxBuilder.addMutableReducer(JobBoardActionTypes.FETCH_FORM_OPTIONS_DATA, (state) => {
    state.formOptions.data = INITIAL_STATE.formOptions.data;
    state.formOptions.loading = true;
    state.formOptions.error = null;
});

reduxBuilder.addMutableReducer<JobBoardFormOptions>(JobBoardActionTypes.FETCH_FORM_OPTIONS_DATA_SUCCESS, (state, payload) => {
    state.formOptions.loading = false;
    state.formOptions.data = payload;
});

reduxBuilder.addMutableReducer<any>(JobBoardActionTypes.FETCH_FORM_OPTIONS_DATA_ERROR, (state, payload) => {
    state.formOptions.data = INITIAL_STATE.formOptions.data;
    state.formOptions.loading = false;
    state.formOptions.error = payload;
});

reduxBuilder.addMutableReducer(JobBoardActionTypes.FETCH_CITIES_DATA, (state) => {
    state.cities.data = [];
    state.cities.loading = true;
    state.cities.error = null;
});

reduxBuilder.addMutableReducer<City[]>(JobBoardActionTypes.FETCH_CITIES_DATA_SUCCESS, (state, payload) => {
    state.cities.loading = false;
    state.cities.data = payload;
});

reduxBuilder.addMutableReducer<any>(JobBoardActionTypes.FETCH_CITIES_DATA_ERROR, (state, payload) => {
    state.cities.data = [];
    state.cities.loading = false;
    state.cities.error = payload;
});

reduxBuilder.addMutableReducer(JobBoardActionTypes.CLEAR_CURRENT_JOB, (state) => {
    state.currentJob.data = undefined;
    state.currentJob.loading = false;
    state.currentJob.error = null;
});

reduxBuilder.addMutableReducer(JobBoardActionTypes.FETCH_JOB_LIST_DATA, (state) => {
    state.jobs.data = [];
    state.jobs.loading = true;
    state.jobs.error = null;
});

reduxBuilder.addMutableReducer<JobListInterface[]>(JobBoardActionTypes.FETCH_JOB_LIST_DATA_SUCCESS, (state, payload) => {
    state.jobs.loading = false;
    state.jobs.data = payload;
});

reduxBuilder.addMutableReducer<any>(JobBoardActionTypes.FETCH_JOB_LIST_DATA_ERROR, (state, payload) => {
    state.jobs.data = [];
    state.jobs.loading = false;
    state.jobs.error = payload;
});

reduxBuilder.addMutableReducer(JobBoardActionTypes.FETCH_JOB_DETAIL_DATA, (state) => {
    state.currentJob.data = undefined;
    state.currentJob.loading = true;
    state.currentJob.error = null;
});

reduxBuilder.addMutableReducer<JobInterface>(JobBoardActionTypes.FETCH_JOB_DETAIL_DATA_SUCCESS, (state, payload) => {
    state.currentJob.loading = false;
    state.currentJob.data = payload;
});

reduxBuilder.addMutableReducer<any>(JobBoardActionTypes.FETCH_JOB_DETAIL_DATA_ERROR, (state, payload) => {
    state.currentJob.data = undefined;
    state.currentJob.loading = false;
    state.currentJob.error = payload;
});

reduxBuilder.addMutableReducer(JobBoardActionTypes.CREATE_JOB_DATA, (state) => {
    state.currentJob.data = undefined;
    state.currentJob.loading = true;
    state.currentJob.error = null;
});

reduxBuilder.addMutableReducer<JobInterface>(JobBoardActionTypes.CREATE_JOB_DATA_SUCCESS, (state, payload) => {
    state.currentJob.loading = false;
    state.currentJob.data = payload;
});

reduxBuilder.addMutableReducer<any>(JobBoardActionTypes.CREATE_JOB_DATA_ERROR, (state, payload) => {
    state.currentJob.data = undefined;
    state.currentJob.loading = false;
    state.currentJob.error = payload;
});

reduxBuilder.addMutableReducer(JobBoardActionTypes.CLOSE_JOB_DATA, (state) => {
    state.closeJob.data = false;
    state.closeJob.loading = true;
    state.closeJob.error = null;
});

reduxBuilder.addMutableReducer(JobBoardActionTypes.CLOSE_JOB_DATA_SUCCESS, (state) => {
    state.closeJob.data = true;
    state.closeJob.loading = false;
});

reduxBuilder.addMutableReducer<any>(JobBoardActionTypes.CLOSE_JOB_DATA_ERROR, (state, payload) => {
    state.closeJob.loading = false;
    state.closeJob.error = payload;
});

export default reduxBuilder.build();