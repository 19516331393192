import CheckAnimation from "../../../../../components/check-animation";
import { Container, Message } from "./index.styled";

const Success: React.FC<{ messages?: string[] }> = ({ messages }) => {
    return (
        <Container>
            <CheckAnimation />
            {messages && messages.map((message, key) => (
                <Message key={key}>{message}</Message>
            ))}
        </Container>
    );
}

export default Success;