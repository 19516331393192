import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { action } from 'typesafe-actions';
import { companyApi } from '../../api/talent/company-service';
import { logout } from '../auth/auth-actions';
import { talentApi } from './../../api/talent/talent-service';
import { AppState } from './../app-state';
import { CompanyActionTypes } from './company-types';

export const fetchLastInvoice = () => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(CompanyActionTypes.FETCH_LAST_INVOICE));
        try {
            const response = await companyApi.fetchLastInvoice();
            dispatch(action(CompanyActionTypes.FETCH_LAST_INVOICE_SUCCESS, response.data));
        } catch (error) {
            dispatch(action(CompanyActionTypes.FETCH_LAST_INVOICE_ERROR, error));
        }
    }
};

export const fetchCompanyPlan = () => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(CompanyActionTypes.FETCH_COMPANY_PLAN));
        try {
            const response = await talentApi.fetchTalentPlan();
            dispatch(action(CompanyActionTypes.FETCH_COMPANY_PLAN_SUCCESS, response.data));
        } catch (error) {
            dispatch(action(CompanyActionTypes.FETCH_COMPANY_PLAN_ERROR, error));
            dispatch(logout());
        }
    }
};
