import { Locale } from "date-fns";
import { ptBR, enUS, es } from "date-fns/locale";
import i18n from "i18next";

export const getLocale = (): Locale => {
  switch (i18n.language) {
    case "pt-BR":
      return ptBR;
    case "es":
      return es;
    case "en":
    default:
      return enUS;
  }
};
