import React from "react";

// import { Container } from './styles';

const ContactIco: React.FC = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.993C0.00183004 0.730378 0.1069 0.479017 0.292513 0.293218C0.478126 0.107418 0.72938 0.00209465 0.992 0H19.008C19.556 0 20 0.445 20 0.993V17.007C19.9982 17.2696 19.8931 17.521 19.7075 17.7068C19.5219 17.8926 19.2706 17.9979 19.008 18H0.992C0.728813 17.9997 0.476497 17.895 0.290489 17.7088C0.104482 17.5226 -1.33455e-07 17.2702 0 17.007V0.993ZM4 12V14H16V12H4ZM4 4V10H10V4H4ZM12 4V6H16V4H12ZM12 8V10H16V8H12ZM6 6H8V8H6V6Z"
        fill="#452470"
      />
    </svg>
  );
};

export default ContactIco;
