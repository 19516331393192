import { format } from "date-fns";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { JobInterface } from "../../job-board-form.model";
import { Container, Description, FieldNameContainer, Field, Name } from "./index.styled";

interface FieldNameProps {
    field: string;
    name: string;
}
const FieldName: React.FC<FieldNameProps> = ({ field, name }) => {
    return (
        <FieldNameContainer>
            <Field>{field}:</Field>
            <Name>{name}</Name>
        </FieldNameContainer>
    )
}

const JobDetail: React.FC<{ job: JobInterface }> = ({ job }) => {
    const { t } = useTranslation('private-home-page');
    const root = useCallback((key: string) => {
        return t(`job-board.form-fields.${key}`);
    }, [t]);
    return (
        <Container>
            <FieldName field={root('name')} name={job.name} />
            {!!job.owner && (<FieldName field={root('owner')} name={job.owner?.name} />)}
            <FieldName field={root('language')} name={job.language.name} />
            <FieldName field={root('end_date')} name={format(new Date(job.end_date), 'dd/MM/yyyy')} />
            <FieldName field={root('hire_type')} name={job.hire_type.name} />
            <FieldName field={root('work_model')} name={root(`work_models.${job.work_model.toLowerCase()}`)}  />
            {!!job.degree && (<FieldName field={root('degree')} name={job.degree.name} />)}
            <FieldName field={root('experience_time')} name={job.experience_time.name} />
            <FieldName field={root('experience_level')} name={job.experience_level.name} />
            {!!job.city && (<FieldName field={root('city')} name={job.city.name} />)}
            <FieldName field={root('country')} name={job.country.name} />
            <FieldName field={root('currency')} name={job.currency.name} />
            {!!job.min_salary && (<FieldName field={root('salary.min_salary')} name={job.min_salary} />)}
            {!!job.max_salary && (<FieldName field={root('salary.max_salary')} name={job.max_salary} />)}
            <FieldName field={root('skills')} name={job.skills.map(s => s.name).join(' - ')} />
            <FieldName field={root('diversity')} name={job.diversitys?.map(d => d.name).join(' - ')} />
            <FieldNameContainer style={{flexDirection: 'column'}}>
                <Field>{root('description')}:</Field>
                <Description dangerouslySetInnerHTML={{__html: job.description}} />
            </FieldNameContainer>
        </Container>
    );
}

export default JobDetail;