import styled from "styled-components";


export const Container = styled.div<{error?: boolean}>`
	margin: 0;
    padding: 0;
    display: flex;
    align-items: center;

    svg {
        cursor: pointer;
        ${({ error }) => error && 'color: #DD5147;'}
    }
    position: relative;
`;

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: none;
    z-index: 20;
`;

export const MessageContainer = styled.div<{open: boolean, error?: boolean}>`
    transition: 0.3s;
    visibility: ${({ open }) => open ? 'visible' : 'hidden'};
    opacity: ${({ open }) => open ? '1' : '0'};
    border: 1px solid ${({ error }) => error ? '#DD5147' : "#aaa"};
    border-radius: 0.25rem;
    padding: 0 0.25rem;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(102%, -70%);
    background: ${({ error }) => error ? '#FDF3F2' : "#fff"};
    width: 15rem;
    z-index: 10;

    .message {
        color: ${({ error }) => error ? '#DD5147' : "#888"};
        font-size: 0.75rem;
    }
`;
