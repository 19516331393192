import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow } from '../../index.styled';
import { HeadCell } from './index.styled';

export interface HeaderItem {
  value: string;
  width?: string;
};

interface HeaderProps {
  headerItems: HeaderItem[];
}
const Header: React.FC<HeaderProps> = ({ headerItems }) => {
  const { t } = useTranslation("private-home-page");
  const root = (key: string) => t(`ranking-table.headers.${key}`);

  return (
    <TableRow>
      {headerItems.map((item, index) => (
        <HeadCell width={item.width} key={`header-item-${index}`}>
          {root(item.value)}
        </HeadCell>
      ))}
    </TableRow>
  );
}

export default Header;