import { StorageData } from "../storage-data";

export interface TabChangedState {
    isChanged: StorageData<boolean | null, any>;
}

export const INITIAL_STATE: TabChangedState = {
    isChanged: {
        data: false,
        loading: false,
        error: null
    }
}

export enum TabChangedTypes {
    SET_IS_CHANGED = '@tabChanged/SET_IS_CHANGED'
}
