import React from "react";
import { Banner, Container, Languages, LogoDio } from "./header.styled";

interface HeaderPropos extends React.HTMLAttributes<HTMLDivElement> {
    banner?: string | null
}

const Header: React.FC<HeaderPropos> = ({ className, banner, ...props }) => {
    banner = banner || "/assets/img/banner-talent-match-week.png";
    return (
        <Container {...props} className={className}>
            <Banner src={banner} alt="Talent Match" />
            <Languages />
            <LogoDio src="https://hermes.digitalinnovation.one/assets/diome/logo.svg" alt="Digital Inovation One" />
        </Container>
    );
}

export default Header;