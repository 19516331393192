import React from "react";
import { useTranslation } from "react-i18next";
import { CandidateDetail } from "../../../../models/candidate-detail.model";
import WageClaim from "../../../wage-claim/wage-claim";
import { InformationLink, SectionBlock, Title } from "../../talent.styled";
import { Skill, SkillsList } from "../candidate-skills/candidate-skills.styled";

interface SpotlightProps {
  candidate?: CandidateDetail | null;
}

const Spotlight: React.FC<SpotlightProps> = ({ candidate }) => {
  const { t } = useTranslation("private-home-page");
  return (
    <SectionBlock>
      <Title>{t("talent.emphasis")}</Title>
      <SkillsList>
      {(candidate?.city) && (
        <Skill>{candidate?.city.name}/{candidate?.city.state}</Skill>
      )}
        {candidate &&
          candidate.diversity?.length > 0 &&
          candidate.diversity.map((diversity, index) => (
            diversity.name === "Prefiro não dizer" ? 
              null
            :            
              <Skill key={`diversity-${index}`}>{diversity.name}</Skill>
          ))}
        <Skill>{candidate?.experience_time}</Skill>
        <Skill>
          <WageClaim value={candidate?.wage_claim} />
        </Skill>
        {candidate?.hire_types &&
          candidate.hire_types.map((hireType, index) => (
            <Skill key={`hireTypes-${index}`}>{hireType}</Skill>
          ))}
        {candidate?.remote && <Skill>{t("talent.remoteWork")}</Skill>}
        {candidate?.work_model_presencial && (
          <Skill>{t("talent.presentialWork")}</Skill>
        )}
        {candidate?.work_model_hybrid && (
          <Skill>{t("talent.hybridWork")}</Skill>
        )}
        {candidate?.track_tests && (
          <InformationLink target="_blank" href={candidate?.track_tests}>
            {t("talent.viewChallenge")}
          </InformationLink>
        )}
      </SkillsList>
    </SectionBlock>
  );
};

export default Spotlight;
