import React from "react";
import { CandidateDetail } from "../../../../models/candidate-detail.model";
import { SectionBlock } from "../../talent.styled";
import Avatar from "../avatar/avatar";
import { LockedContent } from "../locked-content/locked-content";
import {
  SocialIcon,
  SocialNetwork,
  SocialNetworkLink,
  SocialNetworkList
} from "../social-networks/social-networks.styled";
import Contact from "./../../components/contacts/contacts";
import { Content } from "./header.styled";
interface HeaderProps {
  candidate?: CandidateDetail | null;
  isLocked: boolean;
}

const Header: React.FC<HeaderProps> = ({ candidate, isLocked }) => {
  return (
    <Content>
      <SectionBlock header="header">
        <Avatar candidate={candidate} isLocked={isLocked} />
      </SectionBlock>
      {candidate?.linkedin && (
        <SectionBlock header="header">
          {isLocked ? (
            <>
              <LockedContent />
            </>
          ) : (
            <SocialNetworkList>
              {candidate?.linkedin && (
                <SocialNetwork>
                  <SocialNetworkLink
                    href={candidate.linkedin}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SocialIcon
                      src="/assets/img/linkedin.svg"
                      title="LinkedIn"
                    />
                  </SocialNetworkLink>
                </SocialNetwork>
              )}
            </SocialNetworkList>
          )}
        </SectionBlock>
      )}
      {candidate?.github && (
        <SectionBlock header="header">
          {isLocked ? (
            <>
              <LockedContent />
            </>
          ) : (
            <SocialNetworkList>
              {candidate?.github && (
                <SocialNetwork>
                  <SocialNetworkLink
                    href={candidate.github}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SocialIcon src="/assets/img/github.svg" title="Github" />
                  </SocialNetworkLink>
                </SocialNetwork>
              )}
            </SocialNetworkList>
          )}
        </SectionBlock>
      )}

      <SectionBlock header="header">
        <Contact candidate={candidate} isLocked={isLocked} />
      </SectionBlock>
    </Content>
  );
};

export default Header;
