import styled, { keyframes } from "styled-components";

export const rotate = keyframes`
   0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

export const Container = styled.div`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    .loading-part:nth-child(1) {
        animation-delay: -0.45s;
    }

    .loading-part:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .loading-part:nth-child(3) {
        animation-delay: -0.15s;
    }
`;

export const Loading = styled.div`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #452470;
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #452470 transparent transparent transparent;
`;