
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { action } from "typesafe-actions";
import { talentApi } from "./../../api/talent/talent-service";
import { AppState } from "./../app-state";
import { ApplicationActionTypes } from "./application-types";


export const fetchProfessionalMoment = () => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
      dispatch(action(ApplicationActionTypes.FETCH_PROFESSIONAL_MOMENTS));
      try {
        const response = await talentApi.fetchProfessionalMoment();
        dispatch(
          action(
            ApplicationActionTypes.FETCH_PROFESSIONAL_MOMENTS_SUCCESS,
            response.data
          )
        );
      } catch (error) {
        dispatch(
          action(
            ApplicationActionTypes.FETCH_PROFESSIONAL_MOMENTS_ERROR,
            error
          )
        );
      }
    };
  };