import { AxiosResponse } from "axios";
import { JobBoardFormFields, JobBoardFormOptions, JobInterface, JobListInterface } from "../../pages/private/job-board/job-board-form.model";
import { City } from "../../store/job-board/job-board-types";
import talentApi from "./talent-api-config";

class JobBoardApi {
    async fetchFormOptions(): Promise<AxiosResponse<JobBoardFormOptions[]>> {
        return talentApi.get<JobBoardFormOptions[]>(`/jobs/talent/form-options/`);
    }

    async fetchCities(name: string): Promise<AxiosResponse<City[]>> {
        return talentApi.get<City[]>(`/cities/?name=${name}`);
    }

    async fetchJobs(): Promise<AxiosResponse<JobListInterface[]>> {
        return talentApi.get<JobListInterface[]>(`/jobs/talent/list/`);
    }

    async fetchJobDetail(id: string): Promise<AxiosResponse<JobListInterface[]>> {
        return talentApi.get<JobListInterface[]>(`/jobs/${id}/talent/`);
    }

    async createJob(jobData: JobBoardFormFields): Promise<AxiosResponse<JobInterface>> {
        return  talentApi.post<JobInterface>('/jobs/', jobData);
    }

    async closeJob(id: string): Promise<AxiosResponse<string>> {
        return talentApi.post<string>(`/jobs/${id}/close/`);
    }
}

export const jobBoardApi = new JobBoardApi();
