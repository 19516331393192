import { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { RiAlertLine, RiArrowLeftLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { action } from "typesafe-actions";
import { AppState } from "../../../store/app-state";
import { createJob } from "../../../store/job-board/job-board-actions";
import { JobBoardActionTypes } from "../../../store/job-board/job-board-types";
import ConfirmModal from "./components/confirm-modal";
import Form from "./components/form";
import { FORM_INITIAL_STATE } from "./components/form/form.data";
import { JobInterface } from "./job-board-form.model";
import { convertJobToPostBody } from "./job-board-form.utils";
import { Back, FormMainContainer, FormMessage, Title } from "./job-board-page.styled";

const JobBoardFormPage: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const root = useCallback((key: string) => {
        return t(`private-home-page:job-board.${key}`);
    }, [t]);
    const [fields, setFields] = useState<JobInterface>(FORM_INITIAL_STATE);
    const [showModal, setShowModal] = useState<boolean>(false);
    const { data: currentJob, loading, error } = useSelector((store: AppState) => store.jobBoardState.currentJob);

    const onSubmit = useCallback(() => {
        dispatch(createJob(convertJobToPostBody(fields)));
    },  [dispatch, fields]);

    useEffect(() => {
        dispatch(action(JobBoardActionTypes.CLEAR_CURRENT_JOB));
    }, [dispatch]);

    return (
        <FormMainContainer>
            {showModal && (
                <ConfirmModal 
                    onAccept={onSubmit} 
                    onDecline={() => setShowModal(false)}
                    job={fields} 
                    loading={loading}
                    new_job_id={currentJob?.id}
                    error={!!error}
                />
            )}
            <Back onClick={() => history.push(`/jobs`)}>
                <RiArrowLeftLine />{root('form-back')}
            </Back>
            <Title>{root('form-title')}</Title>    
            <FormMessage><RiAlertLine />{root('no_edit_message')}</FormMessage>
            <Form fields={fields} setFields={setFields} onSubmit={() => setShowModal(true)} />
        </FormMainContainer>
    );
}

export default JobBoardFormPage;