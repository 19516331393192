import User from "../../models/user.model";

export interface AuthState {
    authenticated: boolean;
    token: string | null;
    user: User | null;
}

export const INITIAL_STATE: AuthState = {
    authenticated: false,
    token: null,
    user: null,
}

export enum AuthActionTypes {
    UPDATE_AUTHENTICATION_TOKEN = '@auth/UPDATE_AUTHENTICATION_TOKEN',
}
