import { ReducerBuilder } from "../../utils/redux/reducer-builder";
import {
  ApplicationActionTypes,
  ApplicationState,
  ProfessionalMoment,
} from "../application/application-types";

import { INITIAL_STATE } from "./application-types";

const reduxBuilder = new ReducerBuilder<ApplicationState>(INITIAL_STATE);

reduxBuilder.addMutableReducer<ProfessionalMoment>(
  ApplicationActionTypes.FETCH_PROFESSIONAL_MOMENTS,
  (state) => {
    state.professionalMomentData.data = null;
    state.professionalMomentData.loading = true;
  }
);

reduxBuilder.addMutableReducer<ProfessionalMoment[]>(
  ApplicationActionTypes.FETCH_PROFESSIONAL_MOMENTS_SUCCESS,
  (state, payload) => {
    state.professionalMomentData.loading = false;
    state.professionalMomentData.data = payload;
  }
);

reduxBuilder.addMutableReducer<string>(
  ApplicationActionTypes.FETCH_PROFESSIONAL_MOMENTS_ERROR,
  (state, payload) => {
    state.professionalMomentData.loading = false;
    state.professionalMomentData.error = payload;
  }
);

export default reduxBuilder.build();
