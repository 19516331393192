import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../app-state";
import { action } from 'typesafe-actions';
import { LoadActionTypes } from "./load-types";

export const fetchSetVisible = () => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(LoadActionTypes.SET_VISIBLE));
    }
};

export const fetchSetInvisible = () => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(LoadActionTypes.SET_INVISIBLE));
    }
};
