import { CandidateQuery } from './../../api/talent/models/candidates-query.model';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { action } from 'typesafe-actions';
import CandidateStatus from '../../models/candidate-status.model';
import EventTrackStatistics from '../../models/event-track-statistics.model';
import { eventApi } from './../../api/talent/event-service';
import { AppState } from './../app-state';
import { EventActionTypes } from './event-types';

export const fetchEventData = (eventSlug: string) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(EventActionTypes.FETCH_EVENT_DATA));
        try {
            const response = await eventApi.fetchEventData(eventSlug);
            dispatch(action(EventActionTypes.FETCH_EVENT_DATA_SUCCESS, response.data));
        } catch (error) {
            dispatch(action(EventActionTypes.FETCH_EVENT_DATA_ERROR, error));
        }
    }
};

export const fetchSelectedCandidates = (eventSlug: string, query: CandidateQuery) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(EventActionTypes.FETCH_SELECTED_CANDIDATES));
        try {
            const response = await eventApi.fetchSelectedCandidates(eventSlug, query);
            dispatch(action(EventActionTypes.FETCH_SELECTED_CANDIDATES_SUCCESS, response.data));
        } catch (error) {
            dispatch(action(EventActionTypes.FETCH_SELECTED_CANDIDATES_ERROR, error));
        }
    }
}

export const fetchEventTracks = (eventSlug: string) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(EventActionTypes.FETCH_EVENT_TRACKS));
        try {
            const response = await eventApi.fetchEventTracks(eventSlug);
            dispatch(action(EventActionTypes.FETCH_EVENT_TRACKS_SUCCESS, response.data));
        } catch (error) {
            dispatch(action(EventActionTypes.FETCH_EVENT_TRACKS_ERROR, error));
        }
    }
};

export const updateCurrentEventTrackData = (trackUuid: string) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(EventActionTypes.FETCH_EVENT_TRACK));
        try {
            const response = await eventApi.fetchEventTrack(trackUuid);
            dispatch(action(EventActionTypes.FETCH_EVENT_TRACK_SUCCESS, response.data));
        } catch (error) {
            dispatch(action(EventActionTypes.FETCH_EVENT_TRACK_ERROR, error));
        }
    }
}

export const clearCurrentEventTrackData = () => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(EventActionTypes.CLEAR_CURRENT_EVENT_TRACK));
    }
}

export const fetchTrackCandidates = (trackUuid: string, query: CandidateQuery) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(EventActionTypes.FETCH_TRACK_CANDIDATES));
        try {
            const response = await eventApi.fetchTrackCandidates(trackUuid, query);
            dispatch(action(EventActionTypes.FETCH_TRACK_CANDIDATES_SUCCESS, response.data));
        } catch (error) {
            dispatch(action(EventActionTypes.FETCH_TRACK_CANDIDATES_ERROR, error));
        }
    }
}

export const fetchTrackSkills = (trackUuid: string) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(EventActionTypes.FETCH_TRACK_SKILLS));
        try {
            const response = await eventApi.fetchTrackSkills(trackUuid);
            const sortedData = response.data.sort();

            dispatch(action(EventActionTypes.FETCH_TRACK_SKILLS_SUCCESS, sortedData));
        } catch (error) {
            dispatch(action(EventActionTypes.FETCH_TRACK_SKILLS_ERROR, error));
        }
    }
}

export const updateEventTrackStatistic = (eventSlug: string, trackUuid: string, statistics: EventTrackStatistics) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(EventActionTypes.UPDATE_EVENT_TRACK_STATISTICS, { eventSlug, trackUuid, statistics }));
    }
}

export const addEventNotification = (notification: Notification) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(EventActionTypes.ADD_NOTIFICATION, notification));
    }
}

export const updateCandidateStatus = (candidateStatus: CandidateStatus) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(EventActionTypes.UPDATE_CANDIDATE_STATUS, candidateStatus));
    }
}

export const readNotifications = () => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(EventActionTypes.READ_NOTIFICATIONS));
    }
}

export const fetchOriginFilter = (eventSlug: string) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(EventActionTypes.FETCH_ORIGIN_FILTER));
        try {
            const response = await eventApi.fetchOriginFilter(eventSlug);
            dispatch(action(EventActionTypes.FETCH_ORIGIN_FILTER_SUCCESS, response.data));
        } catch (error) {
            dispatch(action(EventActionTypes.FETCH_ORIGIN_FILTER_ERROR, error));
        }
    }
}
