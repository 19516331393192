import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Label } from "./index.styled";

interface TableCounterProps {
    count: number;
    total: number;
}
const TableCounter: React.FC<TableCounterProps> = ({ count, total }) => {
    const { t } = useTranslation("private-home-page");
    return (
        <Container>
            <Label>{t('ranking-table.showing-total', {count: count, total: total})}</Label>
        </Container>
    );
};

export default TableCounter;