import { Container, Loading } from "./index.styled";

const LoadingSpinner: React.FC = () => {
    return (
        <Container>
            <Loading className="loading-part"/>
            <Loading className="loading-part"/>
            <Loading className="loading-part"/>
            <Loading className="loading-part"/>
        </Container>
    );
}

export default LoadingSpinner;