
import { AxiosResponse } from 'axios';
import Ecosystem from '../../models/ecosystem.model';
import talentApi from './talent-api-config';

class EcosystemApi {

    async fetchEcosystemsData(): Promise<AxiosResponse<Ecosystem[]>> {
        return talentApi.get<Ecosystem[]>(`/dmq/ecosystems/`);
    }

}

export const ecosystemApi = new EcosystemApi();
