import styled from "styled-components";


export const SlideContainer = styled.div`
    display: flex;
    height: 20rem;
    flex-direction: column;
    gap: 2.5rem;
`;

export const Message = styled.span`
    color: #452470;
`;

export const Title = styled(Message)`
    font-weight: 700;
    font-size: 1.25rem;
`;