import styled from "styled-components";

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    overflow: hidden;

    background: rgba(69, 36, 112, 0.7);
    backdrop-filter: blur(2px);
`;

export const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 5rem;
    margin: auto;
    z-index: 550;
    padding: 2rem;

    width: 45rem;
    height: 30rem;

    background: #fff;
    border-radius: 1rem;

    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Button = styled.button`
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 0.75rem;
    width: 10rem;
    background-color: #452470;
    color: #fff;

    &:hover {
        background-color: #6A4F8C;
    }
`;