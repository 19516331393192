import styled from "styled-components";

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    overflow: hidden;

    background: rgba(69, 36, 112, 0.7);
    backdrop-filter: blur(2px);
`;

export const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 5rem;
    margin: auto;
    z-index: 550;

    min-width: 32rem;
    max-width: 46rem;
    width: fit-content;
    height: fit-content;

    background: #fff;
    border-radius: 1rem;
`;

export const Header = styled.div`
    width: 100%;
    height: 2.5rem;
    background-color: #452470;
    margin: 0;
    padding: 0.5rem;
    padding-left: 1rem;
    border: none;
    border-start-start-radius: 1rem;
    border-start-end-radius: 1rem;
    display: flex;

    align-items: center;
    justify-content: space-between;

    
    .icon-label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #fff;
    }
`;

export const CloseButton = styled.button`
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 1.75rem;
    color: #fff;
    background: none;
`;

export const Alert = styled.span`
    font-size: 0.75rem;
    padding: 0.5rem 0.75rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #FDF3F2;
    color: #222;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    width: fit-content;

    svg {
        color: #DD5147;
    }
`;