
import React from 'react';
import { CandidateDetail } from '../../../../models/candidate-detail.model';
import { Information, InformationList, Section } from '../../talent.styled';
import { LockedContent } from '../locked-content/locked-content';

interface ContactsProps {
    candidate?: CandidateDetail | null;
    isLocked: boolean;
}

const Contacts: React.FC<ContactsProps> = ({ isLocked, candidate }) => {
    return (
        <Section>
            {isLocked ?
                <LockedContent />
                :
                <InformationList>
                    {candidate?.email &&
                        <Information>
                            <a href={`mailto:${candidate?.email}`}>{candidate?.email}</a>
                        </Information>
                    }
                    {candidate?.phone &&
                        <Information>
                            <a href={`tel:${candidate?.phone}`}>{candidate?.phone}</a>
                        </Information>
                    }
                </InformationList>
            }
        </Section>
    );
}

export default Contacts;
