import styled, { css } from "styled-components";

interface BackdropContainerProps {
  isShown: boolean;
  transparent?: boolean;
  zIndex?: number;
}

export const BackdropContainer = styled.div<BackdropContainerProps>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: ${({ isShown }) => isShown && "hidden"};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 10)};
  ${({ transparent }) =>
    transparent
      ? css`
          background: transparent;
        `
      : css`
          backdrop-filter: blur(0.5rem);
          background: linear-gradient(
              180deg,
              #151515 0%,
              rgba(255, 255, 255, 0) 100%
            ),
            #12092b61;
        `}
`;
