import React from "react";
import { RealtimeProvider } from "../../realtime/realtime-database";
import Footer from "./components/footer/footer";
import Header from './components/header/header';
import { Container } from "./main-layout.styled";

const MainLayout: React.FC = ({ children }) => {
    return (
        <RealtimeProvider>
            <Container>
                <Header />
                <main>
                    {children}
                </main>
                <Footer/>
            </Container>
        </RealtimeProvider>
    );
}

export default MainLayout;