import Plan from '../../models/plan.model';
import { Invoice } from './../../models/invoice.model';
import { StorageData } from './../storage-data';


export interface CompanyState {
    lastInvoiceData: StorageData<Invoice | null, any>;
    planData: StorageData<Plan | null, any>;
};

export const INITIAL_STATE: CompanyState = {
    lastInvoiceData: {
        loading: false,
        data: null,
        error: null
    },
    planData: {
        loading: false,
        data: null,
        error: null,
    },
};

export enum CompanyActionTypes {
    FETCH_LAST_INVOICE = '@company/FETCH_LAST_INVOICE',
    FETCH_LAST_INVOICE_SUCCESS = '@company/FETCH_LAST_INVOICE_SUCCESS',
    FETCH_LAST_INVOICE_ERROR = '@company/FETCH_LAST_INVOICE_ERROR',
    FETCH_COMPANY_PLAN = '@company/FETCH_COMPANY_PLAN',
    FETCH_COMPANY_PLAN_SUCCESS = '@company/FETCH_COMPANY_PLAN_SUCCESS',
    FETCH_COMPANY_PLAN_ERROR = '@company/FETCH_COMPANY_PLAN_ERROR',
};
