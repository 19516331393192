import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../../../components/content/pagination/pagination';
import LockIco from '../../../../../components/ico/lock';
import Modal from '../../../../../components/modal/modal';
import Talent from '../../../../../components/talent/talent';
import { useModal, useQuery } from '../../../../../hooks';
import useCandidateStatus from '../../../../../hooks/use-candidate-status';
import { Candidate } from '../../../../../models/candidate.model';
import { AppState } from '../../../../../store/app-state';
import { clearCandidateDetail, fetchCandidatePool, fetchSkillsOfCandidatePool } from '../../../../../store/candidate/candidate-actions';
import { fetchTrackCandidates } from '../../../../../store/event/event-actions';
import { fetchSetInvisible, fetchSetVisible } from '../../../../../store/load/load-actions';
import HomeDashboardPageQuery from '../../home-dashboard-page-query.model';
import FilterForm from '../filter-form/filter-form';
import Header from '../header/header';
import { ViewDesktopFilters } from '../selected-candidates-content/selected-candidates-content.styled';
import TableCounter from '../table/components/counter';
import PageSizeFilter from '../table/components/filter/page-size-filter';
import { HeaderItem } from '../table/components/header';
import Table from '../table/index';
import { DataCell, InfoTableContainer, TableRow } from '../table/index.styled';
import {
    getCandidateHireModel,
    getCandidateHireTypes,
    getCandidatePosition,
    getCandidateSalary
} from '../table/utils/table.utils';
import { Container, Content } from './talent-pool-content.styled';

const DEFAULT_ECOSYSTEM = { id: 'ad63a367-a5e5-47b6-a9bf-0a6dfb5fe1ff', name: 'Java' };
const modalTalent = document.getElementById("modal-talent");

export const TalentPoolContent: React.FC = () => {
    const { isShown, toggle } = useModal();
    const { t } = useTranslation('private-home-page');
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.authState.user);
    const [query, setQuery] = useQuery<HomeDashboardPageQuery>();
    const { candidatePoolData, currentCandidatePoolSkillsData } = useSelector((state: AppState) => state.candidateState);
    const devTableRef = useRef<HTMLDivElement>(null);
    const { currentEventTrackData } = useSelector(
        (state: AppState) => state.eventState
    );

    useEffect(() => {
        if (!candidatePoolData.loading) {
            dispatch(fetchSetInvisible());
        }
    }, [candidatePoolData.loading, dispatch])

    useEffect(() => {
        if (query.talentPool) {
            if (!query.ecosystem) {
                query.ecosystem = DEFAULT_ECOSYSTEM.id;
            }
            dispatch(fetchCandidatePool(query.ecosystem, query));
            dispatch(fetchSkillsOfCandidatePool(query.ecosystem, query));
            dispatch(fetchSetVisible());
        }
    }, [query, dispatch]);

    const handlePageChange = useCallback((page: number) => {
        query.candidatePoolPage = page.toString();
        setQuery(query);

        if (devTableRef.current) {
            window.scrollTo({
                behavior: 'smooth',
                top: devTableRef.current.offsetTop,
            });
        }
    }, [query, setQuery, devTableRef]);

    const tableHeaderItems: HeaderItem[] = [
        { value: 'position', width: '2%' },
        { value: 'name', width: '2%' },
        { value: 'main-skills', width: '20%' },
        { value: 'contact', width: '3%' },
        { value: 'experience', width: '10%' },
        { value: 'wage-claim', width: '15%' },
        { value: 'work-model', width: '15%' },
        { value: 'hire-types', width: '15%' },
    ];

    const handleOpen = useCallback(() => {
        toggle();
    }, [toggle]);

    const handleClose = () => {
        if (currentEventTrackData.data?.register) {
            // TODO: Se possível toda atualização vier pelo realtime também
            dispatch(fetchTrackCandidates(currentEventTrackData.data.uuid, query));
        }
        dispatch(clearCandidateDetail());
    };
    const [candidate, setCandidate] = useState<Candidate>();

    const candidateStatus = useCandidateStatus({ candidateId: candidate?.id || '' });

    const handleModal = useCallback((candidate: Candidate, candidateStatus) => {
        (!candidateStatus?.hired ||
            candidateStatus?.hired === user?.corporate.id ||
            candidate.company_hired === user?.corporate.id) &&
            handleOpen();
    }, [handleOpen, user]);


    return (<>
        <Modal
            width={850}
            isShown={isShown}
            toggle={handleOpen}
            target={modalTalent}
            handleClose={handleClose}
            background={'#F3F3F3'}
        >
            <Talent
                talentId={
                    candidate ?
                        candidate?.is_pool && candidate?.user_id
                            ? candidate?.user_id
                            : candidate?.id
                        : ''
                }
                userId={candidate?.user_id || ''}
                trackId={candidate?.selection?.source_id}
                ecosystem={query.ecosystem || DEFAULT_ECOSYSTEM.id}
                isNotifyOpenCandidateDetail={!candidate?.selection}
            />
        </Modal>
        <Container>
            <Header title={t('private-home-page:tabs.talentPool.title')} subtitle={user?.corporate.name} isSelecteds={true} />

            <ViewDesktopFilters>
                <FilterForm
                    initialMaxSalary={50000}
                    isCandidatePool={true}
                    skills={currentCandidatePoolSkillsData?.data?.map(s => ({ value: s, label: s })) || []}
                />
            </ViewDesktopFilters>
            <Content>
                <InfoTableContainer>
                    <TableCounter
                        count={candidatePoolData.data?.results.length || 0}
                        total={candidatePoolData.data?.count || 0}
                    />
                    <PageSizeFilter />
                </InfoTableContainer>
                <Table headerItems={tableHeaderItems}>
                    {candidatePoolData.data?.results.map((c, key) => (
                        <TableRow 
                            key={key}
                            onClick={() => {
                                setCandidate(c);
                                handleModal(c, candidateStatus)
                            }}
                        >
                            <DataCell>{
                                getCandidatePosition(
                                    candidatePoolData.data?.current_page, 
                                    candidatePoolData.data?.page_size,
                                    key
                                )
                            }º</DataCell>
                            <DataCell color={'#452470'} bold={600}>{c.initials_name.replace(' ', '')}</DataCell>
                            <DataCell>{c.main_skills.join('/')}</DataCell>
                            <DataCell><LockIco /></DataCell>
                            <DataCell>{c.experience_time}</DataCell>
                            <DataCell>{getCandidateSalary(c, t)}</DataCell>
                            <DataCell>{getCandidateHireModel(c)}</DataCell>
                            <DataCell>{getCandidateHireTypes(c, t)}</DataCell>
                        </TableRow>))}
                </Table>
                {(candidatePoolData.data && candidatePoolData.data.results?.length > 0) &&
                    <Pagination
                        currentPage={candidatePoolData.data?.current_page}
                        pageNumbers={candidatePoolData.data?.total_pages}
                        onPageChange={handlePageChange}
                    />
                }
            </Content>
        </Container>
    </>
    );
}
