import styled from "styled-components";

export const Container = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Status = styled.div<{
  border: string;
  background: string;
  color: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 6px;
  width: fit-content;
  height: 28px;
  background: ${({ background }) => background};
  border: 1px solid ${({ border }) => border};
  border-radius: 6px;

  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: ${({ color }) => color};
  }
`;
