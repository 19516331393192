import { useTranslation } from "react-i18next";
import { JobOfferPayload } from "../../../../models/candidate-action.model";
import { jobOfferStatusTypes } from "../../../../models/candidate.model";
import { Field, Infos, Modal, Skill } from "../../../job-offer/joboffer.styled";
const ModalJobOfferElement = document.getElementById("modal-joboffer");

const JobOfferResumeModal: React.FC<{
  toggle: () => void;
  isShown: boolean;
  joboffer: {
    status: jobOfferStatusTypes;
    metadata: JobOfferPayload;
  };
}> = ({ joboffer, isShown, toggle }) => {
  const { t } = useTranslation("private-home-page");
  const textSalary = joboffer.metadata?.salary_range && [
    joboffer.metadata.salary_range[0],
    [joboffer.metadata.salary_range[1]],
  ];

  return (
    <Modal
      top={15}
      width={500}
      isShown={isShown}
      toggle={() => toggle()}
      target={ModalJobOfferElement}
      handleClose={() => toggle()}
    >
      <Infos>
        <Skill>
          <p>{`${t("jobOffer.modal.coreTechnology")}${
            joboffer.metadata.main_skill
          }`}</p>
        </Skill>

        <Field>
          <p>{`${t("jobOffer.modal.salaryRange")} ${
            joboffer.metadata?.salary_range
              ? textSalary?.join(" - ")
              : "Não informado"
          }`}</p>
        </Field>

        <Field>
          {joboffer.metadata?.experience_time ? (
            <p>{`${t("jobOffer.modal.experience")} ${
              joboffer.metadata.experience_time
            }`}</p>
          ) : (
            <p>{`${t("jobOffer.modal.experience")} ${t(
              "jobOffer.modal.notInformed"
            )}`}</p>
          )}
        </Field>
        <Field>
          {joboffer.metadata?.skills ? (
            <p>{`${t("jobOffer.modal.skills")} ${joboffer.metadata.skills?.join(
              " - "
            )}`}</p>
          ) : (
            <p>{`${t("jobOffer.modal.skills")} ${t(
              "jobOffer.modal.notInformed"
            )}`}</p>
          )}
        </Field>
        {joboffer.metadata?.hire_types && (
          <Field>
            <p>
              {`${t("jobOffer.modal.hireType")} 
            ${
              Array.isArray(joboffer.metadata.hire_types) &&
              joboffer.metadata.hire_types?.join(" - ")
            }`}
            </p>
          </Field>
        )}
        <Field>
          {joboffer.metadata?.work_type ? (
            <p>{`${t(
              "jobOffer.modal.workType"
            )} ${joboffer.metadata?.work_type?.join(" - ")}`}</p>
          ) : (
            <p>{`${t("jobOffer.modal.workType")} ${t(
              "jobOffer.modal.notInformed"
            )}`}</p>
          )}
        </Field>
        {joboffer.metadata?.workplace && (
          <Field>
            <p>{`${t("jobOffer.modal.city")}${
              joboffer.metadata?.workplace || t("jobOffer.modal.notInformed")
            }`}</p>
          </Field>
        )}
        {joboffer.metadata?.english && (
          <Field>
            <p>{`${t("jobOffer.modal.english")} ${
              joboffer.metadata?.english?.join(" - ") ||
              t("jobOffer.modal.notInformed")
            }`}</p>
          </Field>
        )}
        {joboffer.metadata?.diversity && (
          <Field>
            <p>{`${t("jobOffer.modal.diversity")} ${
              joboffer.metadata?.diversity?.join(" - ") ||
              t("jobOffer.modal.notInformed")
            }`}</p>
          </Field>
        )}
      </Infos>
    </Modal>
  );
};

export default JobOfferResumeModal;
