import { shade } from 'polished';

import styled, { css } from 'styled-components';
import { ScrollableContent } from '../../../../../components/talent/talent.styled';
import { TABLET } from '../../../../../styles/breakpoints';

export const NotificationHeader = `
    height: 3.375rem;
    padding: 0 1rem;
    font-weight: 700;
`;

export const Area = styled.div<{ isShown: boolean}>`

    left: 0;
    right: 0;
    position: fixed;
    top: 8rem;
    width: calc(100% - 2rem);
    margin: 1rem;


    overflow-y: hidden;
    padding: 0 0.6rem 1.125rem 1rem;
    background-color: #FFFFFF;
    border-radius: 10px;
    display: ${({ isShown }) => isShown ? 'flex' : 'none'};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    flex-direction: column;
    z-index: 2;


    @media screen and (min-width: ${TABLET}){
        position: absolute;
        width: 360px;
        height: 402px;
        left: -19rem;
        top: 2rem;
    };

    @media screen and (max-width: ${TABLET}){
        position: absolute;
        width: 360px;
        height: 402px;
        left: -12rem;
        top: 2rem;
    };

    h3 {
        color: ${({ theme }) => shade(0.2, theme.colors.primary.main)};
    }
    ul {
        display: flex;
        list-style: none;
        font-size: .8rem;
        margin: 0;
        padding: 0 0.6rem 0 0;

        
        color: ${({ theme }) => theme.colors.primary.text};

        li {
            white-space: break-spaces;
            border-bottom: 1px dotted ${({ theme }) => theme.colors.primary.text};
            font-size: 1rem;
            color: ${({ theme }) => shade(0.2, theme.colors.primary.main)};
            border-radius: 10px;
            text-align: left;
            margin-bottom: 0.3125rem;

        }
    }
`;

export const List = styled.ul`
    display: flex;
    overflow-y: auto;
    ${ScrollableContent};
    height: 100%;
    flex-direction: column-reverse;
`;

export const Item = styled.li<{ isNew?: boolean }>`
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
    background:'#F6F6F6';
    padding-left: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: start;
    position: relative;
    background: #F6F6F6;
    padding: 1.25rem 1rem 1.25rem 1rem;
    transition: all 1s;

    p {
        margin: 0;
        font-size: 0.875rem;
        line-height: 1.25rem;
        padding-right: 4rem;
    }
    span {
        position: absolute;
        right: 1rem;
        color: #939799;
        font-size: 0.75rem;
    }

    ${({ isNew }) => isNew && css`
        padding: 1.25rem 1rem 1.25rem 2rem;
        background: #F5EDFF;
        p {
            color: ${({ theme }) => theme.typography.primary.color }
        }
        &:before {
            content: "";
            width: 0.375rem;
            height: 0.375rem;
            background: #0081F8;
            border-radius: 50%;
            position: absolute;
            left: 1rem;

        }
    `};

    @keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
    }

    @-moz-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
    }

    @-webkit-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
    }

    @-o-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
    }

    @-ms-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
    }
`;

export const ImageButton = styled.button`
    background: 0;
    border: 0;
    box-shadow: none;
    padding: 0;
`;