import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Success from "../../../success";
import { Button, Container } from "./index.styled";

const SuccessElement: React.FC<{ job_id: string }> = ({ job_id }) => {
    const history = useHistory();
    const { t } = useTranslation('private-home-page');

    return (
        <Container>
            <Success messages={[
                t(`job-board.confirm-modal.published-1`),
                t(`job-board.confirm-modal.published-2`)
            ]}/>
            <Button onClick={() => history.push(`/jobs?job=${job_id}`)}>
                {t(`job-board.confirm-modal.goto-job`)}
            </Button>
        </Container>
    );
}

export default SuccessElement;