export enum NOTIFICATION_STATUS {
    READ = 'READ',
    NOT_READ = 'NOT_READ',
}

export interface NotificationModel {
    timestamp?: Date;
    message: string;
    status?: NOTIFICATION_STATUS;
    translation?: NotificationTranslated;
};

export interface NotificationTranslated {
    featured: string;
    message: string;
};
