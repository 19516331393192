import styled from 'styled-components';
import { WIDE_DESKTOP } from './../../../../../styles/breakpoints';

export const Container = styled.div`
    width: 100%;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
`;

export const Form = styled.div<{ grid?: boolean }>`
    padding: 2rem 1rem;
    flex-wrap: wrap;
    width: 100%;

    @media(min-width: ${WIDE_DESKTOP}) {
        padding: 0;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 0.5rem;

    @media(min-width: ${WIDE_DESKTOP}) {
        &:not(:first-child) {
            padding-top: 2rem;
        }
    };
`;

export const InputContent = styled(Content)`
    padding: 0rem;
    justify-content: space-between;
    @media(min-width: ${WIDE_DESKTOP}) {
        &:not(:first-child) {
            padding-top: 0.4rem;
        }
    };
`;

export const Title = styled.p`
  margin: .5rem 0 .5rem 0;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const TitleWithInfo = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   color: ${({ theme }) => theme.colors.primary.main};
   font-size: 0.875rem;
`;

export const CheckboxList = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 14.125rem;

    .form-field {
        margin: .5rem 1rem .5rem 0;

    }
`;

export const SingleCheck = styled.div`
    padding: 0.5rem 0;
`;

export const MultipleRange = styled.div`
    position: relative;

    input {
        position: absolute;
    }
`;

export const TextFieldContent = styled.div`
    margin-bottom: 1.75rem;
    position: relative;
    width: 350px;
    @media screen and (min-width: ${WIDE_DESKTOP}) {
        margin-bottom: 0;
        &:first-child {
            max-width: 250px;
            margin-right: 40px;
        }

        &:nth-child(3){
            margin-left: 40px;
        }
    }
`;

export const ViewDesktop = styled.div`
    display: none;
    background: #FFFFFF;
    
    @media(min-width: ${WIDE_DESKTOP}) {
        display: flex;
    }
`;

export const Line = styled.div`
    align-items: center;
    background: #D9D9D9;
    display: flex;
    width: 1.9rem;
    height: 0.063rem;
    margin-left: .3rem;
    margin-right: .3rem;
`;

export const Range = styled.div`
    display: flex;
    align-items: center;
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 340px;
    margin-top: 1.5rem;
`;