import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../../../components/form/button/button.styled";
import Input from "../../../../../components/form/Input/input";
import LoadingPage from "../../../../../components/form/loading-pages/loading-pages";
import RangeInput from "../../../../../components/form/range-input/range-input";
import SelectTag from "../../../../../components/form/select-tag/select-tag";
import Select from "../../../../../components/form/select/select";
import { useQuery } from "../../../../../hooks";
import { AppState } from "../../../../../store/app-state";
import {
  fetchCandidateDiversities,
  fetchCandidateEcosystems,
} from "../../../../../store/candidate/candidate-actions";
import { fetchSetVisible } from "../../../../../store/load/load-actions";
import HomeDashboardPageQuery from "../../home-dashboard-page-query.model";
import {
  Actions,
  Container,
  Form,
  InputContent,
  Line,
  Range,
  TextFieldContent,
  Title,
} from "./filter-form.styled";

const DEFAULT_ECOSYSTEM = {
  id: "ad63a367-a5e5-47b6-a9bf-0a6dfb5fe1ff",
  name: "Java",
};

interface FilterFormProps {
  hireTypes?: [];
  isCandidatePool?: boolean;
  isSelectedsCandidate?: boolean;
  initialMaxSalary: number;
  grid?: boolean;
  isTrack?: boolean;
  skills?: any[];
  reset?: boolean;
}

const FilterForm: React.FC<FilterFormProps> = ({
  initialMaxSalary,
  isSelectedsCandidate,
  isCandidatePool,
  grid,
  isTrack,
  skills,
}) => {
  const { t } = useTranslation("private-home-page");
  const dispatch = useDispatch();
  const [query, setQuery] = useQuery<HomeDashboardPageQuery>();
  const [filters, setFilters] = useState<any>(null);
  const [lastFilters, setLastFilters] = useState<any>(null);
  const [changedFilters, setChangedFilters] = useState(false);
  const { isVisible } = useSelector((state: AppState) => state.loadState);
  const { isChanged } = useSelector((state: AppState) => state.tabChangedState);
  const [locationKeys, setLocationKeys] = useState([""]);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        if (location.key) setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          setFilters(null);
        } else {
          setLocationKeys((keys: any) => [location.key, ...keys]);
          setFilters(null);
        }
      }
    });
  }, [locationKeys, history]);

  const [experience] = useState([
    {
      value: "Sem experiência",
      label: t("private-home-page:filterForm.withoutExperience"),
    },
    {
      value: "Menos de 1 ano",
      label: t("private-home-page:filterForm.lessThanOneYear"),
    },
    {
      value: "1 a 3 anos",
      label: t("private-home-page:filterForm.oneToThreeYears"),
    },
    {
      value: "3 a 6 anos",
      label: t("private-home-page:filterForm.threeToSixYears"),
    },
    {
      value: "+ de 6 anos",
      label: t("private-home-page:filterForm.experienceSixYearsMore"),
    },
  ]);

  const [workType] = useState([
    {
      value: "remote",
      label: t("private-home-page:filterForm.acceptRemoteWork"),
    },
    {
      value: "presential",
      label: t("private-home-page:filterForm.acceptPresentialWork"),
    },
    {
      value: "hybrid",
      label: t("private-home-page:filterForm.acceptHybridWork"),
    },
  ]);
  const [english] = useState([
    {
      value: "BEGINNER",
      label: t("private-home-page:filterForm.beginnerEnglish"),
    },
    {
      value: "INTERMEDIATE",
      label: t("private-home-page:filterForm.intermediateEnglish"),
    },
    {
      value: "ADVANCED",
      label: t("private-home-page:filterForm.advancedEnglish"),
    },
    { value: "FLUENT", label: t("private-home-page:filterForm.fluentEnglish") },
  ]);

  const [hireType] = useState([
    { value: "clt", label: t("private-home-page:filterForm.hireTypesClt") },
    { value: "pj", label: t("private-home-page:filterForm.hireTypesPj") },
  ]);

  const { data: diversities } = useSelector(
    (state: AppState) => state.candidateState.candidateDiversitiesData
  );

  const { data: ecosystems } = useSelector(
    (state: AppState) => state.candidateState.candidateEcosystemsData
  );

  const handleSetQuery = () => {
    dispatch(fetchSetVisible());
    setChangedFilters(false);
    setLastFilters(filters);
    if (isTrack) {
      setQuery({
        ...filters,
        track: query.track,
        orderBy: query?.orderBy,
      });
    } else if (isCandidatePool) {
      setQuery({
        ...filters,
        talentPool: true,
      });
    } else {
      setQuery({
        ...filters,
        selectedCandidates: true,
      });
    }
  };

  useEffect(() => {
    if (!filters && query) {
      const newFilters = {
        ...query,
      };

      setFilters({ ...query, ...newFilters });
      setLastFilters({ ...query, ...newFilters });
    }
  }, [query, filters, lastFilters]);

  const handleChangeFilter = useCallback(
    (filter) => {
      for (const key of Object.keys(filter)) {
        if (filter[key] === "" || !filter[key]) {
          delete filter[key];
        }
      }
      setFilters(filter);
    },
    [setFilters]
  );

  const isPerformance = useMemo(() => (
    query?.orderBy === 'performance'
  ), [query?.orderBy]);

  const noCoreTechnology = useMemo(() => ({
    id: '', 
    name: t("private-home-page:filterForm.selectTechnology")
  }), [t]);

  const currentEcosystem = useMemo(() => {
    const current = filters?.ecosystem
      ? ecosystems?.find((e) => e.id === filters?.ecosystem)
      : (isPerformance || isCandidatePool)
      ? noCoreTechnology
      : DEFAULT_ECOSYSTEM;
    return current;
  }, [ecosystems, filters, isCandidatePool, isPerformance, noCoreTechnology]);

  useEffect(() => {
    const ecosystemId = isCandidatePool && !query?.ecosystem
      ? DEFAULT_ECOSYSTEM.id
      : ecosystems?.find((e) => e.id === query?.ecosystem)?.id;
    setFilters((f: any) => ({...f, ecosystem: ecosystemId}));
    setLastFilters((f: any) => ({...f, ecosystem: ecosystemId}));
  }, [ecosystems, isCandidatePool, query]);

  const handleResetForm = useCallback(() => {
    if (isTrack) {
      setQuery({ track: query.track, orderBy: query?.orderBy });
    } else if (isCandidatePool) {
      setQuery({ talentPool: "true" });
    } else {
      setQuery({ selectedCandidates: "true" });
    }
    setTimeout(() => {
      setFilters(null);
    }, 1000);
  }, [setFilters, setQuery, query, isTrack, isCandidatePool]);

  const getTagValues = (filter: any, tags: any) => {
    if (filter) {
      if (typeof filter !== "string") {
        return (
          filter?.map((v: any) => tags?.find((t: any) => t.value === v)) || []
        );
      } else {
        return tags?.find((t: any) => t.value === filter);
      }
    }
    return null;
  };

  useEffect(() => {
    setChangedFilters(filters && JSON.stringify(filters) !== JSON.stringify(lastFilters));
  }, [filters, setChangedFilters, lastFilters]);

  useEffect(() => {
    setFilters(null);
  }, [isChanged, setFilters]);

  useEffect(() => {
    dispatch(fetchCandidateDiversities());
  }, [dispatch]);

  useEffect(() => {
    if (isCandidatePool || isTrack) {
      dispatch(fetchCandidateEcosystems());
    }
  }, [isCandidatePool, isTrack, dispatch]);

  return (
    <Container>
      <Form onSubmit={(e) => e.preventDefault()} grid={grid}>
        <InputContent>
          {isSelectedsCandidate && (
            <TextFieldContent>
              <Title>{t("private-home-page:filterForm.search")}</Title>
              <Input
                name="search"
                type="text"
                placeholder=""
                value={filters?.search ?? ""}
                debounceDelay={200}
                onDeboncedValueChange={(newValue) =>
                  handleChangeFilter({ ...filters, search: `${newValue}` })
                }
              />
            </TextFieldContent>
          )}

          {((isCandidatePool && ecosystems) || isTrack) && (
            <TextFieldContent>
              <Title>
                {t("private-home-page:filterForm.coreTechnologyRequired")}
              </Title>
              <Select
                placeholder={t(
                  "private-home-page:filterForm.selectCoreTechnology"
                )}
                value={currentEcosystem}
                values={ecosystems ? (isPerformance ? [noCoreTechnology, ...ecosystems] : ecosystems) : []}
                onChange={(newValue) =>
                  handleChangeFilter({
                    ...filters,
                    ecosystem: `${newValue.id}`,
                  })
                }
                getElementLabel={(ecosystem) => ecosystem?.name || ""}
              />
            </TextFieldContent>
          )}

          {((isCandidatePool && ecosystems) || isTrack) && (
            <TextFieldContent>
              <Title>{t("private-home-page:filterForm.skills")}</Title>
              <SelectTag
                placeholder={t("private-home-page:filterForm.selectTags")}
                values={getTagValues(filters?.skills, skills)}
                options={skills}
                onChange={(newValue) => {
                  handleChangeFilter({
                    ...filters,
                    skills: newValue.map((v) => v.value),
                  });
                }}
              />
            </TextFieldContent>
          )}

          {((isCandidatePool && ecosystems) || isTrack) && (
            <TextFieldContent>
              <Title>{t("private-home-page:filterForm.english")}</Title>
              <SelectTag
                placeholder={t("private-home-page:filterForm.selectTags")}
                values={getTagValues(filters?.english, english)}
                options={english || []}
                onChange={(newValue) => {
                  handleChangeFilter({
                    ...filters,
                    english: newValue.map((v) => v.value),
                  });
                }}
              />
            </TextFieldContent>
          )}

          {isSelectedsCandidate && (
            <TextFieldContent>
              <Title>{t("private-home-page:filterForm.english")}</Title>
              <SelectTag
                placeholder={t("private-home-page:filterForm.selectTags")}
                values={getTagValues(filters?.english, english)}
                options={english || []}
                onChange={(newValue) => {
                  handleChangeFilter({
                    ...filters,
                    english: newValue.map((v) => v.value),
                  });
                }}
              />
            </TextFieldContent>
          )}

          {isSelectedsCandidate && (
            <TextFieldContent>
              <Title>{t("private-home-page:filterForm.hireTypes")}</Title>
              <SelectTag
                placeholder={t("private-home-page:filterForm.selectTags")}
                values={getTagValues(filters?.hireTypes, hireType)}
                options={hireType || []}
                onChange={(newValue) => {
                  handleChangeFilter({
                    ...filters,
                    hireTypes: newValue.map((v) => v.value),
                  });
                }}
              />
            </TextFieldContent>
          )}
        </InputContent>
        <InputContent>
          <TextFieldContent>
            <Title>{t("private-home-page:filterForm.salaryRange")}</Title>

            <Range>
              <RangeInput
                name="minSalary"
                type="text"
                placeholder={t("private-home-page:filterForm.minRange")}
                value={filters?.minSalary ?? ""}
                debounceDelay={200}
                onDeboncedValueChange={(newValue) =>
                  handleChangeFilter({
                    ...filters,
                    minSalary: `${newValue ?? ""}`,
                  })
                }
              />
              <Line />
              <RangeInput
                name="maxSalary"
                type="text"
                placeholder={t("private-home-page:filterForm.maxRange")}
                value={filters?.maxSalary ?? ""}
                debounceDelay={200}
                onDeboncedValueChange={(newValue) =>
                  handleChangeFilter({
                    ...filters,
                    maxSalary: `${newValue ?? ""}`,
                  })
                }
              />
            </Range>
          </TextFieldContent>

          <TextFieldContent>
            <Title>
              {t("private-home-page:filterForm.professionalExperience")}
            </Title>
            <SelectTag
              placeholder={t("private-home-page:filterForm.selectTags")}
              values={getTagValues(filters?.experience, experience)}
              options={experience || []}
              onChange={(newValue) => {
                handleChangeFilter({
                  ...filters,
                  experience: newValue.map((v) => v.value),
                });
              }}
            />
          </TextFieldContent>
          {isSelectedsCandidate && (
            <TextFieldContent>
              <Title>{t("private-home-page:filterForm.preferences")}</Title>
              <SelectTag
                placeholder={t("private-home-page:filterForm.selectTags")}
                values={getTagValues(filters?.workType, workType)}
                options={workType || []}
                onChange={(newValue) => {
                  handleChangeFilter({
                    ...filters,
                    workType: newValue.map((v) => v.value),
                  });
                }}
              />
            </TextFieldContent>
          )}
          {((isCandidatePool && ecosystems) || isTrack) && (
            <TextFieldContent>
              <Title>{t("private-home-page:filterForm.hireTypes")}</Title>
              <SelectTag
                placeholder={t("private-home-page:filterForm.selectTags")}
                values={getTagValues(filters?.hireTypes, hireType)}
                options={hireType || []}
                onChange={(newValue) => {
                  handleChangeFilter({
                    ...filters,
                    hireTypes: newValue.map((v) => v.value),
                  });
                }}
              />
            </TextFieldContent>
          )}
        </InputContent>
        <InputContent>
          <TextFieldContent>
            <Title>{t("private-home-page:filterForm.city")}</Title>
            <Input
              name="workplace"
              type="text"
              placeholder={t("private-home-page:filterForm.city")}
              value={filters?.workplace ?? ""}
              debounceDelay={200}
              onDeboncedValueChange={(newValue) => {
                handleChangeFilter({ ...filters, workplace: `${newValue}` });
              }}
            />
          </TextFieldContent>

          <TextFieldContent>
            <Title>{t("private-home-page:filterForm.diversity")}</Title>
            <SelectTag
              placeholder={t("private-home-page:filterForm.selectTags")}
              values={getTagValues(
                filters?.diversity,
                diversities?.map((d) => ({ value: d.id, label: t(d.name) })) ||
                  []
              )}
              options={
                diversities?.map((d) => ({ value: d.id, label: t(d.name) })) ||
                []
              }
              onChange={(newValue) => {
                handleChangeFilter({
                  ...filters,
                  diversity: newValue.map((v) => v.value),
                });
              }}
            />
          </TextFieldContent>

          {((isCandidatePool && ecosystems) || isTrack) && (
            <TextFieldContent>
              <Title>{t("private-home-page:filterForm.preferences")}</Title>
              <SelectTag
                placeholder={t("private-home-page:filterForm.selectTags")}
                values={getTagValues(filters?.workType, workType)}
                options={workType || []}
                onChange={(newValue) => {
                  handleChangeFilter({
                    ...filters,
                    workType: newValue.map((v) => v.value),
                  });
                }}
              />
            </TextFieldContent>
          )}
        </InputContent>
      </Form>

      <Actions>
        <ButtonSecondary onClick={() => handleResetForm()}>
          {t("private-home-page:filterForm.clean")}
        </ButtonSecondary>

        <ButtonPrimary
          onClick={() => handleSetQuery()}
          disabled={!changedFilters}
        >
          {t("private-home-page:filterForm.filter")}
        </ButtonPrimary>
      </Actions>

      {isVisible.data && isCandidatePool && (
        <LoadingPage
          messages={[
            t("private-home-page:filterForm.loadingMessage1"),
            t("private-home-page:filterForm.loadingMessage2"),
            t("private-home-page:filterForm.loadingMessage3"),
            t("private-home-page:filterForm.loadingMessage4"),
            t("private-home-page:filterForm.loadingMessage5"),
            t("private-home-page:filterForm.loadingMessage6"),
          ]}
        />
      )}

      {isVisible.data && (isTrack || isSelectedsCandidate) && (
        <LoadingPage
          messages={[t("private-home-page:filterForm.loadingMessage7")]}
        />
      )}
    </Container>
  );
};

export default FilterForm;
