import { JobBoardFormOptions, JobInterface, JobListInterface } from '../../pages/private/job-board/job-board-form.model';
import { StorageData } from './../storage-data';

export interface City {
    label: string;
    value: string;
    city: number;
}

export interface JobBoardState {
    formOptions: StorageData<JobBoardFormOptions, any | null>;
    cities: StorageData<City[], any | null>;
    jobs: StorageData<JobListInterface[], any | null>;
    currentJob: StorageData<JobInterface, any | null>;
    closeJob: StorageData<boolean, any | null>;
}

const FORM_OPTIONS_INITIAL_STATE: JobBoardFormOptions = {
    hire_types: [],
    degrees: [],
    experience_levels: [],
    experience_times: [],
    languages: [],
    currencies: [],
    countries: [],
    skills: [],
    diversitys:[],
}

export const INITIAL_STATE: JobBoardState = {
    formOptions: {
        data: FORM_OPTIONS_INITIAL_STATE,
        loading: false,
        error: null
    },
    cities: {
        data: [],
        loading: false,
        error: null
    },
    jobs: {
        data: [],
        loading: false,
        error: null
    },
    currentJob: {
        data: undefined,
        loading: false,
        error: null
    },
    closeJob: {
        data: false, 
        loading: false,
        error: null
    }
}

export enum JobBoardActionTypes {
    FETCH_FORM_OPTIONS_DATA = '@job-board/FETCH_FORM_OPTIONS_DATA',
    FETCH_FORM_OPTIONS_DATA_SUCCESS = '@job-board/FETCH_FORM_OPTIONS_DATA_SUCCESS',
    FETCH_FORM_OPTIONS_DATA_ERROR = '@job-board/FETCH_FORM_OPTIONS_DATA_ERROR',

    FETCH_CITIES_DATA = '@job-board/FETCH_CITIES_DATA',
    FETCH_CITIES_DATA_SUCCESS = '@job-board/FETCH_CITIES_DATA_SUCCESS',
    FETCH_CITIES_DATA_ERROR = '@job-board/FETCH_CITIES_DATA_ERROR',

    FETCH_JOB_LIST_DATA = '@job-board/FETCH_JOB_LIST_DATA',
    FETCH_JOB_LIST_DATA_SUCCESS = '@job-board/FETCH_JOB_LIST_DATA_SUCCESS',
    FETCH_JOB_LIST_DATA_ERROR = '@job-board/FETCH_JOB_LIST_DATA_ERROR',

    FETCH_JOB_DETAIL_DATA = '@job-board/FETCH_JOB_DETAIL_DATA',
    FETCH_JOB_DETAIL_DATA_SUCCESS = '@job-board/FETCH_JOB_DETAIL_DATA_SUCCESS',
    FETCH_JOB_DETAIL_DATA_ERROR = '@job-board/FETCH_JOB_DETAIL_DATA_ERROR',
    CLEAR_CURRENT_JOB = '@job-board/CLEAR_CURRENT_JOB',

    CREATE_JOB_DATA = '@job-board/CREATE_JOB_DATA',
    CREATE_JOB_DATA_SUCCESS = '@job-board/CREATE_JOB_DATA_SUCCESS',
    CREATE_JOB_DATA_ERROR = '@job-board/CREATE_JOB_DATA_ERROR',

    CLOSE_JOB_DATA = 'job-board/CLOSE_JOB_DATA',
    CLOSE_JOB_DATA_SUCCESS = 'job-board/CLOSE_JOB_DATA_SUCCESS',
    CLOSE_JOB_DATA_ERROR = 'job-board/CLOSE_JOB_DATA_ERROR',
} 