import { differenceInDays } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { action } from "typesafe-actions";
import { AppState } from "../../../../../store/app-state";
import { closeJob, fetchJobDetail } from "../../../../../store/job-board/job-board-actions";
import { JobBoardActionTypes } from "../../../../../store/job-board/job-board-types";
import Error from '../error';
import JobDetail from "../job-detail";
import Loading from "../loading";
import Success from "../success";
import { Backdrop, CancelButton, CloseButton, CloseContainer, CloseJobButton, ConfirmCancelContainer, Message, ModalContainer, RemainingDays, Title } from "./index.styled";

interface DetailModalInterface {
    job_id: string;
    onClose: () => void;
}
const DetailModal: React.FC<DetailModalInterface> = ({ job_id, onClose }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('private-home-page');
    const root = useCallback((key: string, obj={}) => {
        return t(`job-board.detail-modal.${key}`, obj);
    }, [t]);
    const {data: job, loading, error} = useSelector((state: AppState) => state.jobBoardState.currentJob);
    const {data: closed, loading: closing, error: errorClose} = useSelector((state: AppState) => state.jobBoardState.closeJob);
    const { user } = useSelector((state: AppState) => state.authState);
    const [confirmClose, setConfirmClose] = useState<boolean>(false);

    const remainingDays = useMemo(() => {
        if(!job)
            return;
        const end_date = new Date(job.end_date);
        const today = new Date();
        return differenceInDays(end_date, today);
    }, [job]);

    useEffect(() => {
        dispatch(fetchJobDetail(job_id));
    }, [dispatch, job_id]);

    const handleClose = useCallback(() => {
        if(!closing){
            dispatch(action(JobBoardActionTypes.CLEAR_CURRENT_JOB));
            onClose();
        }
    }, [dispatch, onClose, closing]);

    const handleCloseJob = useCallback(() => {
        setConfirmClose(false);
        dispatch(closeJob(job_id));
    }, [dispatch, job_id]);

    const currentElement = useMemo((): JSX.Element => {
        if(closing){
            return <Loading message={root('loading-close')}/>;
        }
        if(loading){
            return <Loading message={root('loading-job')}/>;
        }
        if(!!errorClose){
            return <Error message={root('error-close')}/>;
        }
        if(!!error){
            return <Error message={root('error-job')}/>;
        }
        if(closed){
            return <Success messages={[root('success-close')]}/>;
        }
        if(confirmClose){
            return (
                <ConfirmCancelContainer>
                    <Title>{root('title-cancel')}</Title>
                    <Message>{root('message-cancel')}</Message>
                    <CloseContainer style={{'justifyContent': 'center'}}>
                        <CancelButton onClick={() => setConfirmClose(false)}>
                            {root('cancel')}
                        </CancelButton>
                        <CloseJobButton onClick={handleCloseJob}>
                            {root('close-job')}
                        </CloseJobButton>
                    </CloseContainer>
                </ConfirmCancelContainer>
            );
        }
        if(!!job){
            return (<>
                <Title>{root('title')}</Title>
                <JobDetail job={job}/>
                <CloseContainer>
                    {!!job.end_date && (<RemainingDays>
                        {root('remaining-days', {days: remainingDays})}
                    </RemainingDays>)}
                    {(user?.id === job.owner?.id) && (
                        <CloseJobButton onClick={() => setConfirmClose(true)}>
                            {root('close-job')}
                        </CloseJobButton>
                    )}
                </CloseContainer>
            </>);
        }
        return <></>;
    }, [closed, closing, confirmClose, error, errorClose, handleCloseJob, job, loading, remainingDays, root, user?.id]);

    return (<>
        <Backdrop onClick={handleClose} />
        <ModalContainer>
            <CloseButton onClick={handleClose}><RiCloseLine /></CloseButton>
            {currentElement}
        </ModalContainer>
    </>);
}

export default DetailModal;