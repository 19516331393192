import { AxiosError } from 'axios';
import { StorageData } from './../storage-data';

export interface SignInState {
    magicLinkRequest: StorageData<boolean | null, Error | AxiosError | null>;
    authenticatedRequest: StorageData<boolean | null, Error | AxiosError | null>;
}

export const INITIAL_STATE: SignInState = {
    magicLinkRequest: {
        loading: false,
        data: null,
        error: null
    },
    authenticatedRequest: {
        data: null,
        loading: false,
        error: null
    },
}

export enum SignInActionTypes {
    SEND_MAGIC_LINK = '@sign/SEND_MAGIC_LINK',
    SEND_MAGIC_LINK_SUCCESS = '@sign/SEND_MAGIC_LINK_SUCCESS',
    SEND_MAGIC_LINK_ERROR = '@sign/SEND_MAGIC_LINK_ERROR',
    AUTHENTICATE = '@sign/AUTHENTICATE',
    AUTHENTICATE_SUCCESS = '@sign/AUTHENTICATE_SUCCESS',
    AUTHENTICATE_ERROR = '@sign/AUTHENTICATE_ERROR',
}
