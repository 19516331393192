import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiLogOut } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import LanguageSelection from "../../../../components/language-selection";
import { useQuery } from "../../../../hooks";
import useDrawer from "../../../../hooks/use-drawer";
import Drawer from "../../../../pages/private/home-dashboard/components/drawer/drawer";
import FilterForm from "../../../../pages/private/home-dashboard/components/filter-form/filter-form";
import NotificationArea from "../../../../pages/private/home-dashboard/components/notification-area/notification-area";
import { AppState } from "../../../../store/app-state";
import { logout } from "../../../../store/auth/auth-actions";
import { ViewMobile } from "../../../../styles/themes/responsive.styled";
import {
  Brandings,
  Container,
  Logo,
  MenuButton,
  MenuList,
  MenuListItem,
  MenuListItemHiddenMobile,
  MenuSandwitch
} from "./header.styled";
import Notification from '../../../../components/notification'
import MenuCrossButton, { MenuOption } from "../../../../components/menu-cross-button";
import { MenuSpan } from "../../../../components/menu-cross-button/index.styled";
import { useHistory } from "react-router-dom";


const Header: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.authState.user);
  const { notifications } = useSelector((state: AppState) => state.eventState);

  const companyPlan = useSelector(
    (state: AppState) => state.companyState.planData.data
  );
  const dispatch = useDispatch();
  const [query, setQuery] = useQuery<{ myBusiness: boolean, selectedCandidates?: boolean, talentPool?: boolean }>();
  const enableControle = false;
  const { toggleDrawer } = useDrawer('filter');
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleMyBusinessControl = useCallback(() => {
    setQuery({ myBusiness: true });
  }, [setQuery]);

  const handleClick = useCallback((e: any, url: string) => {
    e.stopPropagation();
    setShowMenu(false)
    history.push(url);
  }, [history]);

  const menuOptions: MenuOption[] = useMemo(() => [
    {
      option: <MenuSpan>{t("menu.ranking")}</MenuSpan>,
      onClick: (e) => handleClick(e, '/home/talent-match?talentPool=true')
    },
    {
      option: <MenuSpan>{t("menu.vacancies")}</MenuSpan>,
      onClick: (e) => handleClick(e, '/jobs')
    },
    {
      option: <MenuSpan>{t("menu.logout")}<FiLogOut /></MenuSpan>,
      onClick: handleLogout
    }
  ], [t, handleLogout, handleClick]);

  return (
    <Container>
      <Notification messages={notifications} timing={10000} />

      <Brandings>
        <Logo src={"/assets/img/talent-logo.svg"} alt="DIO" />
        <ViewMobile>
          <MenuButton type="button" onClick={toggleDrawer}>
            <MenuSandwitch src="/assets/img/menu-header.svg" />
          </MenuButton>
        </ViewMobile>
      </Brandings>
      <MenuList>
        <MenuListItem>
          <LanguageSelection />
        </MenuListItem>
        {companyPlan?.total_leed !== undefined && companyPlan?.total_leed > 0 && (
          <MenuListItem>
            {companyPlan?.used_leed}/{companyPlan?.total_leed}
          </MenuListItem>
        )}
        <MenuListItemHiddenMobile>{user?.name}</MenuListItemHiddenMobile>
        <MenuListItem>
          <NotificationArea />
        </MenuListItem>
        {enableControle && (
          <MenuListItem>
            <MenuButton type="button" onClick={handleMyBusinessControl}>
              {t("control")}
            </MenuButton>
          </MenuListItem>
        )}
        {enableControle && (
          <MenuListItem>
            <MenuButton type="button" onClick={handleLogout}>
              {t("logout")} <FiLogOut />
            </MenuButton>
          </MenuListItem>
        )}
        <MenuCrossButton options={menuOptions} isOpen={showMenu} onClick={() => setShowMenu(!showMenu)}/>
      </MenuList>
      <ViewMobile>
        <Drawer title={'filter'}>
          <FilterForm
            initialMaxSalary={50000}
            isSelectedsCandidate={query.selectedCandidates}
            isCandidatePool={query.talentPool}
          />
        </Drawer>
      </ViewMobile>
    </Container>
  );
};

export default Header;
