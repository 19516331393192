import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ButtonPrimary, ButtonSecondary } from "../form/button/button.styled";
import { JobOfferContext } from "./context";
import { Buttons, Field, Footer, Icon, Infos, Skill } from "./joboffer.styled";

const JobOfferDetails: React.FC<{
  handleOpenEdit: () => void;
  onDismiss: () => void;
  onConfirm: () => void;
}> = ({ handleOpenEdit, onDismiss, onConfirm }) => {
  const { t } = useTranslation("private-home-page");
  const handleClick = () => {
    handleOpenEdit();
  };

  const {
    joinTextExperience,
    joinTextSkills,
    joinTextWorkType,
    joinTextSalaryRange,
    joinTextEnglish,
    joinTextDiversity,
    jobofferform,
  } = useContext(JobOfferContext);

  return (
    <>
      <Infos>
        <Skill>
          <p>{`${t("jobOffer.modal.coreTechnology")} ${
            jobofferform?.ecosystem ? jobofferform.ecosystem.name : "Java"
          }`}</p>
          <Icon>
            <img
              src="/assets/img/pencil-icon.svg"
              alt=""
              onClick={() => handleClick()}
            />
          </Icon>
        </Skill>
        <Field>
          <p>{`${t("jobOffer.modal.salaryRange")} ${joinTextSalaryRange(
            jobofferform?.minSalary,
            jobofferform?.maxSalary
          )}`}</p>
        </Field>
        <Field>
          <p>
            {`${t("jobOffer.modal.experience")} ${joinTextExperience(
              jobofferform?.experience
            )}`}
          </p>
        </Field>
        <Field>
          <p>{`${t("jobOffer.modal.skills")} ${joinTextSkills(
            jobofferform?.skills
          )}`}</p>
        </Field>
        <Field>
          <p>
            {`${t("jobOffer.modal.hireType")} ${joinTextWorkType(
              jobofferform?.hireTypes
            )}`}
          </p>
        </Field>
        <Field>
          <p>
            {`${t("jobOffer.modal.workType")} ${joinTextWorkType(
              jobofferform?.workType
            )}`}
          </p>
        </Field>
        <Field>
          <p>
            {`${t("jobOffer.modal.city")} ${
              jobofferform?.workplace ||
              t("private-home-page:jobOffer.modal.notInformed")
            }`}
          </p>
        </Field>
        <Field>
          <p>{`${t("jobOffer.modal.english")} ${joinTextEnglish(
            jobofferform?.english
          )}`}</p>
        </Field>
        <Field>
          <p>{`${t("jobOffer.modal.diversity")} ${joinTextDiversity(
            jobofferform?.diversity
          )}`}</p>
        </Field>
      </Infos>
      <Footer>
        <Buttons>
          <ButtonSecondary onClick={() => onDismiss()}>
            {t("jobOffer.buttons.cancel")}
          </ButtonSecondary>
          <ButtonPrimary onClick={() => onConfirm()}>
            {t("jobOffer.buttons.submit")}
          </ButtonPrimary>
        </Buttons>
      </Footer>
    </>
  );
};

export default JobOfferDetails;
