import styled from "styled-components";


export const Container = styled.div`
	width: 43rem;
    height: 23rem;
    overflow-y: scroll;
    border: 1px solid #D9D9D9;
    border-radius: 0.5rem;
    align-self: center;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 0.4rem;
        height: 0.4rem;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background-color: #bdbdbd;
    }
`;

export const FieldNameContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const Field = styled.span`
    color: #452470;
    font-weight: 600;
`;

export const Name = styled.span`
    color: #222;
`;

export const Description = styled.div`
    border: 1px solid #D9D9D9;
    border-radius: 0.5rem;
    padding: 0 0.5rem;
    overflow: scroll;
    max-height: 20rem;

    * {
        color: #222;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 0.4rem;
        height: 0.4rem;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background-color: #bdbdbd;
    }
`;