import styled from 'styled-components';
import { DESKTOP } from './../../../../../../../../../styles/breakpoints';

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    @media(min-width: ${DESKTOP}) {
        flex-direction: row;
    }
`;
