import { t } from "i18next";
import queryString from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { JobOfferPayload } from "../../models/candidate-action.model";
import { AppState } from "../../store/app-state";
import { createJobOffer } from "../../store/candidate/candidate-actions";
import { currencyFormatter } from "../../utils/redux/currency-formatter";
import { DEFAULT_ECOSYSTEM, englishTypeOptions } from "./constants";
import { JobofferForm, JobofferQuery, SelectLabelValue } from "./context";

const useJobOffer = () => {
  const location = useLocation();
  const initialQuery = queryString.parse(location.search);
  const [queryJobOffer] = useState<JobofferQuery>(initialQuery);

  const dispatch = useDispatch();
  const { data: ecosystems } = useSelector(
    (state: AppState) => state.candidateState.candidateEcosystemsData
  );

  const { data: diversities } = useSelector(
    (state: AppState) => state.candidateState.candidateDiversitiesData
  );

  const skillsList = useSelector(
    (state: AppState) =>
      state.candidateState.currentCandidatePoolSkillsData.data?.map((s) => ({
        value: s,
        label: s,
      })) || undefined
  );

  const getEcosystem = useMemo(() => {
    if (queryJobOffer.ecosystem) {
      return ecosystems?.find((e) => e.id === queryJobOffer.ecosystem);
    }

    return DEFAULT_ECOSYSTEM;
  }, [queryJobOffer.ecosystem, ecosystems]);

  const getSkills = useMemo(() => {
    if (queryJobOffer.skills) {
      if (!Array.isArray(queryJobOffer?.skills)) {
        const workTypeWithLabel = {
          value: queryJobOffer?.skills,
          label: queryJobOffer?.skills,
        };
        return [workTypeWithLabel];
      }

      return queryJobOffer.skills.map((sk) => ({ value: sk, label: sk }));
    }
  }, [queryJobOffer.skills]);

  const getHireType = useMemo(() => {
    if (queryJobOffer.hireTypes) {
      if (!Array.isArray(queryJobOffer?.hireTypes)) {
        const hireTypeWithLabel = {
          value: queryJobOffer.hireTypes,
          label: queryJobOffer.hireTypes,
        };

        return [hireTypeWithLabel];
      }

      return queryJobOffer.hireTypes?.map((ht) => ({ value: ht, label: ht }));
    }
  }, [queryJobOffer.hireTypes]);

  const getWorkType = useMemo(() => {
    if (queryJobOffer.workType) {
      if (!Array.isArray(queryJobOffer?.workType)) {
        const workTypeWithLabel = {
          value: queryJobOffer.workType,
          label: queryJobOffer.workType,
        };

        return [workTypeWithLabel];
      }

      return queryJobOffer.workType?.map((wt) => ({ value: wt, label: wt }));
    }
  }, [queryJobOffer.workType]);

  const getExperience = useMemo(() => {
    if (queryJobOffer.experience) {
      if (!Array.isArray(queryJobOffer?.experience)) {
        const experienceWithLabel = {
          value: queryJobOffer.experience,
          label: queryJobOffer.experience,
        };

        return [experienceWithLabel];
      }

      return queryJobOffer?.experience?.map((exp) => ({
        value: exp,
        label: exp,
      }));
    }
  }, [queryJobOffer.experience]);

  const getDiversity = useMemo(() => {
    if (queryJobOffer.diversity) {
      if (!Array.isArray(queryJobOffer?.diversity)) {
        const diversityWithLabel = {
          value: queryJobOffer.diversity,
          label: queryJobOffer.diversity,
        };

        return [diversityWithLabel];
      }

      return queryJobOffer?.diversity?.map((exp) => ({
        value: exp,
        label: exp,
      }));
    }
  }, [queryJobOffer.diversity]);

  const getEnglish = useMemo(() => {
    if (queryJobOffer.english) {
      let englishLevels: SelectLabelValue[] = [];

      if (!Array.isArray(queryJobOffer.english)) {
        let formatted = englishTypeOptions.find(
          (en) => en.value.toUpperCase() === queryJobOffer.english
        );

        formatted &&
          englishLevels.push({
            value: formatted.value,
            label: t(formatted.label),
          });
        return englishLevels;
      }

      for (const level of queryJobOffer.english) {
        let formatted = englishTypeOptions.find(
          (en) => en.value === level.toLocaleLowerCase()
        );

        formatted &&
          englishLevels.push({
            value: formatted.value,
            label: t(formatted.label),
          });
      }
      return englishLevels;
    }
  }, [queryJobOffer.english]);

  const initialState = {
    ecosystem: getEcosystem,
    skills: getSkills,
    minSalary: queryJobOffer?.minSalary,
    maxSalary: queryJobOffer?.maxSalary,
    workType: getWorkType,
    hireTypes: getHireType,
    experience: getExperience,
    workplace: queryJobOffer?.workplace,
    diversity: getDiversity,
    english: getEnglish,
  };

  const [jobofferform, setJobofferform] = useState<JobofferForm>(initialState);

  const onResetJobOffer = () => {
    setJobofferform(initialState);
  };

  const onCreateJobOffer = useCallback(() => {
    let job_offer: JobOfferPayload = {};

    if (jobofferform.ecosystem) {
      job_offer["main_skill"] = jobofferform.ecosystem.name;
    }

    if (jobofferform.minSalary || jobofferform.maxSalary) {
      let salary_range = ["0", "0"];

      if (jobofferform.minSalary) {
        salary_range[0] = jobofferform.minSalary;
      }

      if (jobofferform.maxSalary) {
        salary_range[1] = jobofferform.maxSalary;
      }

      const isSalaryRange = salary_range.find((sal) => parseInt(sal) > 0);

      if (isSalaryRange) {
        job_offer["salary_range"] = [
          formattedMoney(salary_range[0]),
          formattedMoney(salary_range[1]),
        ];
      }
    }

    if (jobofferform.experience) {
      job_offer["experience_time"] = [];

      for (const et of jobofferform.experience) {
        job_offer["experience_time"].push(et.label);
      }
    }

    if (jobofferform.skills) {
      job_offer["skills"] = [];

      for (const sk of jobofferform.skills) {
        job_offer["skills"].push(sk.label);
      }
    }

    if (jobofferform.workType) {
      job_offer["work_type"] = [];

      for (const wt of jobofferform.workType) {
        job_offer["work_type"].push(wt.label);
      }
    }

    if (jobofferform.hireTypes) {
      job_offer["hire_types"] = [];

      for (const wt of jobofferform.hireTypes) {
        job_offer["hire_types"].push(wt.label);
      }
    }

    if (jobofferform.english) {
      job_offer["english"] = [];

      for (const wt of jobofferform.english) {
        job_offer["english"].push(wt.label);
      }
    }

    if (jobofferform.diversity) {
      job_offer["diversity"] = [];

      for (const wt of jobofferform.diversity) {
        job_offer["diversity"].push(wt.label);
      }
    }

    if (jobofferform.workplace) {
      job_offer["workplace"] = jobofferform.workplace;
    }
    dispatch(createJobOffer(job_offer));
  }, [dispatch, jobofferform]);

  const updateJobOffer = () => {
    onCreateJobOffer();
  };

  const filteredSkills = (skills: SelectLabelValue[]) => {
    let isFiltered = skills && skills.length > 0;

    if (isFiltered) {
      return skills?.map((querySkill) =>
        skillsList?.find((appSkill) => appSkill.value === querySkill.value)
      );
    }
  };

  const joinTextSkills = (skills?: SelectLabelValue[]) => {
    if (!skills) {
      return t("private-home-page:jobOffer.modal.notInformed");
    }
    if (!Array.isArray(skills)) {
      skills = [skills];
    }
    return skills.length > 0
      ? filteredSkills(skills)
          ?.map((skill) => skill && skill.value)
          .join(" - ")
      : t("private-home-page:jobOffer.modal.notInformed");
  };

  const joinTextExperience = (experienceTimes: JobofferForm["experience"]) => {
    return experienceTimes
      ? experienceTimes.map((exp) => exp && exp.label).join(" - ")
      : t("private-home-page:jobOffer.modal.notInformed");
  };

  const joinTextDiversity = (divs: JobofferForm["diversity"]) => {
    let filteredDiversities = divs?.map((div) =>
      diversities?.find((divOption) => divOption.id === div.value)
    );

    return filteredDiversities
      ? filteredDiversities?.map((exp) => exp?.name).join(" - ")
      : t("private-home-page:jobOffer.modal.notInformed");
  };

  const joinTextWorkType = (workTypes: JobofferForm["skills"]) => {
    return workTypes
      ? workTypes
          .map((worktype) => worktype && worktype.label.toLocaleUpperCase())
          .join(" - ")
      : t("private-home-page:jobOffer.modal.notInformed");
  };

  const joinTextEnglish = (levels: JobofferForm["english"]) => {
    return Array.isArray(levels)
      ? levels?.map((level) => level && level.label).join(" - ")
      : t("private-home-page:jobOffer.modal.notInformed");
  };

  const joinTextSalaryRange = (minSalary = "", maxSalary = "") => {
    const isSalaryRange = minSalary || maxSalary;

    if (isSalaryRange) {
      return joinedFormattedSalary(minSalary, maxSalary);
    }
    return t("private-home-page:jobOffer.modal.notInformed");
  };

  const joinedFormattedSalary = (minSalary: string, maxSalary: string) => {
    let rangeFormatted = [];
    const moneyFormat = (value: string) =>
      currencyFormatter(Number(value), {
        monetrayPrefix: true,
      });

    minSalary && rangeFormatted.push(moneyFormat(minSalary));
    maxSalary && rangeFormatted.push(moneyFormat(maxSalary));

    return rangeFormatted.sort().join(" - ");
  };

  const formattedMoney = (value: string) => {
    const moneyFormat = (value: string) =>
      currencyFormatter(Number(value), {
        monetrayPrefix: true,
      });
    return moneyFormat(value);
  };

  useEffect(() => {
    if (jobofferform) {
      onCreateJobOffer();
    }
  }, [jobofferform, onCreateJobOffer]);

  return {
    jobofferform,
    joinTextDiversity,
    joinTextEnglish,
    joinTextExperience,
    joinTextSalaryRange,
    joinTextSkills,
    joinTextWorkType,
    onCreateJobOffer,
    setJobofferform,
    updateJobOffer,
    onResetJobOffer,
  };
};

export default useJobOffer;
