import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem;
    max-width: 400px;
`;

export const Technology = styled.div`
    margin: .5rem;
    svg {
        width: 30px;
        height: 30px;
    }
`;
