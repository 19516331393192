import React from 'react';

export interface WageClaimProps {
    value: any;
}

const WageClaim: React.FC<WageClaimProps> = ({ value }) => (
    <>
        {value &&
            <>
                {parseFloat(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </>
        }
    </>
)

export default WageClaim;