import styled from "styled-components";


export const Container = styled.div`
    margin: 1rem;
    align-self: flex-start;
`;

export const Label = styled.p`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: #452470;
`;