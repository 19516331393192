
import styled from 'styled-components';

export const Container = styled.p`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
`;

export const Indicator = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .25rem .5rem;
    color: #452470;
`;

export const Label = styled.span`
    text-transform: lowercase;
    font-size: 1rem;
    margin-bottom: .5rem;
`;

export const Value = styled.span`
    color: #452470;
    font-size: 2rem;
    font-weight: 700;
    padding: .25rem 0;
`;

export const Separator = styled(Value)`
    align-self: flex-start;
    margin-top: .2rem;
`;
