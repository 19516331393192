import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Balls from "./components/balls";
import Slider from "./components/slider";
import Slide1 from "./components/slides/slide-1";
import Slide2 from "./components/slides/slide-2";
import Slide3 from "./components/slides/slide-3";
import { Backdrop, Button, ModalContainer } from "./index.styled";

interface InstructionModalProps {
    onClickCreate: () => void;
}
const InstructionModal: React.FC<InstructionModalProps> = ({ onClickCreate }) => {
    const { t } = useTranslation('private-home-page');
    const [current, setCurrent] = useState<number>(0);

    const currentSlide = useMemo(() => {
        switch(current){
            case 0:
                return <Slide1 />;
            case 1:
                return <Slide2 />;
            case 2:
                return <Slide3 />;
        }
    }, [current]);

    return (<>
        <Backdrop />
        <ModalContainer>
            <Slider
                onPrev={() => setCurrent(current-1)}
                onNext={() => setCurrent(current+1)}
                showPrev={current !== 0}
                showNext={current !== 2}
            >
                {currentSlide}
            </Slider>
            <Balls current={current} total={3} setCurrent={(n) => setCurrent(n)}/>
            {(current === 2) ? (
                <Button onClick={onClickCreate}>
                    {t('job-board.instructions-modal.create-job')}
                </Button>
            ) : (
                <Button onClick={() => setCurrent(current+1)}>
                    {t('job-board.instructions-modal.next')}
                </Button>
            )}
        </ModalContainer>
    </>);
}

export default InstructionModal;