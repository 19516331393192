import { setLightness } from 'polished';
import styled from 'styled-components';
import Button from '../../../../../../../../../../../components/form/button/button';
import { TABLET } from '../../../../../../../../../../../styles/breakpoints';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media(min-width: ${TABLET}) {
        margin: 0 2rem;
        max-width: ${TABLET};
    }
`;

export const Title = styled.h3`
    width: 100%;
    padding-bottom: 1rem;
    color: ${({ theme }) => theme.colors.primary.main};
    border-bottom: 1px solid ${({ theme }) => setLightness(.8, theme.typography.regular.color)};
    text-transform: uppercase;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    @media(min-width: ${TABLET}) {
        justify-content: space-between;
    }
`;

export const Indicator = styled.p`
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
`;

export const Chart = styled(Indicator)`
    justify-content: center;
    align-items: center;
    margin: 0;

    @media(min-width: ${TABLET}) {
        flex-direction: row;
    }
`;

export const Label = styled.span`
    padding: .5rem 0;
    max-width: 140px;
    text-align: center;

    @media(min-width: ${TABLET}) {
        text-align: left;
    }
`;

export const Value = styled.span`
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.primary.main};
`;

export const Actions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;

    @media(min-width: ${TABLET}) {
        justify-content: flex-start;
    }
`;

export const Action = styled(Button)`
    padding-left: 4rem;
    padding-right: 4rem;
`;
