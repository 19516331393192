import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import useChat from "../../../hooks/use-chat";
import useQuery from "../../../hooks/use-query";
import { HomePageContent } from "../../../models/home-page-content.enum";
import Track from "../../../models/track.model";
import { fetchCompanyPlan } from "../../../store/company/company-actions";
import { clearCurrentEventTrackData, fetchEventData, fetchEventTracks } from "../../../store/event/event-actions";
import { fetchTabChanged } from "../../../store/tab-changed/tab-changed-actions";
import MyBusinessContent from "./components/my-business-content/my-business-content";
import SelectedCandidatesContent from "./components/selected-candidates-content/selected-candidates-content";
import TabsMenu from "./components/tabs-menu/tabs-menu";
import { TalentPoolContent } from "./components/talent-pool-content/talent-pool-content";
import TrackContent from "./components/track-content/track-content";
import HomeDashboardPageQuery from "./home-dashboard-page-query.model";
import { Container } from "./home-page.styled";

interface HomeDashboardPageParams {
    event: string
}

const HomeDashboardPage: React.FC = () => {
    const dispatch = useDispatch();
    const { event: eventSlug } = useParams<HomeDashboardPageParams>();
    const [query, setQuery] = useQuery<HomeDashboardPageQuery>();

    useChat();

    const content: HomePageContent = useMemo(() => {
        if (query.track) {
            return HomePageContent.TRACKING;
        }

        if (query.talentPool) {
            return HomePageContent.TALENT_POOL;
        }

        if (query.myBusiness) {
            return HomePageContent.MY_BUSINESS;
        }

        return HomePageContent.SELECTED_CANDIDATES;
    }, [query]);


    useEffect(() => {
        dispatch(fetchEventData(eventSlug));
        dispatch(fetchEventTracks(eventSlug));
        dispatch(fetchCompanyPlan());
    }, [dispatch, eventSlug]);

    const handleSelectTrackTab = useCallback((track: Track) => {
        dispatch(fetchTabChanged());
        setQuery({ track: track.uuid });
    }, [setQuery, dispatch]);

    const handleSelectCandidatesTab = useCallback(() => {
        dispatch(fetchTabChanged());
        setQuery({ selectedCandidates: 'true' });
        dispatch(clearCurrentEventTrackData());
    }, [setQuery, dispatch]);

    const handleSelectTalentPoolTab = useCallback(() => {
        dispatch(fetchTabChanged());
        setQuery({ talentPool: 'true' });
        dispatch(clearCurrentEventTrackData());
    }, [setQuery, dispatch]);


    return (
        <>
            <TabsMenu
                onSelectedTrack={handleSelectTrackTab}
                onSelectedCandidates={handleSelectCandidatesTab}
                onSelectedTalentPool={handleSelectTalentPoolTab}
            />

            <Container>
                {content === HomePageContent.SELECTED_CANDIDATES &&
                    <SelectedCandidatesContent eventSlug={eventSlug} />
                }

                {content === HomePageContent.TRACKING &&
                    <TrackContent />
                }

                {content === HomePageContent.TALENT_POOL &&
                    <TalentPoolContent />
                }

                {content === HomePageContent.MY_BUSINESS &&
                    <MyBusinessContent />
                }
            </Container>
        </>
    );
}

export default HomeDashboardPage;
