
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../../../store/app-state';
import { fetchLastInvoice } from '../../../../../../../store/company/company-actions';
import InvoiceDashboard from './components/invoice-dashboard/invoice-dashboard';
import { Container } from './current-invoice.styled';
import InvoiceExtract from './invoice-extract/invoice-extract';

const CurrentInvoice: React.FC = () => {
    const dispatch = useDispatch();
    const { data: invoice, loading } = useSelector((state: AppState) => state.companyState.lastInvoiceData);

    useEffect(() => {
        dispatch(fetchLastInvoice());
    }, [dispatch]);

    return (
        <Container>
            <InvoiceDashboard invoice={invoice} loading={loading} />
            <InvoiceExtract />
        </Container>
    );

}

export default CurrentInvoice;
