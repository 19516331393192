import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { action } from "typesafe-actions";
import { jobBoardApi } from "../../api/talent/job-board-service";
import { JobBoardFormFields } from "../../pages/private/job-board/job-board-form.model";
import { AppState } from "../app-state";
import { JobBoardActionTypes } from "./job-board-types";

export const fetchFormOptions = () => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(JobBoardActionTypes.FETCH_FORM_OPTIONS_DATA));
        try {
            const response = await jobBoardApi.fetchFormOptions();
            dispatch(action(JobBoardActionTypes.FETCH_FORM_OPTIONS_DATA_SUCCESS, response.data));
        } catch (error) {
            dispatch(action(JobBoardActionTypes.FETCH_FORM_OPTIONS_DATA_ERROR, error));
        }
    }
};

export const fetchCities = (name: string) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(JobBoardActionTypes.FETCH_CITIES_DATA));
        try {
            const response = await jobBoardApi.fetchCities(name);
            dispatch(action(JobBoardActionTypes.FETCH_CITIES_DATA_SUCCESS, response.data));
        } catch (error) {
            dispatch(action(JobBoardActionTypes.FETCH_CITIES_DATA_ERROR, error));
        }
    }
};

export const fetchJobs = () => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(JobBoardActionTypes.FETCH_JOB_LIST_DATA));
        try {
            const response = await jobBoardApi.fetchJobs();
            dispatch(action(JobBoardActionTypes.FETCH_JOB_LIST_DATA_SUCCESS, response.data));
        } catch (error) {
            dispatch(action(JobBoardActionTypes.FETCH_JOB_LIST_DATA_ERROR, error));
        }
    }
};

export const fetchJobDetail = (id: string) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(JobBoardActionTypes.FETCH_JOB_DETAIL_DATA));
        try {
            const response = await jobBoardApi.fetchJobDetail(id);
            dispatch(action(JobBoardActionTypes.FETCH_JOB_DETAIL_DATA_SUCCESS, response.data));
        } catch (error) {
            dispatch(action(JobBoardActionTypes.FETCH_JOB_DETAIL_DATA_ERROR, error));
        }
    }
};

export const createJob = (jobData: JobBoardFormFields) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(JobBoardActionTypes.CREATE_JOB_DATA));
        try {
            const response = await jobBoardApi.createJob(jobData);
            dispatch(action(JobBoardActionTypes.CREATE_JOB_DATA_SUCCESS, response.data));
        } catch (error) {
            dispatch(action(JobBoardActionTypes.CREATE_JOB_DATA_ERROR, error));
        }
    }
}

export const closeJob = (id: string) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(JobBoardActionTypes.CLOSE_JOB_DATA));
        try {
            await jobBoardApi.closeJob(id);
            dispatch(action(JobBoardActionTypes.CLOSE_JOB_DATA_SUCCESS));
        } catch (error) {
            dispatch(action(JobBoardActionTypes.CLOSE_JOB_DATA_ERROR, error));
        }
    }
}