import styled from "styled-components";


export const Container = styled.ul`
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

`;

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    max-width: 35rem;
    align-self: center;
    padding: 7rem 0;
`;
    
export const Message = styled.span`
    color: #57397E;
    font-size: 1.25rem;
    text-align: center;
`;