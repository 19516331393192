import React, { useState } from "react";
import { JobOfferContext, JobofferForm } from "./context";
import useJobOffer from "./hook";

const JobOfferProvider: React.FC = ({ children }) => {
  const {
    jobofferform,
    joinTextDiversity,
    joinTextEnglish,
    joinTextExperience,
    joinTextSalaryRange,
    joinTextSkills,
    joinTextWorkType,
    onCreateJobOffer,
    onResetJobOffer,
    setJobofferform,
    updateJobOffer,
  } = useJobOffer();

  const [feedbackVisible] = useState(true);

  const handleFormChange = (partForm: Partial<JobofferForm>) => {
    setJobofferform({ ...jobofferform, ...partForm });
  };

  return (
    <JobOfferContext.Provider
      value={{
        feedbackVisible,
        handleFormChange,
        jobofferform,
        joinTextDiversity,
        joinTextEnglish,
        joinTextExperience,
        joinTextSalaryRange,
        joinTextSkills,
        joinTextWorkType,
        onCreateJobOffer,
        onResetJobOffer,
        updateJobOffer,
      }}
    >
      {children}
    </JobOfferContext.Provider>
  );
};

export default JobOfferProvider;
export { JobOfferProvider, JobOfferContext };
