import styled, { css } from "styled-components";

interface InputProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
}

export const Input = styled.div<InputProps>`
   align-items: center;
    background: #FFFFFF;
    border-radius: 0.3125rem;
    border: 1px solid #D9D9D9;
    height: 2.125rem;
    font-size: 0.875rem;
    color: #858B8D;
    color: ${({theme}) => theme.typography.strong.color};
    display: flex;
    padding: .5rem .75rem;
    
    ${props => props.isErrored && css`
        color: ${({theme}) => theme.colors.error.main};
        border-color: ${({theme}) => theme.colors.error.main};
    `}
    
    ${props => props.isFocused && css`
        border-color: ${({theme}) => theme.colors.primary.hover};
    `}
    & + div {
        margin-top: .5rem;
    }
    svg {
        margin-right: .5rem;
        color: ${({theme}) => theme.typography.regular.color};
        ${props => props.isErrored && css`
            color: ${({theme}) => theme.colors.error.main};
        `}
        ${props => props.isFocused && css`
            color: ${({theme}) => theme.colors.primary.hover};
        `}
    }
    input {
        flex: 1;
        background: transparent;
        border: 0;
        outline: 0;
        color: ${({theme}) => theme.typography.strong.color};
        line-height: 2;
        width: 100%;
        &:focus {
            outline: 0;
        }
        &::placeholder {
            color: ${({theme}) => theme.typography.regular.color};
            ${props => props.isFocused && css`
                color: ${({theme}) => theme.colors.primary.hover};
            `}
        }
        ${props => props.isErrored && css`
            color: ${({theme}) => theme.colors.error.main};
        `}
        ${props => props.isFocused && css`
            color: ${({theme}) => theme.colors.primary.hover};
        `}
    }
`;



export const Container = styled.div`
    align-items: center;
    height: 2.125rem;
    display: flex;
`;


export const Line = styled.div`
    align-items: center;
    background: #D9D9D9;
    display: flex;
    width: 1.9rem;
    height: 0.063rem;
    margin-left: .3rem;
    margin-right: .3rem;
`