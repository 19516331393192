import styled from "styled-components";

export const Container = styled.div``;

export const Title = styled.h4`
  margin: 1rem 0 0;
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 0.625rem;
  color: ${({ theme }) => theme.colors.primary.main};

  span {
    color: #9f9a9a;
    font-size: 0.75rem;
    font-weight: 400;
  }
`;

export const ShowJobOffer = styled.button`
  padding: 0.5rem;
  background: #ffffff;
  font-size: 0.75rem;
  border: 0.5px solid #452470;
  border-radius: 0.3125rem;
  color: #452470;
  position: relative;
  padding-right: 1.3125rem;
  height: 1.625rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    content: "";
    right: 5px;
  }
`;

export const JobOfferUnlocked = styled.div``;
