import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useDialog } from "../../../../hooks";
import { AppState } from "../../../../store/app-state";
import {
  candidateHired,
  candidateNotInterviewed,
  declineCandidate,
  denyJobOffer
} from "../../../../store/candidate/candidate-actions";
import Button from "../../../form/button/button";
import { SectionBlock, SectionList, Title } from "../../talent.styled";
import { ActionsList } from "./actions.styled";

interface ActionsProps {
  talentId: string;
  trackId: string;
  isLocked: boolean;
  onUnlockCandidate: () => void;
}

const Actions: React.FC<ActionsProps> = ({
  talentId,
  trackId,
  isLocked,
  onUnlockCandidate,
}) => {
  const { t } = useTranslation("private-home-page");
  const { createDialog } = useDialog();
  const dispatch = useDispatch();
  const loadingUnlockCandidate = useSelector(
    (state: AppState) => state.candidateState.unlockCandidateData.loading
  );

  const confirmNotifyToCandidate = useCallback(() => {
    return createDialog({
      title: t("talent.attentionRecruiter"),
      message: t("talent.confirmTalentNotification"),
    });
  }, [t, createDialog]);

  const handleHired = useCallback(async () => {
    const confirmed = await confirmNotifyToCandidate();

    if (confirmed) {
      dispatch(candidateHired(talentId, trackId));
    }
  }, [talentId, trackId, dispatch, confirmNotifyToCandidate]);

  const handleNotApproved = useCallback(async () => {
    const confirmed = await confirmNotifyToCandidate();

    if (confirmed) {
      dispatch(declineCandidate(talentId, trackId));
    }
  }, [talentId, trackId, dispatch, confirmNotifyToCandidate]);

  const handleNotInterviewed = useCallback(async () => {
    const confirmed = await confirmNotifyToCandidate();

    if (confirmed) {
      dispatch(candidateNotInterviewed(talentId, trackId));
    }
  }, [talentId, trackId, dispatch, confirmNotifyToCandidate]);

  const handleDenyJobOffer = useCallback(async () => {
    const confirmed = await confirmNotifyToCandidate();
    const reason = "1";

    if (confirmed) {
      dispatch(denyJobOffer(talentId, trackId, reason));
    }
  }, [talentId, trackId, dispatch, confirmNotifyToCandidate]);

  return (
    <>
      {isLocked ? (
        <ActionsList>
          <SectionList>
            <Button
              color="primary"
              onClick={onUnlockCandidate}
              loading={loadingUnlockCandidate}
            >
              {t("talent.unlockCandidate")}
            </Button>
          </SectionList>
        </ActionsList>
      ) : (
        <>
          <SectionBlock>
            <Title>{t("talent.enterTheCandidateStatus")}</Title>
            <ActionsList>
              <Button color="candidateHired" onClick={() => handleHired()}>
                {t("talent.hiredCandidate")}
              </Button>
              <Button
                color="candidateNotApproved"
                onClick={() => handleNotApproved()}
              >
                {t("talent.notApproved")}
              </Button>
              <Button
                color="candidateNotInterview"
                onClick={() => handleNotInterviewed()}
              >
                {t("talent.notInterviewed")}
              </Button>
              <Button
                color="candidateDenyJobOffer"
                onClick={() => handleDenyJobOffer()}
              >
                {t("talent.candidateDidNotAcceptOffer")}
              </Button>
            </ActionsList>
          </SectionBlock>
        </>
      )}
    </>
  );
};

export default Actions;
