import { Authentication } from './../../models/authentication.model';
import { SignIn } from './../../models/sign-in.model';
import talentApi from "./talent-api-config";

class AuthenticationApi {

    async sendMagicLink(signIn: SignIn): Promise<void> {
        return talentApi.post(`/talent/${signIn.event}/signin/`, { email: signIn.email });
    }

    async authenticate(authente: Authentication) {
        const queryParams = { url_auth_token: authente.tokenKey };
        return talentApi.get<{ token: string }>(`/talent/${authente.event}/signin/`, { params: queryParams });
    }

}

export const authenticationApi = new AuthenticationApi();
