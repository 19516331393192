import styled from "styled-components";


export const Container = styled.li<{closed: boolean}>`
    position: relative;
	width: 100%;
    background-color: ${({ closed }) => closed ? '#ddd' : '#fffa'};
    border: 1px solid ${({ closed }) => closed ? '#ddd' : '#57397E'};
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    cursor: ${({ closed }) => closed ? 'default' : 'pointer'};;
    gap: 0.25rem;

    * {
        color: ${({ closed }) => closed ? '#888' : '#57397E'};
        border-color: ${({ closed }) => closed ? '#888' : '#57397E'}!important;
    }
`;

export const AttributeRow = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 3fr;
    grid-gap: 1rem;
    border: 1px solid;
    border-left: none;
    border-right: none;
    padding: 0.25rem 0;
    margin: 0.5rem 0;
`;

export const DateRow = styled.div`
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Name = styled.span`
    font-weight: 600;
`;

export const Attribute = styled.span``;

export const ClosedJob = styled.span`
    color: #000; 
    font-weight: 600;
`;

export const New = styled.span`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: #C7BDD3;
    color: #452470;
    border: none;
    border-radius: 0.25rem;
    padding: 0.4rem 0.5rem;
    font-size: 0.75rem;
    width: fit-content;
    height: fit-content;
`;