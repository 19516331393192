import React, { HTMLAttributes } from "react";
import { DefaultTheme } from 'styled-components';
import { Container, Initials } from "./avatar.styled";

interface AvatarProps extends HTMLAttributes<HTMLDivElement> {
    loading?: boolean;
    color?: keyof DefaultTheme['colors'];
    initials: string;
    size?: number;
    fontSize?: number;
};

const Avatar: React.FC<AvatarProps> = ({ color, initials, size, fontSize }) => {
    return (
        <Container color={color} size={size}>
            <Initials fontSize={fontSize}>
                {initials.replace(' ', '')}
            </Initials>
        </Container>
    );
}

export default Avatar;
