import styled, { keyframes } from 'styled-components';

export const rotate = keyframes`
   0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

export const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(69, 36, 112, 0.6);
    backdrop-filter: blur(2px);
    z-index:1000;
  `;

export const LoadingSpinner = styled.div`
    width: 80px;
    height: 80px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: ${rotate} 1.5s linear infinite;
  `;

export const SpinnerContainer = styled.div`
    display: grid; 
    justify-content: center;
    align-items: center;
    height: 200px;
    margin-top: 200px;
  `;

export const LoadingMsg = styled.div`
    font-family: 'Open Sans'; 
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px;
  `;