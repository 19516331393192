import styled from "styled-components";
import { ViewDesktop } from "../filter-form/filter-form.styled";
import { DESKTOP } from './../../../../../styles/breakpoints';

export const Container = styled.div`
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media(min-width: ${DESKTOP}) {
        margin: 0 auto;
        max-width: 1440px;
    }
`;

export const DashboardHeader = styled.div<{ showCountdown?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media(min-width: ${DESKTOP}) {
        display: grid;
        grid-template-columns: 2fr ${({ showCountdown }) => showCountdown && '1fr'};
        align-items: flex-start;
        justify-content: space-around;
        width: 90%;
    }
`;

export const NotificationRow = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 .5rem;
`;

export const StatisticsRow = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .5rem;
`;

export const ViewDesktopFilters = styled(ViewDesktop)`
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: .5rem 3.125rem;
    min-width: 910px;
`;

export const CardCountdown = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 90%;

`;

export const ContainerTable = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
padding: 1rem 0 ;

@media(min-width: ${DESKTOP}) {
    max-width: 92%;
    margin: 0 auto;
}
`;

export const TrackTitle = styled.span`
    margin-bottom: 2rem;
    margin-left: 4.5rem;
    align-self: flex-start;
    color: #fff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;    
`;