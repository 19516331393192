import React, { ButtonHTMLAttributes } from "react";
import { DefaultTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { Container } from "./button.styled";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  color?: keyof DefaultTheme["colors"];
}

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  disabled,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Container type="button" {...props} disabled={loading || disabled}>
      {!!loading ? t("loading") : children}
    </Container>
  );
};

export default Button;
