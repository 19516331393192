import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../store/app-state";
import { ButtonPrimary, ButtonSecondary } from "../form/button/button.styled";
import Input from "../form/Input/input";
import RangeInput from "../form/range-input/range-input";
import SelectTag from "../form/select-tag/select-tag";
import Select from "../form/select/select";
import {
  englishTypeOptions,
  experienceTimeOptions,
  hireTypesOptions,
  workTypeOptions,
} from "./constants";
import { JobOfferContext } from "./context";
import {
  Buttons,
  EditField,
  EditInfos,
  Footer,
  Line,
  Range,
  SkillEdit,
} from "./joboffer.styled";

interface JobOfferFormProps {
  dismiss: () => void;
}

const JobOfferForm: React.FC<JobOfferFormProps> = ({ dismiss }) => {
  const { t } = useTranslation("private-home-page");

  const { jobofferform, updateJobOffer, handleFormChange, onResetJobOffer } =
    useContext(JobOfferContext);

  const handleUpdate = () => {
    updateJobOffer();
    dismiss();
  };

  const skillsList = useSelector(
    (state: AppState) =>
      state.candidateState.currentCandidatePoolSkillsData.data?.map((s) => ({
        value: s,
        label: s,
      })) || undefined
  );

  const { data: ecosystems } = useSelector(
    (state: AppState) => state.candidateState.candidateEcosystemsData
  );

  const { data: diversities } = useSelector(
    (state: AppState) => state.candidateState.candidateDiversitiesData
  );

  const handleDismiss = () => {
    onResetJobOffer();
    dismiss();
  };
  return (
    <>
      <EditInfos>
        <SkillEdit>
          <p>{`${t("jobOffer.modal.coreTechnology")}`}</p>
          {ecosystems && (
            <Select
              placeholder={t("filterform.select")}
              value={jobofferform?.ecosystem}
              values={ecosystems || []}
              onChange={(ecosystem) => handleFormChange({ ecosystem })}
              getElementLabel={(ecosystem) => ecosystem?.name || ""}
            />
          )}
        </SkillEdit>
        <EditField>
          <p>{t("jobOffer.modal.salaryRange")} </p>
          <Range>
            <RangeInput
              placeholder={t("filterForm.minRange")}
              value={jobofferform?.minSalary}
              debounceDelay={200}
              onDeboncedValueChange={(minSalary) =>
                handleFormChange({ minSalary })
              }
            />
            <Line />
            <RangeInput
              placeholder={t("filterForm.minRange")}
              value={jobofferform?.maxSalary}
              debounceDelay={200}
              onDeboncedValueChange={(maxSalary) =>
                handleFormChange({ maxSalary })
              }
            />
          </Range>
        </EditField>
        <EditField>
          <p>{t("jobOffer.modal.experience")} </p>
          <SelectTag
            placeholder={t("filterForm.select")}
            values={jobofferform?.experience}
            options={experienceTimeOptions}
            onChange={(experience) => handleFormChange({ experience })}
          />
        </EditField>
        <EditField>
          <p>{t("jobOffer.modal.skills")} </p>
          <SelectTag
            placeholder={t("filterForm.select")}
            values={jobofferform?.skills}
            options={skillsList}
            onChange={(skills) => handleFormChange({ skills })}
          />
        </EditField>
        <EditField>
          <p>{t("jobOffer.modal.hireType")}</p>
          <SelectTag
            placeholder={t("filterForm.select")}
            values={jobofferform?.hireTypes}
            options={hireTypesOptions.map((hireOption) => ({
              value: hireOption.value,
              label: t(hireOption.label),
            }))}
            onChange={(hireTypes) => handleFormChange({ hireTypes })}
          />
        </EditField>
        <EditField>
          <p>{t("jobOffer.modal.workType")} </p>
          <SelectTag
            placeholder={t("filterForm.select")}
            values={jobofferform?.workType}
            options={workTypeOptions}
            onChange={(workType) => handleFormChange({ workType })}
          />
        </EditField>
        <EditField>
          <p>{t("jobOffer.modal.city")}</p>
          <Input
            name="workplace"
            type="text"
            placeholder={t("filterForm.city")}
            value={jobofferform?.workplace}
            onValueChange={(workplace) => handleFormChange({ workplace })}
          />
        </EditField>
        <EditField>
          <p>{t("jobOffer.modal.english")}</p>
          <SelectTag
            placeholder={t("filterForm.selectTags")}
            values={jobofferform?.english}
            options={englishTypeOptions.map((enOption) => ({
              value: enOption.value,
              label: t(enOption.label),
            }))}
            onChange={(english) => handleFormChange({ english })}
          />
        </EditField>
        <EditField>
          <p>{t("jobOffer.modal.diversity")}</p>
          <SelectTag
            placeholder={t("filterForm.selectTags")}
            values={jobofferform?.diversity}
            options={
              diversities?.map((d) => ({ value: d.id, label: d.name })) || []
            }
            onChange={(diversity) => handleFormChange({ diversity })}
          />
        </EditField>
      </EditInfos>
      <Footer>
        <Buttons>
          <ButtonSecondary onClick={() => handleDismiss()}>
            {t("jobOffer.buttons.discard")}
          </ButtonSecondary>
          <ButtonPrimary onClick={() => handleUpdate()}>
            {t("jobOffer.buttons.save")}
          </ButtonPrimary>
        </Buttons>
      </Footer>
    </>
  );
};

export default JobOfferForm;
