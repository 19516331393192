import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useMemo } from 'react';
import DonutsChart from '../../../../../../../../../../../components/content/charts/donuts/donut-chart';
import { InvoiceStatus } from '../../../../../../../../../../../models/invoice-status.enum';
import { Invoice } from '../../../../../../../../../../../models/invoice.model';
import { currencyFormatter } from '../../../../../../../../../../../utils/redux/currency-formatter';
import { Action, Actions, Chart, Container, Content, Indicator, Label, Title, Value } from './invoice resume.styled';

interface InvoiceResumeProps {
    invoice?: Invoice | null;
    loading?: boolean;
}

const InvoiceResume: React.FC<InvoiceResumeProps> = ({ invoice, loading }) => {
    const monthName = useMemo(() => {
        if (!!invoice?.due) {
            return format(invoice.due, 'LLLL', { locale: ptBR });
        }
        return '';
    }, [invoice]);

    return (
        <Container>
            <Title>{loading ? 'Carregando' : monthName}</Title>

            <Content>
                <Indicator>
                    <Label>Fatura Atual</Label>
                    <Value>
                        {loading && '-'}
                        {!loading && currencyFormatter(invoice?.amount, { monetrayPrefix: true })}
                    </Value>
                </Indicator>

                <Indicator>
                    <Label>Vencimento</Label>
                    <Value>
                        {loading && '-'}
                        {invoice?.due && format(invoice.due, 'dd/MM/yyyy')}
                    </Value>
                </Indicator>

                <Chart>
                    <Label>Número de Contratados</Label>
                    <DonutsChart
                        total={invoice?.total_hired || 0}
                        value={invoice?.total_hired || 0}
                        custonStyle={{
                            width: '100px',
                            height: '100px',
                            fontSize: '.5rem',
                        }}
                    />
                </Chart>
            </Content>

            <Actions>
                {invoice?.status === InvoiceStatus.WAITING_PAYMENT &&
                    <Action
                        color={'error'}
                        loading={loading}
                    >
                        Pagar Fatura
                    </Action>
                }
            </Actions>
        </Container>
    );
}

export default InvoiceResume;

