import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

const useQuery = <T>(): [T, (newQuery: T) => void] => {
  const location = useLocation();
  const history = useHistory();
  const [query, setQuery] = useState<T>({} as T);

  useEffect(() => {
    setQuery(queryString.parse(location.search) as unknown as T);
  }, [location.search]);

  const updateQuery = useCallback((newQuery: T) => {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(newQuery)
    });
  }, [location, history]);

  return [query, updateQuery];
};

export default useQuery;
