import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background: none;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`;

export const Message = styled.span`
    color: #452470;
`;

export const Button = styled.button`
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 0.75rem;
    width: 10rem;
    background-color: #452470;
    color: #fff;

    &:hover {
        background-color: #6A4F8C;
    }
`;