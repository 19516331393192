import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NotificationModel, NOTIFICATION_STATUS } from "../models/notification.model";
import { AppState } from "../store/app-state";

const UseNotification = (): { messages: NotificationModel[] | [], hasNewNotifications: boolean } => {
    const notifications = useSelector((state: AppState) => state.eventState.notifications);
    const [hasNewNotifications, setHasNewNotifications] = useState<boolean>(false);
    const [localMessages, setLocalMessages] = useState<NotificationModel[] | []>([]);

    const [firedSound, setFiredSound] = useState<boolean>(false);
    const { t } = useTranslation("private-home-page");

    const translations = useMemo(() => new Map<string, string>(), []);
    translations.set("joinedTheEvent", t("notifications.joinedTheEvent"));
    translations.set("identifiedPotentialCandidate", t("notifications.identifiedPotentialCandidate"));
    translations.set("startedInterview", t("notifications.startedInterview"));
    translations.set("hiredProfessional", t("notifications.hiredProfessional"));

    const translateMessage = useCallback((message: NotificationModel) => {
        const translationsKeys = Array.from(translations.keys());

        for (let i = 0; i < translationsKeys.length; i++) {
            const translationKey = translationsKeys[i];
            if (message.message.includes(translationKey)) {
                let featured = message.message.replace(translationKey, "").replace(".", "");
                let actionMessage = message.message.replaceAll(featured, "");
                actionMessage = actionMessage.replaceAll(translationKey, translations.get(translationKey) || translationKey);

                setLocalMessages((prev) => {
                    return [
                        ...prev, {
                            ...message,
                            timestamp: message.timestamp,
                            translation: {
                                featured,
                                message: actionMessage
                            }
                        }
                    ];
                });
            }
        }

        return message;
    }, [translations]);

    const playCandidateHiredSound = useCallback(() => {
        if (!firedSound) {
            const notificationAudio = new Audio('/assets/audio/notification.mp3');

            const timeout = setTimeout(() => {
                notificationAudio.play();
            }, 580);

            setFiredSound(true);

            return () => {
                clearTimeout(timeout);
            }
        }
    }, [firedSound]);

    useEffect(() => {
        if (notifications.length > 0) {
            const localMessages = JSON.parse(
              window.localStorage.getItem("notifications_readed") ?? '[]') as NotificationModel[];
            
            setLocalMessages(() => []);
            setHasNewNotifications(localMessages.some(m => m.status === NOTIFICATION_STATUS.NOT_READ));
            localMessages.map((message: any) => {
              return translateMessage(message);
            });
        }
    }, [notifications, translateMessage]);

    useEffect(() => {
        playCandidateHiredSound();
    }, [localMessages, firedSound, playCandidateHiredSound]);


    return {
        messages: localMessages,
        hasNewNotifications,
    };
};



export default UseNotification;