import React from "react";
import EventCountdown from "../../../../../components/event-countdown/event-countdown";
import { Container, Title } from "./countdown.styled";

interface CountdownProps {
    title?: string;
    endDate?: Date;
    loading?: boolean;
    className?: string;
}

const Countdown: React.FC<CountdownProps> = ({ title, endDate, loading, className }) => {
    return (
        <Container className={className}>
            <Title dangerouslySetInnerHTML={{__html: title || ''}}></Title>
            <EventCountdown end={endDate} loading={loading} />
        </Container>
    );
}

export default Countdown;