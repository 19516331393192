import { ReducerBuilder } from "../../utils/redux/reducer-builder";
import { GlobalTypes } from './../global-types';
import { INITIAL_STATE, LoadActionTypes, LoadState } from "./load-types";

const reduxBuilder = new ReducerBuilder<LoadState>(INITIAL_STATE);

reduxBuilder.addReducer(GlobalTypes.CLEAR, () => {
    return INITIAL_STATE;
});

reduxBuilder.addMutableReducer(LoadActionTypes.SET_INVISIBLE, (state) => {
    state.isVisible.data = false;
});

reduxBuilder.addMutableReducer(LoadActionTypes.SET_VISIBLE, (state) => {
    state.isVisible.data = true;
});

export default reduxBuilder.build();