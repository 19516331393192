import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import {
  NotificationModel,
  NOTIFICATION_STATUS,
} from "../../models/notification.model";
import { Wrapper } from "./notification.styled";
import "react-toastify/dist/ReactToastify.css";

export interface NotificationProps {
  messages: NotificationModel[];
  timing: number;
}

const Notifications: React.FC<NotificationProps> = ({ messages, timing }) => {
  const { t } = useTranslation("private-home-page");
  const [now, setNow] = useState<Date>(new Date());
  const translations = useMemo(() => new Map<string, string>(), []);
  translations.set("joinedTheEvent", t("notifications.joinedTheEvent"));
  translations.set(
    "identifiedPotentialCandidate",
    t("notifications.identifiedPotentialCandidate")
  );
  translations.set("startedInterview", t("notifications.startedInterview"));
  translations.set("hiredProfessional", t("notifications.hiredProfessional"));

  const translateMessage = useCallback(
    (message: NotificationModel) => {
      const translationsKeys = Array.from(translations.keys());

      for (let i = 0; i < translationsKeys.length; i++) {
        const translationKey = translationsKeys[i];
        if (message.message.includes(translationKey)) {
          let featured = message.message
            .replace(translationKey, "")
            .replace(".", "");
          let actionMessage = message.message.replaceAll(featured, "");
          actionMessage = actionMessage.replaceAll(
            translationKey,
            translations.get(translationKey) || translationKey
          );

          toast(`${featured} ${actionMessage}`, {
            style: {
              backgroundColor: "#BF76AE",
              color: "#FFF",
              borderRadius: "10px",
            },
          });
        }
      }
      if (message.timestamp ?? new Date() > now) {
        setNow(message.timestamp ?? new Date());
      }

      return message;
    },
    [translations, now]
  );

  useEffect(() => {
    messages
      .filter(
        (m) =>
          m.status === NOTIFICATION_STATUS.NOT_READ &&
          m.timestamp &&
          m.timestamp > now
      )
      .map((m) => translateMessage(m));
  }, [messages, now, translateMessage]);

  if (messages.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={3}
      />
    </Wrapper>
  );
};

export default Notifications;
