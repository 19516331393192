import { MdKeyboardArrowDown, MdClose } from 'react-icons/md';
import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface SelectionProps {
    hasValue?: boolean;
}

export const SelectContainer = styled.div`
    background: #FFFFFF;
    border-radius: 0.3125rem;
    padding: 0.5rem;
    color: ${({ theme }) => theme.typography.strong.color};
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid #D9D9D9;
    height: 2.125rem;
    font-size: 0.875rem;
`;

export const Selection = styled.button<SelectionProps>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: .5rem;
    cursor: pointer;
    text-align: left;
    color: ${({ theme }) => theme.typography.regular.color};
    background-color: transparent;
    border: 1px solid transparent;
    margin: 1rem 0;


    ${props => props.hasValue && css`
        color: ${({ theme }) => theme.colors.primary.main};
    `}
`;

export const ArrowIcon = styled(MdKeyboardArrowDown)``;

export const SelectionList = styled(animated.ul)`
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    margin: 0;
    padding: .5rem;
    background-color: red;
    list-style: none;
    color: ${({ theme }) => theme.typography.strong.color};
    background: #FFFFFF;
    border-radius: 0.3125rem;
    z-index: 1;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.15);
    padding: 0;
    max-height: 20rem;
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 0.4rem;
        height: 0.4rem;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background-color: #bdbdbd;
    }
`;

export const CloseSelectionList = styled.button`
    border: none;
    border-radius: 50%;
    background: ${({ theme }) => theme.containers.main.backgroundColor};
    color: ${({ theme }) => theme.typography.regular.color};
    padding: 0;
    margin: 0;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

`;

export const CloseIcon = styled(MdClose)``;

export const CloseListItem = styled.li`
    position: absolute;
    top: calc(.5rem + 1px);
    right: calc(.5rem + 1px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const SelectionListItem = styled.li`
    &:hover {
        background: #F0F0F0;
        
    }
`;

export const SelectionItem = styled(Selection)``;
