import { CheckMark, Container } from "./index.styled";

const CheckAnimation: React.FC = () => {
    return (
        <Container>
            <CheckMark xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="circle" cx="26" cy="26" r="25" fill="none"/> 
                <path className="check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </CheckMark>
        </Container>
    );
}

export default CheckAnimation;