import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IJobOfferModel } from "./context";
import JobOfferProvider from "./controller";
import JobOfferDetails from "./details";
import Form from "./form";
import { Message, Modal, Title } from "./joboffer.styled";

const DialogElement = document.getElementById("dialog-confirm");

interface JobOfferProps extends IJobOfferModel {
  isOpen: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
}

const JobOfferModal: React.FC<JobOfferProps> = ({
  isOpen,
  onConfirm,
  onDismiss,
  ...props
}) => {
  const { t } = useTranslation("private-home-page");
  const [editIsVisible, setEditIsVisible] = useState(false);

  return (
    <JobOfferProvider>
      <Modal
        {...props}
        top={15}
        width={500}
        isShown={isOpen}
        toggle={onDismiss}
        target={DialogElement}
        handleClose={onDismiss}
      >
        <Title>{t("jobOffer.modal.attentionRecruiter")}</Title>
        <Message>{t("jobOffer.modal.confirmJobOfferNotification")}</Message>

        {editIsVisible ? (
          <Form dismiss={() => setEditIsVisible(false)} />
        ) : (
          <JobOfferDetails
            handleOpenEdit={() => setEditIsVisible(true)}
            onDismiss={onDismiss}
            onConfirm={onConfirm}
          />
        )}
      </Modal>
    </JobOfferProvider>
  );
};

export default JobOfferModal;
