import { format } from "date-fns";
import { enUS, es, ptBR } from "date-fns/locale";
import { useCallback, useEffect, useMemo, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { RiAddCircleLine, RiCalendarEventLine } from "react-icons/ri";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../components/form/Input/input";
import RangeInput from "../../../../../components/form/range-input/range-input";
import SelectTag from "../../../../../components/form/select-tag/select-tag";
import Select from "../../../../../components/form/select/select";
import { AppState } from "../../../../../store/app-state";
import {
  fetchCities,
  fetchFormOptions,
} from "../../../../../store/job-board/job-board-actions";
import { City } from "../../../../../store/job-board/job-board-types";
import {
  IdNameInterface,
  JobInterface,
  WorkModel,
} from "../../job-board-form.model";
import ToolTip from "../tooltip";
import { FORM_INITIAL_STATE, WORK_MODELS } from "./form.data";
import {
  DateFieldContent,
  DateInput,
  DescriptionFieldContent,
  FormContainer,
  FormRow,
  Line,
  Range,
  SubmitButton,
  TextFieldContent,
  Title,
  TitleFieldContent,
} from "./index.styled";

registerLocale("es", es);
registerLocale("en", enUS);
registerLocale("pt-BR", ptBR);

interface SelectOption {
  label: string;
  value: string;
}

interface FormProps {
  fields: JobInterface;
  setFields: (f: JobInterface) => void;
  onSubmit: () => void;
}
const Form: React.FC<FormProps> = ({ fields, setFields, onSubmit }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const root = useCallback(
    (key: string) => {
      return t(`private-home-page:job-board.form-fields.${key}`);
    },
    [t]
  );
  const [endDate, setEndDate] = useState(new Date(FORM_INITIAL_STATE.end_date));
  const [selectedCities, setSelectedCities] = useState<City[]>([]);
  const { data: formOptions } = useSelector(
    (state: AppState) => state.jobBoardState.formOptions
  );
  const { data: cities } = useSelector(
    (store: AppState) => store.jobBoardState.cities
  );

  const errorDescription = useMemo(() => {
    const description = fields.description.replace(/<[^>]*>?/gm, "");
    return description.trim().length < 20;
  }, [fields.description]);

  const errorSalary = useMemo(() => {
    const min = fields.min_salary ? parseInt(fields.min_salary) : 0;
    const max = fields.max_salary ? parseInt(fields.max_salary) : 0;
    return min > max;
  }, [fields.min_salary, fields.max_salary]);

  const submitDisabled = useMemo((): boolean => {
    return !fields.name.trim().length || errorDescription || errorSalary || !fields.diversitys.length;
  }, [fields.name,fields.diversitys,  errorDescription, errorSalary]);

  useEffect(() => {
    dispatch(fetchFormOptions());
  }, [dispatch]);

  const handleInputCity = useCallback(
    (input: string) => {
      if (input.length) {
        dispatch(fetchCities(input));
      }
    },
    [dispatch]
  );

  const convertToSelectOption = useCallback(
    (obj: IdNameInterface): SelectOption => {
      return !!obj
        ? {
            label: obj.name,
            value: obj.id,
          }
        : {
            label: "",
            value: "",
          };
    },
    []
  );

  const convertToIdName = useCallback((obj: SelectOption): IdNameInterface => {
    return !!obj
      ? {
          name: obj.label,
          id: obj.value,
        }
      : {
          name: "",
          id: "",
        };
  }, []);

  const getTagValues = useCallback(
    (
      form: IdNameInterface[] | IdNameInterface | any,
      options: IdNameInterface[] | any,
      multi: boolean = false
    ) => {
      if (multi) {
        if (!!form && form.length) {
          return (
            form?.map((v: IdNameInterface) =>
              convertToSelectOption(
                options?.find((t: IdNameInterface) => t.id === v.id)
              )
            ) || []
          );
        }
        return null;
      }
      if (form) {
        return options?.find((t: IdNameInterface) => t.id === form.id);
      }
      return null;
    },
    [convertToSelectOption]
  );

  const handleSelectEndDate = useCallback(
    (date: Date) => {
      if (date && date > new Date()) {
        setEndDate(date);
        setFields({ ...fields, end_date: format(date, "yyyy-MM-dd") });
      }
    },
    [fields, setFields, setEndDate]
  );

  const handleSelectSkills = useCallback(
    (obj: SelectOption[]) => {
      if (obj.length) {
        setFields({ ...fields, skills: obj.map((o) => convertToIdName(o)) });
      }
    },
    [convertToIdName, fields, setFields]
  );

  const handleSelectDiversity = useCallback(
    (obj: SelectOption[]) => {
      if (obj.length) {
        setFields({ ...fields, diversitys: obj.map((o) => convertToIdName(o)) });
      }
    },
    [convertToIdName, fields, setFields]
  );

  const handleSelectCity = useCallback(
    (obj: SelectOption[]) => {
      if (obj.length < 2) {
        if (obj.length > 0) {
          const id = parseInt(obj[0].value || "0");
          setSelectedCities([
            {
              label: obj[0].label || "",
              value: obj[0].value || "",
              city: id,
            },
          ]);
          setFields({
            ...fields,
            city: {
              id: id,
              name: obj[0].label,
            },
          });
        } else {
          setSelectedCities([]);
          delete fields.city;
        }
      }
    },
    [fields, setFields]
  );

  const handleSalary = useCallback(
    (salary?: string, max?: boolean) => {
      if (!salary) return "0";

      if (max) {
        setFields({
          ...fields,
          max_salary: salary,
        });
      } else {
        setFields({
          ...fields,
          min_salary: salary,
        });
      }
    },
    [fields, setFields]
  );

  const workModelValue = useMemo(() => {
    const wm = WORK_MODELS?.find((t: string) => t === fields.work_model) || "";
    return { name: root(`work_models.${wm.toLowerCase()}`), id: wm };
  }, [fields.work_model, root]);

  return (
    <FormContainer>
      <FormRow>
        <TitleFieldContent>
          <Title>{root("name")}</Title>
          <Input
            name="search"
            type="text"
            placeholder={root("name_placeholder")}
            value={fields?.name ?? ""}
            debounceDelay={200}
            onDeboncedValueChange={(newValue) =>
              setFields({ ...fields, name: newValue || "" })
            }
          />
        </TitleFieldContent>
        <TextFieldContent>
          <Title>{root("language")}</Title>
          <Select
            value={getTagValues(fields?.language, formOptions?.languages)}
            values={formOptions?.languages}
            onChange={(selected) =>
              setFields({
                ...fields,
                language: selected,
              })
            }
            getElementLabel={(language) => language?.name || ""}
          />
        </TextFieldContent>
        <TextFieldContent>
          <Title>{root("degree")}</Title>
          <Select
            value={getTagValues(fields?.degree, formOptions?.degrees)}
            values={formOptions?.degrees}
            onChange={(selected) =>
              setFields({
                ...fields,
                degree: selected,
              })
            }
            getElementLabel={(degree) => degree?.name || ""}
          />
        </TextFieldContent>
      </FormRow>
      <FormRow>
        <TextFieldContent>
          <Title>{root("work_model")}</Title>
          <Select
            value={workModelValue}
            values={WORK_MODELS.map((wm) => ({
              name: root(`work_models.${wm.toLowerCase()}`),
              id: wm,
            }))}
            onChange={(selected) =>
              setFields({
                ...fields,
                work_model:
                  WORK_MODELS.find((wm) => wm === selected.id) ||
                  WorkModel.PRESENTIAL,
              })
            }
            getElementLabel={(language) => language?.name || ""}
          />
        </TextFieldContent>
        <TextFieldContent>
          <Title>{root("hire_type")}</Title>
          <Select
            value={getTagValues(fields?.hire_type, formOptions?.hire_types)}
            values={formOptions?.hire_types}
            onChange={(selected) =>
              setFields({
                ...fields,
                hire_type: selected,
              })
            }
            getElementLabel={(hire_type) => hire_type?.name || ""}
          />
        </TextFieldContent>
        <TextFieldContent>
          <Title>{root("experience_time")}</Title>
          <Select
            value={getTagValues(
              fields?.experience_time,
              formOptions?.experience_times
            )}
            values={formOptions?.experience_times}
            onChange={(selected) =>
              setFields({
                ...fields,
                experience_time: selected,
              })
            }
            getElementLabel={(expt) => expt?.name || ""}
          />
        </TextFieldContent>
        <TextFieldContent>
          <Title>{root("experience_level")}</Title>
          <Select
            value={getTagValues(
              fields?.experience_level,
              formOptions?.experience_levels
            )}
            values={formOptions?.experience_levels}
            onChange={(selected) =>
              setFields({
                ...fields,
                experience_level: selected,
              })
            }
            getElementLabel={(expl) => expl?.name || ""}
          />
        </TextFieldContent>
      </FormRow>
      <FormRow>
        <TextFieldContent>
          <Title>{root("city")}</Title>
          <SelectTag
            placeholder={root("city_placeholder")}
            values={selectedCities}
            options={cities}
            onChange={(selectedValues) => {
              handleSelectCity(selectedValues);
            }}
            onInputChange={(input) => handleInputCity(input)}
          />
        </TextFieldContent>
        <TextFieldContent>
          <Title>{root("country")}</Title>
          <Select
            value={getTagValues(fields?.country, formOptions?.countries)}
            values={formOptions?.countries}
            onChange={(selected) =>
              setFields({
                ...fields,
                country: selected,
              })
            }
            getElementLabel={(country) => country?.name || ""}
          />
        </TextFieldContent>
        <TextFieldContent>
          <Title>{root("currency")}</Title>
          <Select
            value={getTagValues(fields?.currency, formOptions?.currencies)}
            values={formOptions?.currencies}
            onChange={(selected) =>
              setFields({
                ...fields,
                currency: selected,
              })
            }
            getElementLabel={(currency) => currency?.name || ""}
          />
        </TextFieldContent>
        <TextFieldContent>
          <Title>
            {root("salary.range")}
            {errorSalary && (
              <ToolTip error={true} message={root("tooltip.error-salary")} />
            )}
          </Title>
          <Range>
            <RangeInput
              name="minSalary"
              type="text"
              placeholder={root("salary.min")}
              value={`${fields.min_salary || 0}`}
              debounceDelay={200}
              onDeboncedValueChange={(salary) => handleSalary(salary, false)}
            />
            <Line />
            <RangeInput
              name="maxSalary"
              type="text"
              placeholder={root("salary.max")}
              value={`${fields.max_salary || 0}`}
              debounceDelay={200}
              onDeboncedValueChange={(salary) => handleSalary(salary, true)}
            />
          </Range>
        </TextFieldContent>
      </FormRow>
      <FormRow>
        <DescriptionFieldContent>
          <Title>
            {root("description")}
            <ToolTip message={root("tooltip.description")} />
          </Title>
          <ReactQuill
            theme="snow"
            value={fields.description}
            onChange={(value: string) =>
              setFields({ ...fields, description: value })
            }
          />
        </DescriptionFieldContent>

        <DateFieldContent>
          <Title>
            {root("diversity")}
            <ToolTip message={root("tooltip.diversity")} />
          </Title>
          <SelectTag
            values={getTagValues(
              fields?.diversitys,
              formOptions?.diversitys,
              true
            )}
            options={formOptions?.diversitys?.map((o) =>
              convertToSelectOption(o)
            )}
            onChange={(selectedValues) => {
              handleSelectDiversity(selectedValues);
            }}
          />
          <Title>{root("end_date")}</Title>

          <DatePicker
            selected={endDate}
            onChange={(date: Date) => handleSelectEndDate(date)}
            dayClassName={(date: Date): string => {
              return date > new Date() ? "future" : "past";
            }}
            customInput={<DateInput disabled={true} />}
            popperClassName={"date-popper"}
            className={"date-input"}
            locale={i18n.language}
            dateFormat={"dd/MM/yyyy"}
          />
          <RiCalendarEventLine className={"calender-icon"} />
        </DateFieldContent>

        <TextFieldContent>
          <Title>
            {root("skills")}
            <ToolTip message={root("tooltip.skills")} />
          </Title>
          <SelectTag
            values={getTagValues(fields?.skills, formOptions?.skills, true)}
            options={formOptions?.skills.map((o) => convertToSelectOption(o))}
            onChange={(selectedValues) => {
              handleSelectSkills(selectedValues);
            }}
          />
        </TextFieldContent>
      </FormRow>
    <SubmitButton onClick={onSubmit} disabled={submitDisabled}>
        <RiAddCircleLine />
        {root("submit")}
      </SubmitButton>
    </FormContainer>
  );
};

export default Form;
