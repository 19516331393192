import { ViewDesktop } from './../../../../../styles/themes/responsive.styled';
import styled from 'styled-components';
import { DESKTOP } from './../../../../../styles/breakpoints';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media(min-width: ${DESKTOP}) {
        max-width: 1156px;
        margin: 0 auto;
    }
`;

export const DashboardHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const NotificationRow = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .5rem;
`;

export const StatisticsRow = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .5rem;
`;

export const ViewDesktopFilters = styled(ViewDesktop)`
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1.625rem 3.125rem;
`;

export const CandidatesNotFound = styled.span`
    color: #858B8D;
    margin-top: 5rem;
    .icon-search {
        vertical-align: text-bottom;
        margin-right: .5rem;
        font-size: 1.5rem;
    }
`;