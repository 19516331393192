import axios, { AxiosError } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { AppState } from "../../../store/app-state";
import { fetchEventData } from "../../../store/event/event-actions";
import { sendMagicLink } from "../../../store/sign-in/sign-in-actions";
import AuthenticationForm from "./components/authentication-form/authentication-form";
import Header from "./components/header/header";
import Technologies from "./components/technologies/technologies";
import { Container, Content, MagicLinkContainer, MagicLinkSentError, MagicLinkSentMessage, Merchandising } from "./home-page.styled";

interface HomePageParams {
    event: string
}

const HomePage: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('public-home-page');
    const { event } = useParams<HomePageParams>();
    const magicLinkRequest = useSelector((state: AppState) => state.signInState.magicLinkRequest);
    const authenticatedRequest = useSelector((state: AppState) => state.signInState.authenticatedRequest);
    const { eventData } = useSelector((state: AppState) => state.eventState);
    const [unauthorizedError, setUnauthorizedError] = useState(false);
    const [unexpectedError, setUnexpectedError] = useState(false);
    const history = useHistory();
    const user = useSelector((state: AppState) => state.authState.user);

    const handleErrors = useCallback((error: Error | AxiosError) => {
        setUnauthorizedError(false);
        setUnexpectedError(false);

        if (axios.isAxiosError(error) && error.response?.status === 401) {
            setUnauthorizedError(true);
        } else {
            setUnexpectedError(true);
        }
    }, [setUnauthorizedError, setUnexpectedError]);

    const handleLogin = useCallback(async (email: string) => {
        setUnauthorizedError(false);
        setUnexpectedError(false);
        dispatch(sendMagicLink({ email, event }));
    }, [event, dispatch]);


    useEffect(() => {
        if (user && event) {
            if (event === 'home') {
                history.push(`/home/talent-match`);
            } else {
                history.push(`/home/${event}`);
            }
        }
    }, [user, event, history]);

    
    useEffect(() => {
        const error = magicLinkRequest.error || authenticatedRequest.error;
        if (error) {
            setUnauthorizedError(false);
            setUnexpectedError(false);
            handleErrors(error);
        }
    }, [magicLinkRequest.error, authenticatedRequest.error, handleErrors]);

    useEffect(() => {
        dispatch(fetchEventData(event));
    }, [dispatch, event]);



    return (
        <Container>
            <Header banner={eventData.data?.cover} />
            <Merchandising>
                {t('public-home-page:merchandising')}
            </Merchandising>

            <Content>
                <MagicLinkContainer>
                    {magicLinkRequest.data &&
                        <MagicLinkSentMessage>
                            {t('public-home-page:linkSentSuccessfully')} <br />
                            {t('public-home-page:checkYourEmail')}
                        </MagicLinkSentMessage>
                    }

                    {unexpectedError &&
                        <MagicLinkSentError>
                            {t('public-home-page:unexpectedError')} <br />
                            {t('public-home-page:tryAgainLater')}
                        </MagicLinkSentError>
                    }

                    {unauthorizedError &&
                        <MagicLinkSentError>
                            {t('public-home-page:youDoNotHaveAccess')} <br />
                            {t('public-home-page:pleaseContactUs')}
                        </MagicLinkSentError>
                    }

                    {!magicLinkRequest.data &&
                        <AuthenticationForm
                            onSubmit={handleLogin}
                            loading={magicLinkRequest.loading}
                        />
                    }
                </MagicLinkContainer>

                <Technologies />
            </Content>
        </Container>
    );
}

export default HomePage;