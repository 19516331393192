import styled from 'styled-components';

export const SocialNetworkList = styled.ul`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: flex-start;
   align-items: center;
   margin: 0;
   padding:0;
   list-style: none;
`;

export const SocialNetwork = styled.li`
   &:not(:first-child) {
      margin: .5rem;
   }
   
`;

export const SocialIcon = styled.img`

   &:not(:last-child) {
      margin-right: 0.625rem;
   }
`;

export const SocialNetworkLink = styled.a`
  text-decoration: none;
`;
