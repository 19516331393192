import styled from "styled-components";


export const Container = styled.div`
	display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
`;

export const Ball = styled.div<{selected: boolean}>`
    padding: 0;
    margin: 0;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    cursor: pointer;

    background-color: ${({selected}) => selected ? '#452470' : '#b4a7c5'};
`;