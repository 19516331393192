import styled from "styled-components";

export const MainContainer = styled.div`
    margin: 0 auto;
    padding: 0 1rem;
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const FormMainContainer = styled(MainContainer)`
    gap: 1rem;
    max-width: 1440px;
`;

export const TitleContainer = styled.div`
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const Title = styled.span`
    font-size: 1.5rem;
    font-weight: 600;
    color: #452470;
`;

export const Back = styled.button`
    display: flex;
    align-items: flex-end;
    gap: 0.25rem;
    color: #57397E;
    background: transparent;
    padding: 0.5rem 1rem;
    border: 1px solid #57397E;
    border-radius: 0.5rem;
    width: fit-content;
    display: flex;
    align-items: center;

    svg {
        font-size: 1.25rem;
    }
`;

export const AddButton = styled.button`
    border: none;
    border-radius: 0.5rem;
    background-color: #452470;
    color: #fff;
    padding: 0.5rem 1rem;
    width: fit-content;
    height: auto;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    svg {
        font-size: 1.25rem;
    }
    
    &:hover {
        background-color: #6A4F8C;
    }
`;

export const FormMessage = styled.span`
    display: flex;
    align-items: flex-end;
    gap: 0.25rem;
    color: #57397E;
`;