import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
`;

export const IconButton = styled.button`
    width: 3rem;
    height: 3rem;
    border: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primary.main};

    &:disabled {
        cursor: default;
        opacity: .5;
    }

    &:hover, &:focus, &:active {
        outline: 0;
    }

    svg {
        &:hover {
            color: ${({ theme }) => theme.colors.primary.hover};
        }
    }
`;


interface PageProps {
    isCurrent?: boolean;
}

export const Page = styled.button<PageProps>`
    width: 3rem;
    height: 3rem;
    border: none;
    background-color: transparent;
    border-radius: 1.5rem;
    margin: .5rem;
    border: 1px solid transparent;
    color: ${({ theme }) => theme.colors.primary.main};
    transition: border-color .2s, color .2s;

    ${({ isCurrent }) => isCurrent && css`
        border-color: ${({ theme }) => theme.colors.primary.main};
    `}
    
    &:hover {
        color: ${({ theme }) => theme.colors.primary.hover};
        border-color: ${({ theme }) => theme.colors.primary.hover};
    }
`;
