import { ReducerBuilder } from "../../utils/redux/reducer-builder";
import { GlobalTypes } from './../global-types';
import { INITIAL_STATE, SignInActionTypes, SignInState } from "./sign-in-types";

const reduxBuilder = new ReducerBuilder<SignInState>(INITIAL_STATE);

reduxBuilder.addReducer(GlobalTypes.CLEAR, () => {
    return INITIAL_STATE;
});

reduxBuilder.addMutableReducer(SignInActionTypes.SEND_MAGIC_LINK, (state) => {
    state.magicLinkRequest.loading = true;
    state.magicLinkRequest.error = null;
});

reduxBuilder.addMutableReducer(SignInActionTypes.SEND_MAGIC_LINK_SUCCESS, (state) => {
    state.magicLinkRequest.loading = false;
    state.magicLinkRequest.data = true;
});

reduxBuilder.addMutableReducer<any>(SignInActionTypes.SEND_MAGIC_LINK_ERROR, (state, payload) => {
    state.magicLinkRequest.loading = false;
    state.magicLinkRequest.data = false;
    state.magicLinkRequest.error = payload;
});

reduxBuilder.addMutableReducer(SignInActionTypes.AUTHENTICATE, (state) => {
    state.authenticatedRequest.loading = true;
    state.authenticatedRequest.error = null;
});

reduxBuilder.addMutableReducer(SignInActionTypes.AUTHENTICATE_SUCCESS, (state) => {
    state.authenticatedRequest.data = true;
    state.authenticatedRequest.loading = true;
});

reduxBuilder.addMutableReducer<any>(SignInActionTypes.AUTHENTICATE_ERROR, (state, payload) => {
    state.authenticatedRequest.data = null;
    state.authenticatedRequest.loading = true;
    state.authenticatedRequest.error = payload;
});

export default reduxBuilder.build();
