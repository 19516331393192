import styled, { DefaultTheme } from "styled-components";

interface ContainerProps {
    color?: keyof DefaultTheme['colors'];
    size?: number;
    fontSize?: number;
}

export const Container = styled.div<ContainerProps>`
    width: ${(props) => props.size ? `${props.size}px` : '4.75rem'};
    height: ${(props) => props.size ? `${props.size}px` : '4.75rem'};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: ${({ color = 'primary', theme }) => theme.colors[color].main};
    border-radius: 50%;
`;

export const Initials = styled.p<ContainerProps>`
    color: ${({ color = 'primary', theme }) => theme.colors[color].text};
    margin: 0;
    font-size: ${(props) => props.fontSize ? `${props.fontSize}rem` : '2rem'};
    text-transform: uppercase;
` 
