import React from "react";
import { useTranslation } from "react-i18next";
import { CandidateLanguage } from "../../../../models/candidate-language.model";
import { SectionBlock, Title } from "../../talent.styled";
import { Skill, SkillsList } from "../candidate-skills/candidate-skills.styled";

interface LanguageProps {
  languages?: CandidateLanguage[] | null;
}

const Language: React.FC<LanguageProps> = ({ languages }) => {
  const { t } = useTranslation("private-home-page");
  return  (
    <SectionBlock>
      <Title>{t("talent.language")}</Title>
      <SkillsList>
        {languages?.map((language, idx) => (
          <Skill key={`idx${idx * 2}`}>
            {language.name}: {language.proficiency?.name}
          </Skill>
        ))}
      </SkillsList>
    </SectionBlock>
  );
};

export default Language;
