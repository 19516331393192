import React, { useMemo } from "react";
import { Container, Info, Label, Total } from "./donut-chart.styled";

interface DounutChartProps {
    value: number;
    total: number;
    label?: string;
    className?: string;
    custonStyle?: {
        width?: string;
        height?: string;
        fontSize?: string;
    }
}

const CHART_FRACTIONS = 8;
const BASE_COLOR = '#e8e7ea';
const TOTAL_COLOR = '#452470';
const REFERENCE_COLORS = ['#452470', '#452470', '#452470', '#452470', '#452470', '#452470', '#452470', '#452470'];

const DonutsChart: React.FC<DounutChartProps> = ({ className, total, value, label, custonStyle }) => {

    const colors = useMemo(() => {
        const percent = Math.round(value * CHART_FRACTIONS / (total || 1));

        const actualColors = REFERENCE_COLORS.map((color, index) => {
            if (total === value) {
                return TOTAL_COLOR;
            } else if (index < percent) {
                return color;
            } else {
                return BASE_COLOR;
            }
        });

        return actualColors;
    }, [total, value]);

    return (
        <Container className={className} {...custonStyle}>
            <Info>
                <Total>{total}</Total>
                {label && <Label>{label}</Label>}
            </Info>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 176 174"
            >
                <path
                    fill={colors[0]}
                    d="M121.233 28.629a68.877 68.877 0 0114.001 10.665l13.687-13.687a89.293 89.293 0 00-18.013-13.72C117.205 3.98 102.28.15 87.553 0v19.383a68.864 68.864 0 0133.68 9.246z"
                />
                <path
                    fill={colors[1]}
                    d="M135.234 39.293c12.977 12.796 20.126 30.065 20.341 47.698h19.498c-.264-22.701-9.46-44.924-26.152-61.385l-13.687 13.687z"
                />
                <path
                    fill={colors[2]}
                    d="M146.678 121.119a66.327 66.327 0 01-10.897 14.1l13.819 13.819a85.845 85.845 0 0014.001-18.145c7.991-13.835 11.656-28.975 11.474-43.917h-19.482c.133 11.623-2.707 23.395-8.915 34.143z"
                />
                <path
                    fill={colors[3]}
                    d="M87.535 154.584v19.383c22.982.231 45.469-8.552 62.045-24.93l-13.819-13.819c-12.878 12.729-30.345 19.548-48.226 19.366z"
                />
                <path
                    fill={colors[4]}
                    d="M53.855 145.338a68.883 68.883 0 01-14-10.665L26.168 148.36a89.337 89.337 0 0018.013 13.72c13.703 7.908 28.628 11.738 43.355 11.887v-19.383c-11.441-.115-23.032-3.087-33.68-9.246z"
                />
                <path
                    fill={colors[5]}
                    d="M19.498 86.992H0c.264 22.701 9.46 44.924 26.152 61.384L39.84 134.69c-12.96-12.796-20.126-30.065-20.34-47.698z"
                />
                <path
                    fill={colors[6]}
                    d="M28.414 52.85a66.36 66.36 0 0110.896-14.1L25.491 24.93a85.821 85.821 0 00-14 18.145C3.5 56.911-.165 72.051.016 86.992h19.499a66.148 66.148 0 018.899-34.143z"
                />
                <path
                    fill={colors[7]}
                    d="M87.537 19.4V.015c-22.982-.231-45.469 8.552-62.045 24.93l13.82 13.82C52.188 26.02 69.672 19.2 87.536 19.399z"
                />
            </svg>
        </Container>
    );
}

export default DonutsChart;
