import React, { useEffect } from 'react';
import useDrawer from '../../../../../hooks/use-drawer';
import { Backdrop, CloseButton, Container, Content, HeaderDrawer } from './drawer.styled';


export interface DrawerProps {
    title: string;
    width?: number;
    top?: number;
}

export interface DrawerParams {
    drawer?: string;
};

const Drawer: React.FC<DrawerProps> = ({ title, children, ...props }) => {
    const { isOpen, handleCloseDrawer } = useDrawer(title);

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    }, [isOpen]);


    return (
        <>
            <Backdrop onClick={() => handleCloseDrawer()} isShown={isOpen} />
            <Container
                width={1156}
                title="Drawer"
                isShown={isOpen}
            >
                <HeaderDrawer>
                    <CloseButton onClick={() => handleCloseDrawer()}>
                        <img src="/assets/img/close-filter.svg" alt="Close" />
                    </CloseButton>
                </HeaderDrawer>
                <Content>
                    {children}
                </Content>
            </Container>
        </>
    );
}

export default Drawer;