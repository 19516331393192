import React, { useCallback } from "react";
import { BackdropContainer } from "./backdrop.styled";

interface BackdropProps {
  isShown: boolean;
  toggle: (e?: React.MouseEvent<any>) => void;
  closeMouseEnter?: boolean;
  transparent?: boolean;
  zIndex?: number;
}

const Backdrop: React.FC<BackdropProps> = ({
  isShown,
  toggle,
  closeMouseEnter,
  transparent,
  zIndex,
}) => {
  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (closeMouseEnter) {
        toggle(e);
      }
    },
    [toggle, closeMouseEnter]
  );
  return (
    <BackdropContainer
      onClick={toggle}
      isShown={isShown}
      onMouseMove={handleMouseEnter}
      transparent={transparent}
      zIndex={zIndex}
    />
  );
};

export default Backdrop;
