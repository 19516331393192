import styled from "styled-components";

export const ButtonContainer = styled.div`
    margin: 0;
    margin-left: 1rem;
    margin-right: 0.5rem;
    padding 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    height: 1.5rem;
    cursor: pointer;

    .bar::after {
        position: absolute;
        content: '';
        transform: translateY(0.5rem);
    }
    
    .bar::before {
        position: absolute;
        content: '';
        transform: translateY(-0.5rem);
    }

    .cross {
        transform: rotate(50deg);
    }
    
    .cross::after, .cross::before {
        position: absolute;
        content: '';
        transform: rotate(85deg);
    }
`;

export const Button = styled.span`
    &, &::after, &::before {
        transform-origin: center;
        transition: 0.3s;
        width: 1.5rem;
        height: 2px;
        background-color: #313639;
        cursor: pointer;
        z-index: 200;
    }
`;

export const Menu = styled.ul<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0.5rem;
  top: 3.25rem;
  z-index: 200;
  list-style: none;
  overflow: hidden;
  padding: 0.5rem;
  margin: 0;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #eee;
  transition: 0.3s;
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  opacity: ${({ open }) => open ? '1' : '0'};
`;

export const MenuItem = styled.li`
  color: #313639 !important;
  width: 100%;
`;

export const MenuItemClick = styled.button`
  display: inline-block;
  min-width: 120px;
  padding: 0.5rem;
  color: #313639 !important;
  text-align: left;
  outline: none;
  border: none;
  background-color: transparent;

  &:hover {
    background-color:  #eee;
    text-decoration: none;
    border-radius: 5px;
  }
`;

export const MenuDiv = styled.hr`
  border-top: 1px solid #D9D9D9;
  width: 100%;
  height: 1px;
  margin: 2px 0px !important;
`;

export const MenuSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  color: #858b8d;
`;