import { setLightness } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 2rem;
`;

export const Title = styled.h3`
    width: 100%;
    padding-bottom: 1rem;
    color: ${({ theme }) => theme.colors.primary.main};
    border-bottom: 1px solid ${({ theme }) => setLightness(.8, theme.typography.regular.color)};
    text-transform: uppercase;
    margin: 1rem 0 0 0;
`;
