
import { shade } from 'polished';
import styled, { css } from 'styled-components';
import { PlanType } from '../../../../../../../models/plan-type.enum';

interface PlanCardProps {
    type?: PlanType;
}

export const Container = styled.div<PlanCardProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
    width: 310px;
    height: 180px;
    padding: 2rem;
    border-radius: 1.5rem;
    background: linear-gradient(102.17deg, #BF3FF7 3.91%, #84E6F5 94.86%);

    ${({ type }) => type === PlanType.HIRING_PLUS && css`
        background: linear-gradient(180deg, #491094 0%, #493ab4 100%), #fff;
    `};

    &:before {
        content: '';
        width: 400px;
        height: 200px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: black;
        transform: translate(-50%, 50%);
        border-radius: 50%;
        background-color: ${({ theme }) => shade(0.1, theme.colors.primary.main)};
        opacity: .25;
    }

    &:after {
        content: '';
        width: 400px;
        height: 200px;
        position: absolute;
        top: 0;
        right: 0;
        background-color: black;
        transform: translate(50%, -60%);
        border-radius: 50%;
        background-color: ${({ theme }) => shade(0.1, theme.colors.primary.main)};
        opacity: .25;
    }
`;

const Text = styled.p`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0;
    line-height: 1rem;
    text-transform: uppercase;
    color: #fff;
    z-index: 1;
`;

export const Label = styled.span`
    font-size: .8rem;
    font-weight: 400;
`;

export const PlanTypeTitle = styled(Text)`
    font-size: .8rem;
`;

export const CompanyName = styled(Text)`
    margin-top: .5rem;
`;

export const Contract = styled(Text)`
    font-weight: bold;
`;
