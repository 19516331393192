import styled, { keyframes } from "styled-components";

export const circle_anim = keyframes`
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1.1);
    }
`;

export const success_anim = keyframes`
    0% {
        transform: scale(0);
    }
    30% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
`;


export const Container = styled.div`
    display: inline-block;
    position: relative;
    width: 4.1rem;
    height: 4.1rem;
`;

export const Border = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    z-index: 0;
    position: absolute;
    transform: scale(1.1);
    animation: ${circle_anim} 400ms ease;
    background: #452470;	
    margin: 0;
`;

export const Circle = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    z-index: 1;
    position: relative;
    background: white;
    transform: scale(1);
    animation: ${success_anim} 700ms ease;
    margin: 0;
`;

export const Cross = styled.div`
    &::before, &::after {
        content: "";
        display: block;
        height: 4px;
        background: #452470;
        position: absolute;
    }

    &::before {
        width: 40px;
        top: 48%;
        left: 19%;
        transform: rotateZ(50deg);
    }

    &::after {
        width: 40px;
        top: 48%;
        left: 19%;
        transform: rotateZ(-50deg);
    }
`;
