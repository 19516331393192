import {
  ENGLISH_TYPE,
  HIRE_TYPE,
  SelectLabelValue,
  WORK_TYPE,
} from "./context";

export const DEFAULT_ECOSYSTEM = {
  id: "ad63a367-a5e5-47b6-a9bf-0a6dfb5fe1ff",
  name: "Java",
};

export const experienceTimeOptions = [
  { value: "0", label: "Sem experiência" },
  { value: "1", label: "Menos de 1 ano" },
  { value: "2", label: "1 a 3 anos" },
  { value: "3", label: "3 a 6 anos" },
  { value: "4", label: "+ de 6 anos" },
];

export const englishTypeOptions: SelectLabelValue[] = [
  {
    value: ENGLISH_TYPE.BEGINNER,
    label: "private-home-page:filterForm.beginnerEnglish",
  },
  {
    value: ENGLISH_TYPE.INTERMEDIATE,
    label: "private-home-page:filterForm.intermediateEnglish",
  },
  {
    value: ENGLISH_TYPE.ADVANCED,
    label: "private-home-page:filterForm.advancedEnglish",
  },
  {
    value: ENGLISH_TYPE.FLUENT,
    label: "private-home-page:filterForm.fluentEnglish",
  },
];

export const workTypeOptions = [
  { value: WORK_TYPE.REMOTE, label: "Remoto" },
  { value: WORK_TYPE.PRESENTIAL, label: "Presencial" },
  { value: WORK_TYPE.HYBRID, label: "Híbrido" },
];

export const hireTypesOptions: SelectLabelValue[] = [
  { value: HIRE_TYPE.CLT, label: "private-home-page:filterForm.hireTypesClt" },
  { value: HIRE_TYPE.PJ, label: "private-home-page:filterForm.hireTypesPj" },
];
