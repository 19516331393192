import { useContext, useCallback } from 'react';
import { DialogContext } from '../contexts/dialog';

const useDialog = () => {
    const { openDialog, resetDialog } = useContext(DialogContext);

    const createDialog = useCallback(async ({ ...options }) => {

        return new Promise((res) => {
            resetDialog();
            openDialog({ actionCallback: res, ...options });
        });
        // FIXME: Ajustar para não precisar injetar
        // eslint-disable-next-line
    }, []);

    return { createDialog };
};

export default useDialog;