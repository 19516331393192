
import { Duration, intervalToDuration } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Indicator, Label, Separator, Value } from "./event-countdown.styled";

interface EventCountdownProps {
    end?: Date;
    loading?: boolean;
}

const EventCountdown: React.FC<EventCountdownProps> = ({ end, loading }) => {
    const { t } = useTranslation();
    const [timeLeft, setTimeLeft] = useState<Duration>(intervalToDuration({ start: new Date(), end: end || new Date() }));

    useEffect(() => {
        if (end) {
            setTimeLeft(intervalToDuration({ start: new Date(), end }));

            const timer = setInterval(() => {
                setTimeLeft(intervalToDuration({ start: new Date(), end }));
            }, 1000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [end]);

    return (
        <Container>
            <Indicator>
                <Value>{loading || !end ? '-' : timeLeft.days?.toString().padStart(2, '0')}</Value>
                <Label> {timeLeft.days === 1 ? t('day') : t('days')} </Label>
            </Indicator>
            <Separator>:</Separator>
            <Indicator>
                <Value>{loading || !end ? '-' : timeLeft.hours?.toString().padStart(2, '0')}</Value>
                <Label> {timeLeft.hours === 1 ? t('hour') : t('hours')} </Label>
            </Indicator>
            <Separator>:</Separator>
            <Indicator>
                <Value>{loading || !end ? '-' : timeLeft.minutes?.toString().padStart(2, '0')}</Value>
                <Label> {t('minute').slice(0, 3)}</Label>
            </Indicator>
            <Separator>:</Separator>
            <Indicator>
                <Value>{loading || !end ? '-' : timeLeft.seconds?.toString().padStart(2, '0')}</Value>
                <Label> {t('second').slice(0, 3)}</Label>
            </Indicator>
        </Container>
    );
}

export default EventCountdown;
