import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, RouteProps, useParams } from 'react-router-dom';
import { useQuery } from '../hooks';
import HomeDashboardPageQuery from '../pages/private/home-dashboard/home-dashboard-page-query.model';
import { authenticate } from '../store/sign-in/sign-in-actions';

export interface PublicRouteProps extends RouteProps { }
interface HomePageParams {
    event: string
}
const PublicRoute: React.FC<PublicRouteProps> = ({ ...props }) => {
    const [query] = useQuery<HomeDashboardPageQuery>();
    const dispatch = useDispatch();
    const { event } = useParams<HomePageParams>();

    useEffect(() => {
        if (query.token) {
            dispatch(authenticate({ tokenKey: query.token, event: event ? event : 'talent-match' }));
        }
    }, [query, event, dispatch]);

    return (
        <Route {...props} />
    );
};

export default PublicRoute;
