import { AxiosResponse } from 'axios';
import { OriginFilter } from '../../models/origin-filter.model';
import Track from '../../models/track.model';
import { Candidate } from './../../models/candidate.model';
import { SelectedCandidate } from './../../models/selected-candidate.model';
import { CandidateQuery } from './models/candidates-query.model';
import PaginatedResponse from './models/paginated-response.model';
import talentApi from './talent-api-config';

class EventApi {

    async fetchEventData(eventSlug: string): Promise<AxiosResponse<Event>> {
        return talentApi.get<Event>(`/talent/${eventSlug}/`);
    }

    async fetchSelectedCandidates(eventSlug: string, query?: CandidateQuery): Promise<AxiosResponse<PaginatedResponse<SelectedCandidate>>> {
        const page = query?.selectedCandidatesPage ? parseInt(query?.selectedCandidatesPage) : 1;
        const params = { ...query, page_size: 10, page };
        
        if (params.status === 'my_candidates') {
            delete params.status;
            params.my = 'true';
        }

        const response = await talentApi.get<PaginatedResponse<SelectedCandidate>>(`/talent/${eventSlug}/selecteds/`, { params });
        response.data.current_page = page;
        response.data.page_size = params.page_size;

        if (response.data?.results) {
            for (let i = 0; i < response.data?.results.length; i++) {
                const selectedCandidate = response.data?.results[i];
                if (!selectedCandidate.source) {
                    selectedCandidate.candidate.is_pool = true;
                }
            }
        }

        return response;
    }

    async fetchOriginFilter(eventSlug: string): Promise<AxiosResponse<OriginFilter[]>> {
        return talentApi.get<OriginFilter[]>(`/talent/${eventSlug}/origin-filter/`);
    }

    async fetchEventTracks(eventSlug: string): Promise<AxiosResponse<Track[]>> {
        return talentApi.get<Track[]>(`/talent/${eventSlug}/tracks/`);
    }

    async fetchEventTrack(trackUuid: string): Promise<AxiosResponse<Track>> {
        return talentApi.get<Track>(`/talent/track/${trackUuid}/`);
    }

    async fetchTrackCandidates(trackUuid: string, query?: CandidateQuery): Promise<AxiosResponse<PaginatedResponse<Candidate>>> {
        const page = query?.trackingPage ? parseInt(query?.trackingPage) : 1;
        const page_size = query?.pageSize || 10;
        const params = { ...query, page_size: page_size, pageSize: undefined, page };
        const response = await talentApi.get<PaginatedResponse<Candidate>>(`/talent/track/${trackUuid}/candidates/`, { params });
        response.data.current_page = page;
        response.data.page_size = page_size;
        return response;
    }

    async fetchTrackSkills(trackUuid: string): Promise<AxiosResponse<string[]>> {
        return talentApi.get<string[]>(`/talent/track/${trackUuid}/skills/`);
    }

}

export const eventApi = new EventApi();
