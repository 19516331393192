import React from 'react';
import { useTranslation } from 'react-i18next';
import { CandidateDetail } from '../../../../models/candidate-detail.model';
import { Item, Linked, LinkExt, List, SectionBlock } from '../../talent.styled';
import { BulletTitle, TitleAbout } from '../about/about.styled';
import { Skill, SkillsList } from '../candidate-skills/candidate-skills.styled';

interface CertificatesProps {
    candidate: CandidateDetail;
    isLocked?: boolean | true;
}

const Certificates: React.FC<CertificatesProps> = ({ candidate, isLocked }) => {
    const { t } = useTranslation('private-home-page');

    return (
        <SectionBlock>
            <TitleAbout>{t('talent.dioCertificates')}</TitleAbout>
            <List>
                {candidate.achievements.map((achievement, index) => (
                    <Item key={`achievement-${index}`}>
                        <BulletTitle>
                            <Linked target="_blank" href={achievement.certificate} disabled={isLocked}>
                                {achievement.name} <LinkExt src={isLocked ? "/assets/img/borderless-lock-icon.svg" : "/assets/img/link-ext.svg"} />
                            </Linked>
                        </BulletTitle>
                        <SkillsList>
                            {candidate?.main_skills?.map(skill =>
                                <Skill key={skill} >{skill}</Skill>
                            )}
                        </SkillsList>
                    </Item>
                ))}
            </List>
        </SectionBlock>
    );
};

export default Certificates;