import PaginatedResponse from '../../api/talent/models/paginated-response.model';
import { CandidateDetail } from '../../models/candidate-detail.model';
import Diversity from '../../models/diversity.model';
import Ecosystem from '../../models/ecosystem.model';
import { Candidate } from './../../models/candidate.model';
import { DeclineCandidateReason } from './../../models/reason.model';
import { StorageData } from './../storage-data';
import { JobOfferPayload } from '../../models/candidate-action.model';

export interface CandidateState {
    candidateDetailData: StorageData<CandidateDetail | null, any>;
    declineCandidateReasonsData: StorageData<DeclineCandidateReason[], any>;
    unlockCandidateData: StorageData<{
        needPermission: boolean;
        confirmNotify: boolean;
        needLeed: boolean;
        needEcosystem: boolean;
    }, any>;
    openCandidateDetailData: StorageData<boolean | null, any>;
    declineCandidateData: StorageData<boolean | null, any>;
    candidateNotInterviewedData: StorageData<boolean | null, any>;
    candidateHiredData: StorageData<boolean | null, any>;
    candidateDiversitiesData: StorageData<Diversity[] | null, any>;
    candidateEcosystemsData: StorageData<Ecosystem[] | null, any>;
    candidatePoolData: StorageData<PaginatedResponse<Candidate> | null, any | null>;
    currentCandidatePoolSkillsData: StorageData<string[], any | null>;
    jobOffer?: JobOfferPayload;
};

export const INITIAL_STATE: CandidateState = {
    candidateDetailData: {
        data: null,
        loading: false,
        error: null
    },
    declineCandidateReasonsData: {
        data: [],
        loading: false,
        error: null
    },
    unlockCandidateData: {
        data: {
            needPermission: false,
            confirmNotify: false,
            needLeed: false,
            needEcosystem: false,
        },
        loading: false,
        error: null
    },
    openCandidateDetailData: {
        data: null,
        loading: false,
        error: null
    },
    declineCandidateData: {
        data: null,
        loading: false,
        error: null
    },
    candidateNotInterviewedData: {
        data: null,
        loading: false,
        error: null
    },
    candidateHiredData: {
        data: null,
        loading: false,
        error: null
    },
    candidateDiversitiesData: {
        data: null,
        loading: false,
        error: null
    },
    candidateEcosystemsData: {
        data: null,
        loading: false,
        error: null
    },
    candidatePoolData: {
        data: null,
        loading: false,
        error: null
    },
    currentCandidatePoolSkillsData: {
        data: [],
        loading: false,
        error: null
    },
}

export enum CandidateActionTypes {
    FETCH_CANDIDATE = '@candidate/FETCH_CANDIDATE',
    FETCH_CANDIDATE_SUCCESS = '@candidate/FETCH_CANDIDATE_SUCCESS',
    FETCH_CANDIDATE_ERROR = '@candidate/FETCH_CANDIDATE_ERROR',

    CLEAR_CANDIDATE_DETAIL = '@candidate/CLEAR_CANDIDATE_DETAIL',

    NOTIFY_OPEN_CANDIDATE_DETAIL = '@candidate/NOTIFY_OPEN_CANDIDATE_DETAIL',
    NOTIFY_OPEN_CANDIDATE_DETAIL_SUCCESS = '@candidate/NOTIFY_OPEN_CANDIDATE_DETAIL_SUCCESS',
    NOTIFY_OPEN_CANDIDATE_DETAIL_ERROR = '@candidate/NOTIFY_OPEN_CANDIDATE_DETAIL_ERROR',

    JOB_OFFER = '@candidate/JOB_OFFER',
    JOB_OFFER_CREATE = '@candidate/JOB_OFFER_CREATE',
    JOB_OFFER_CREATE_SUCCESS = '@candidate/JOB_OFFER_CREATE',
    JOB_OFFER_CREATE_ERROR = '@candidate/JOB_OFFER_CREATE_ERROR',

    UNLOCK_CANDIDATE = '@candidate/UNLOCK_CANDIDATE',
    UNLOCK_CANDIDATE_NEED_PERMISSION = '@candidate/UNLOCK_CANDIDATE_NEED_PERMISSION',
    UNLOCK_CANDIDATE_CONFIRM_NOTIFY = '@candidate/UNLOCK_CANDIDATE_CONFIRM_NOTIFY',
    UNLOCK_CANDIDATE_CONFIRM_PERMISSION_TO_UNLOCK = '@candidate/UNLOCK_CANDIDATE_CONFIRM_PERMISSION_TO_UNLOCK',
    UNLOCK_CANDIDATE_NEED_LEED = '@candidate/UNLOCK_CANDIDATE_NEED_LEED',
    UNLOCK_CANDIDATE_CONFIRM_LEED_REQUEST = '@candidate/UNLOCK_CANDIDATE_CONFIRM_LEED_REQUEST',
    UNLOCK_CANDIDATE_NEED_ECOSYSTEM = '@candidate/UNLOCK_CANDIDATE_NEED_ECOSYSTEM',
    UNLOCK_CANDIDATE_CONFIRM_ECOSYSTEM_REQUEST = '@candidate/UNLOCK_CANDIDATE_CONFIRM_ECOSYSTEM_REQUEST',
    UNLOCK_CANDIDATE_SUCCESS = '@candidate/UNLOCK_CANDIDATE_SUCCESS',
    UNLOCK_CANDIDATE_ERROR = '@candidate/UNLOCK_CANDIDATE_ERROR',

    APPROVED = '@candidate/APPROVED',
    APPROVED_SUCCESS = '@candidate/APPROVED_SUCCESS',
    APPROVED_ERROR = '@candidate/APPROVED_ERROR',

    DECLINE_CANDIDATE = '@candidate/DECLINE_CANDIDATE',
    DECLINE_CANDIDATE_SUCCESS = '@candidate/DECLINE_CANDIDATE_SUCCESS',
    DECLINE_CANDIDATE_ERROR = '@candidate/DECLINE_CANDIDATE_ERROR',

    CANDIDATE_NOT_INTERVIEWED = '@candidate/CANDIDATE_NOT_INTERVIEWED',
    CANDIDATE_NOT_INTERVIEWED_SUCCESS = '@candidate/CANDIDATE_NOT_INTERVIEWED_SUCCESS',
    CANDIDATE_NOT_INTERVIEWED_ERROR = '@candidate/CANDIDATE_NOT_INTERVIEWED_ERROR',

    CANDIDATE_HIRED = '@candidate/CANDIDATE_HIRED',
    CANDIDATE_HIRED_SUCCESS = '@candidate/CANDIDATE_HIRED_SUCCESS',
    CANDIDATE_HIRED_ERROR = '@candidate/CANDIDATE_HIRED_ERROR',

    DENY_JOB_OFFER = '@candidate/DENY_JOB_OFFER',
    DENY_JOB_OFFER_SUCCESS = '@candidate/DENY_JOB_OFFER_SUCCESS',
    DENY_JOB_OFFER_ERROR = '@candidate/DENY_JOB_OFFER_ERROR',

    FETCH_DECLINE_CANDIDATE_REASONS = '@candidate/FETCH_DECLINE_CANDIDATE_REASONS',
    FETCH_DECLINE_CANDIDATE_REASONS_SUCCESS = '@candidate/FETCH_DECLINE_CANDIDATE_REASONS_SUCCESS',
    FETCH_DECLINE_CANDIDATE_REASONS_ERROR = '@candidate/FETCH_DECLINE_CANDIDATE_REASONS_ERROR',

    FETCH_CANDIDATE_DIVERSITIES = '@candidate/FETCH_CANDIDATE_DIVERSITIES',
    FETCH_CANDIDATE_DIVERSITIES_SUCCESS = '@candidate/FETCH_CANDIDATE_DIVERSITIES_SUCCESS',
    FETCH_CANDIDATE_DIVERSITIES_ERROR = '@candidate/FETCH_CANDIDATE_DIVERSITIES_ERROR',

    FETCH_CANDIDATE_ECOSYSTEMS = '@candidate/FETCH_CANDIDATE_ECOSYSTENS',
    FETCH_CANDIDATE_ECOSYSTEMS_SUCCESS = '@candidate/FETCH_CANDIDATE_ECOSYSTENS_SUCCESS',
    FETCH_CANDIDATE_ECOSYSTEMS_ERROR = '@candidate/FETCH_CANDIDATE_ECOSYSTENS_ERROR',

    FETCH_CANDIDATE_POOL = '@candidate/FETCH_CANDIDATE_POOL',
    FETCH_CANDIDATE_POOL_SUCCESS = '@candidate/FETCH_CANDIDATE_POOL_SUCCESS',
    FETCH_CANDIDATE_POOL_ERROR = '@candidate/FETCH_CANDIDATE_POOL_ERROR',

    FETCH_CANDIDATE_POOL_SKILLS = '@candidate/FETCH_CANDIDATE_POOL_SKILLS',
    FETCH_CANDIDATE_POOL_SUCCESS_SKILLS = '@candidate/FETCH_CANDIDATE_POOL_SUCCESS_SKILLS',
    FETCH_CANDIDATE_POOL_SKILLS_ERROR = '@candidate/FETCH_CANDIDATE_POOL_SKILLS_ERROR',
}
