import React, { useMemo } from 'react';
import { DefaultTheme } from 'styled-components';
import { Container } from './tab.styled';

const TITLE_MAX_LENGHT = 20;

interface TabProps {
    title: string;
    isActive?: boolean;
    color?: keyof DefaultTheme['colors'] | 'white';
    onClick?: () => void;
}

const Tab: React.FC<TabProps> = ({ title, isActive, color = 'primary', onClick }) => {
    const croppedTitle: string = useMemo(() => {
        return title.length > TITLE_MAX_LENGHT
            ? `${title.slice(0, TITLE_MAX_LENGHT-1)}...`
            : title;
    }, [title]);
    return (
        <Container isActive={isActive} color={color} onClick={onClick}>
            {croppedTitle}
        </Container>
    );
}

export default Tab;
