import LoadingSpinner from "../../../../../components/loading-spinner";
import { Container, Message } from "./index.styled";

const Loading: React.FC<{ message?: string }> = ({ message }) => {
    return (
        <Container>
            <LoadingSpinner />
            <Message>{message}</Message>
        </Container>
    );
}

export default Loading;