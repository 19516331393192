import PaginatedResponse from "../../api/talent/models/paginated-response.model";
import { JobOfferPayload } from "../../models/candidate-action.model";
import Diversity from "../../models/diversity.model";
import { ReducerBuilder } from "../../utils/redux/reducer-builder";
import { CandidateDetail } from "./../../models/candidate-detail.model";
import { Candidate } from "./../../models/candidate.model";
import { DeclineCandidateReason } from "./../../models/reason.model";
import { GlobalTypes } from "./../global-types";
import {
  CandidateActionTypes,
  CandidateState,
  INITIAL_STATE
} from "./candidate-types";

const reduxBuilder = new ReducerBuilder<CandidateState>(INITIAL_STATE);

reduxBuilder.addReducer(GlobalTypes.CLEAR, () => {
  return INITIAL_STATE;
});

reduxBuilder.addMutableReducer(
  CandidateActionTypes.CLEAR_CANDIDATE_DETAIL,
  (state) => {
    state.candidateDetailData = INITIAL_STATE.candidateDetailData;
    state.unlockCandidateData = INITIAL_STATE.unlockCandidateData;
    return INITIAL_STATE;
  }
);

reduxBuilder.addMutableReducer(
  CandidateActionTypes.FETCH_CANDIDATE,
  (state) => {
    state.candidateDetailData.loading = true;
    state.candidateDetailData.error = null;
  }
);

reduxBuilder.addMutableReducer<CandidateDetail>(
  CandidateActionTypes.FETCH_CANDIDATE_SUCCESS,
  (state, payload) => {
    state.candidateDetailData.loading = false;
    state.candidateDetailData.data = payload;
  }
);

reduxBuilder.addMutableReducer<any>(
  CandidateActionTypes.FETCH_CANDIDATE_ERROR,
  (state, error) => {
    state.candidateDetailData.loading = false;
    state.candidateDetailData.error = error;
  }
);

reduxBuilder.addMutableReducer(
  CandidateActionTypes.UNLOCK_CANDIDATE,
  (state) => {
    state.unlockCandidateData.loading = true;
    state.unlockCandidateData.error = null;
  }
);

reduxBuilder.addMutableReducer<JobOfferPayload>(
  CandidateActionTypes.JOB_OFFER,
  (state, payload) => {
    state.jobOffer = payload;
  }
);

reduxBuilder.addMutableReducer<JobOfferPayload>(
  CandidateActionTypes.JOB_OFFER_CREATE,
  (state, payload) => {
    delete state.jobOffer;
  }
);

reduxBuilder.addMutableReducer<boolean>(
  CandidateActionTypes.UNLOCK_CANDIDATE_NEED_PERMISSION,
  (state, needPermission) => {
    state.unlockCandidateData.loading = false;
    state.unlockCandidateData.error = null;
    if (state.unlockCandidateData.data) {
      state.unlockCandidateData.data.needPermission = needPermission;
    }
  }
);

reduxBuilder.addMutableReducer<boolean>(
  CandidateActionTypes.UNLOCK_CANDIDATE_NEED_LEED,
  (state, needLeed) => {
    state.unlockCandidateData.loading = false;
    state.unlockCandidateData.error = null;
    if (state.unlockCandidateData.data) {
      state.unlockCandidateData.data.needLeed = needLeed;
    }
  }
);

reduxBuilder.addMutableReducer<boolean>(
  CandidateActionTypes.UNLOCK_CANDIDATE_NEED_ECOSYSTEM,
  (state, needEcosystem) => {
    state.unlockCandidateData.loading = false;
    state.unlockCandidateData.error = null;
    if (state.unlockCandidateData.data) {
      state.unlockCandidateData.data.needEcosystem = needEcosystem;
    }
  }
);

reduxBuilder.addMutableReducer<boolean>(
  CandidateActionTypes.UNLOCK_CANDIDATE_CONFIRM_NOTIFY,
  (state, confirmNotify) => {
    state.unlockCandidateData.loading = false;
    state.unlockCandidateData.error = null;
    if (state.unlockCandidateData.data) {
      state.unlockCandidateData.data.confirmNotify = confirmNotify;
    }
  }
);

reduxBuilder.addMutableReducer<boolean>(
  CandidateActionTypes.UNLOCK_CANDIDATE_CONFIRM_PERMISSION_TO_UNLOCK,
  (state) => {
    state.unlockCandidateData.loading = false;
    state.unlockCandidateData.error = null;
    state.unlockCandidateData.data = INITIAL_STATE.unlockCandidateData.data;
  }
);

reduxBuilder.addMutableReducer<boolean>(
  CandidateActionTypes.UNLOCK_CANDIDATE_CONFIRM_LEED_REQUEST,
  (state) => {
    state.unlockCandidateData.loading = false;
    state.unlockCandidateData.error = null;
    state.unlockCandidateData.data = INITIAL_STATE.unlockCandidateData.data;
  }
);

reduxBuilder.addMutableReducer<boolean>(
  CandidateActionTypes.UNLOCK_CANDIDATE_CONFIRM_ECOSYSTEM_REQUEST,
  (state) => {
    state.unlockCandidateData.loading = false;
    state.unlockCandidateData.error = null;
    state.unlockCandidateData.data = INITIAL_STATE.unlockCandidateData.data;
  }
);

reduxBuilder.addMutableReducer<CandidateDetail>(
  CandidateActionTypes.UNLOCK_CANDIDATE_SUCCESS,
  (state, payload) => {
    state.unlockCandidateData.loading = false;
    state.unlockCandidateData.data = INITIAL_STATE.unlockCandidateData.data;
    state.candidateDetailData.data = payload;
  }
);

reduxBuilder.addMutableReducer<any>(
  CandidateActionTypes.UNLOCK_CANDIDATE_ERROR,
  (state, error) => {
    state.unlockCandidateData.loading = false;
    state.unlockCandidateData.error = error;
  }
);

reduxBuilder.addMutableReducer(
  CandidateActionTypes.NOTIFY_OPEN_CANDIDATE_DETAIL,
  (state) => {
    state.openCandidateDetailData.loading = true;
    state.openCandidateDetailData.error = null;
  }
);

reduxBuilder.addMutableReducer<CandidateDetail>(
  CandidateActionTypes.NOTIFY_OPEN_CANDIDATE_DETAIL_SUCCESS,
  (state, payload) => {
    state.openCandidateDetailData.loading = false;
    state.openCandidateDetailData.data = true;
    state.candidateDetailData.data = payload;
  }
);

reduxBuilder.addMutableReducer<any>(
  CandidateActionTypes.NOTIFY_OPEN_CANDIDATE_DETAIL_ERROR,
  (state, error) => {
    state.openCandidateDetailData.loading = false;
    state.openCandidateDetailData.error = error;
  }
);

reduxBuilder.addMutableReducer(
  CandidateActionTypes.DECLINE_CANDIDATE,
  (state) => {
    state.declineCandidateData.loading = true;
    state.declineCandidateData.error = null;
  }
);

reduxBuilder.addMutableReducer<CandidateDetail>(
  CandidateActionTypes.DECLINE_CANDIDATE_SUCCESS,
  (state, payload) => {
    state.declineCandidateData.loading = false;
    state.declineCandidateData.data = true;
    state.candidateDetailData.data = payload;
  }
);

reduxBuilder.addMutableReducer<any>(
  CandidateActionTypes.DECLINE_CANDIDATE_ERROR,
  (state, error) => {
    state.declineCandidateData.loading = false;
    state.declineCandidateData.error = error;
  }
);

reduxBuilder.addMutableReducer(
  CandidateActionTypes.CANDIDATE_NOT_INTERVIEWED,
  (state) => {
    state.candidateNotInterviewedData.loading = true;
    state.candidateNotInterviewedData.error = null;
  }
);

reduxBuilder.addMutableReducer<CandidateDetail>(
  CandidateActionTypes.CANDIDATE_NOT_INTERVIEWED_SUCCESS,
  (state, payload) => {
    state.candidateNotInterviewedData.loading = false;
    state.candidateNotInterviewedData.data = true;
    state.candidateDetailData.data = payload;
  }
);

reduxBuilder.addMutableReducer<any>(
  CandidateActionTypes.CANDIDATE_NOT_INTERVIEWED_ERROR,
  (state, error) => {
    state.candidateNotInterviewedData.loading = false;
    state.candidateNotInterviewedData.error = error;
  }
);

reduxBuilder.addMutableReducer(
  CandidateActionTypes.CANDIDATE_HIRED,
  (state) => {
    state.candidateHiredData.loading = true;
    state.candidateHiredData.error = null;
  }
);

reduxBuilder.addMutableReducer<CandidateDetail>(
  CandidateActionTypes.CANDIDATE_HIRED_SUCCESS,
  (state, payload) => {
    state.candidateHiredData.loading = false;
    state.candidateHiredData.data = true;
    state.candidateDetailData.data = payload;
  }
);

reduxBuilder.addMutableReducer<any>(
  CandidateActionTypes.CANDIDATE_HIRED_ERROR,
  (state, error) => {
    state.candidateHiredData.loading = false;
    state.candidateHiredData.error = error;
  }
);

reduxBuilder.addMutableReducer(
  CandidateActionTypes.FETCH_DECLINE_CANDIDATE_REASONS,
  (state) => {
    state.declineCandidateReasonsData.loading = true;
    state.declineCandidateReasonsData.error = null;
  }
);

reduxBuilder.addMutableReducer<DeclineCandidateReason[]>(
  CandidateActionTypes.FETCH_DECLINE_CANDIDATE_REASONS_SUCCESS,
  (state, payload) => {
    state.declineCandidateReasonsData.loading = false;
    state.declineCandidateReasonsData.data = payload;
  }
);

reduxBuilder.addMutableReducer<any>(
  CandidateActionTypes.FETCH_DECLINE_CANDIDATE_REASONS_ERROR,
  (state, error) => {
    state.declineCandidateReasonsData.loading = false;
    state.declineCandidateReasonsData.error = error;
  }
);

reduxBuilder.addMutableReducer(
  CandidateActionTypes.FETCH_CANDIDATE_DIVERSITIES,
  (state) => {
    state.candidateDiversitiesData.loading = true;
    state.candidateDiversitiesData.error = null;
  }
);

reduxBuilder.addMutableReducer<Diversity[]>(
  CandidateActionTypes.FETCH_CANDIDATE_DIVERSITIES_SUCCESS,
  (state, payload) => {
    const diversitiesLabelMap = new Map<string, string>();
    // diversitiesLabelMap.set(
    //   "043a5ba2-1b37-4c75-9e47-9501da4a7709",
    //   "+ de 50 anos"
    // );
    // diversitiesLabelMap.set("82aeb278-e901-4cca-b773-7c3a6d374e9c", "PcD");

    const ignoredDiversity = "e22a1fe4-7490-4999-a58d-d6547c21464c";

    const diversities = payload.filter(
      (diversity) => diversity.id !== ignoredDiversity
    );

    for (const diversity of diversities) {
      const newLabel = diversitiesLabelMap.get(diversity.id);
      if (!!newLabel) {
        diversity.name = newLabel;
      }
    }

    state.candidateDiversitiesData.loading = false;
    state.candidateDiversitiesData.data = diversities;
  }
);

reduxBuilder.addMutableReducer<any>(
  CandidateActionTypes.FETCH_CANDIDATE_DIVERSITIES_ERROR,
  (state, error) => {
    state.candidateDiversitiesData.loading = false;
    state.candidateDiversitiesData.error = error;
  }
);

reduxBuilder.addMutableReducer(
  CandidateActionTypes.FETCH_CANDIDATE_ECOSYSTEMS,
  (state) => {
    state.candidateEcosystemsData.loading = true;
    state.candidateEcosystemsData.error = null;
  }
);

reduxBuilder.addMutableReducer<Diversity[]>(
  CandidateActionTypes.FETCH_CANDIDATE_ECOSYSTEMS_SUCCESS,
  (state, payload) => {
    state.candidateEcosystemsData.loading = false;
    state.candidateEcosystemsData.data = payload;
  }
);

reduxBuilder.addMutableReducer<any>(
  CandidateActionTypes.FETCH_CANDIDATE_ECOSYSTEMS_ERROR,
  (state, error) => {
    state.candidateEcosystemsData.loading = false;
    state.candidateEcosystemsData.error = error;
  }
);

reduxBuilder.addMutableReducer(
  CandidateActionTypes.FETCH_CANDIDATE_POOL,
  (state) => {
    state.candidatePoolData.loading = true;
    state.candidatePoolData.error = null;
  }
);

reduxBuilder.addMutableReducer<PaginatedResponse<Candidate>>(
  CandidateActionTypes.FETCH_CANDIDATE_POOL_SUCCESS,
  (state, payload) => {
    state.candidatePoolData.loading = false;
    state.candidatePoolData.data = payload;
  }
);

reduxBuilder.addMutableReducer<any>(
  CandidateActionTypes.FETCH_CANDIDATE_POOL_ERROR,
  (state, payload) => {
    state.candidatePoolData.loading = false;
    state.candidatePoolData.error = payload;
  }
);

reduxBuilder.addMutableReducer(
  CandidateActionTypes.FETCH_CANDIDATE_POOL_SKILLS,
  (state) => {
    state.currentCandidatePoolSkillsData.loading = true;
    state.currentCandidatePoolSkillsData.error = null;
  }
);

reduxBuilder.addMutableReducer<string[]>(
  CandidateActionTypes.FETCH_CANDIDATE_POOL_SUCCESS_SKILLS,
  (state, payload) => {
    state.currentCandidatePoolSkillsData.loading = false;
    state.currentCandidatePoolSkillsData.data = payload;
  }
);

reduxBuilder.addMutableReducer<any>(
  CandidateActionTypes.FETCH_CANDIDATE_POOL_SKILLS_ERROR,
  (state, payload) => {
    state.currentCandidatePoolSkillsData.loading = false;
    state.currentCandidatePoolSkillsData.error = payload;
  }
);



export default reduxBuilder.build();
