export enum WorkModel {
  PRESENTIAL = "PRESENTIAL",
  REMOTE = "REMOTE",
  HYBRID = "HYBRID",
}

export interface IdNameInterface {
  id: string;
  name: string;
  pcd?: boolean;
}

export interface CountryInterface extends IdNameInterface {
  flag?: string;
}

export interface JobBoardFormFields {
  name: string;
  hire_type_id: string;
  degree_id?: string;
  city_id?: number;
  country_id: string;
  experience_time_id: string;
  experience_level_id: string;
  min_salary?: number;
  max_salary?: number;
  currency_id: number;
  language_id: string;
  description: string;
  end_date: string;
  work_model: string;
  skills_id: string[];
  diversitys_id: string[];
}

export interface JobBoardFormOptions {
  hire_types: IdNameInterface[];
  degrees: IdNameInterface[];
  countries: IdNameInterface[];
  experience_times: IdNameInterface[];
  experience_levels: IdNameInterface[];
  languages: IdNameInterface[];
  skills: IdNameInterface[];
  currencies: {
    id: number;
    name: string;
  }[];
  diversitys:IdNameInterface[];
}

export interface JobInterface {
  id?: string;
  name: string;
  hire_type: IdNameInterface;
  degree?: IdNameInterface;
  city?: {
    id: number;
    name: string;
  };
  country: CountryInterface;
  experience_time: IdNameInterface;
  experience_level: IdNameInterface;
  created?: string;
  end_date: string;
  description: string;
  min_salary?: string;
  max_salary?: string;
  currency: {
    id: number;
    name: string;
  };
  language: IdNameInterface;
  work_model: WorkModel;
  skills: IdNameInterface[];
  owner?: IdNameInterface;
  diversitys: IdNameInterface[]
}

export interface DiversityInterface{
    id: string;
    name: string;
    pcd: boolean;
}

export interface JobListInterface extends IdNameInterface {
  company: IdNameInterface;
  hire_type: IdNameInterface;
  work_model: WorkModel;
  experience_level: IdNameInterface;
  location: IdNameInterface;
  country: CountryInterface;
  currency: string;
  language: IdNameInterface;
  created_humanize: string;
  end_date: string;
  skills: IdNameInterface[];
  active: boolean;
}
