import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import BrazilFlagIcon from './icons/brazil-flag-icon';
import SpainFlagIcon from './icons/spain-flag-icon';
import UnitedStatesFlagIcon from './icons/united-states-flag-icon';
import { Language, LanguagesList, SelectLanguageButton } from './index.styled';

interface LanguageSelectionProps { }

const locales = ['pt-BR', 'en', 'es',];

const iconMap = new Map<string, JSX.Element>();
iconMap.set('pt-BR', <BrazilFlagIcon />);
iconMap.set('en', <UnitedStatesFlagIcon />);
iconMap.set('es', <SpainFlagIcon />);

const LanguageSelection: React.FC<LanguageSelectionProps> = ({ ...props }) => {
    const { t, i18n } = useTranslation();

    const handleLocaleChange = useCallback((locale: string) => {
        i18n.changeLanguage(locale)
    }, [i18n]);

    return (
        <>
            {locales.length > 0 &&
                <LanguagesList {...props}>
                    {locales.map((locale) =>
                        <Language key={locale}>
                            <SelectLanguageButton
                                type="button"
                                onClick={() => handleLocaleChange(locale)}
                                title={t(`languages.${locale}`)}
                                active={i18n.language === locale}
                            >
                                {iconMap.get(locale)}
                            </SelectLanguageButton>
                        </Language>
                    )}
                </LanguagesList>
            }
        </>
    );
};

export default LanguageSelection;
