import React, { useCallback, useEffect, useRef, useState } from "react";
import CurrencyInput from 'react-currency-input-field';
import { Container, Input } from "./range-input.styled"

interface RangeProps {
    name?: string;
    type?: string;
    value?: string;
    placeholder?: string;
    error?: string;
    debounceDelay?: number;
    className?: string;
    onDeboncedValueChange?: (value?: string) => void;
    onValueChange?: (value?: string) => void;
};


const RangeInput: React.FC<RangeProps> = ({ name, type = 'text', placeholder, value, className, error, debounceDelay = 800, onDeboncedValueChange, onValueChange }) => {


    const [selectedValue, setSelectedValue] = useState(value);
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!inputRef.current?.value);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (onDeboncedValueChange && selectedValue !== value) {
                const selectedValueCleaned = selectedValue ?? '';
                onDeboncedValueChange(selectedValueCleaned );
            }
        }, debounceDelay);

        return () => {
            clearTimeout(timeout);
        };
    }, [onDeboncedValueChange, selectedValue, debounceDelay, value]);

    useEffect(() => {
        if (onValueChange && selectedValue !== value) {
            onValueChange(selectedValue ?? '');
        }
    }, [selectedValue, value, onValueChange]);

    useEffect(() => {
        setSelectedValue(value);
    }, [value])

    return (
        <Container>

            <Input

                isFocused={isFocused}
                isFilled={isFilled}
                isErrored={!!error}
                className={className}>

                <CurrencyInput
                    type={type}
                    value={selectedValue}
                    ref={inputRef}
                    name={name}
                    placeholder={placeholder}
                    decimalsLimit={2}
                    prefix="R$"
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onValueChange={(value) => {
                        setSelectedValue(value)
                    }}
                />

            </Input>
        </Container>
    );
}

export default RangeInput;  