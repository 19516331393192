
import styled from 'styled-components';

export const LockedIcon = styled.img`
    margin: .5rem 0;
    width: 18px;
    height: 21px;

    &:not(:last-child) {
        margin-right: 0.5rem;
    }
`;
