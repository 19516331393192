import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { action } from 'typesafe-actions';
import history from '../../routes/history';
import { AuthActionTypes } from '../auth/auth-types';
import { authenticationApi } from './../../api/talent/authentication-service';
import { Authentication } from './../../models/authentication.model';
import { SignIn } from './../../models/sign-in.model';
import { AppState } from './../app-state';
import { SignInActionTypes } from './sign-in-types';

export const sendMagicLink = (signIn: SignIn) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(SignInActionTypes.SEND_MAGIC_LINK));
        try {
            await authenticationApi.sendMagicLink(signIn);
            dispatch(action(SignInActionTypes.SEND_MAGIC_LINK_SUCCESS));
        } catch (error) {
            dispatch(action(SignInActionTypes.SEND_MAGIC_LINK_ERROR, error));
        }
    }
};

export const authenticate = (authentication: Authentication) => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(SignInActionTypes.AUTHENTICATE));

        try {
            const authenticationResponse = await authenticationApi.authenticate(authentication);
            dispatch(action(SignInActionTypes.AUTHENTICATE_SUCCESS));
            dispatch(action(AuthActionTypes.UPDATE_AUTHENTICATION_TOKEN, authenticationResponse.data));
            history.push(`/home/${authentication.event}`);
        } catch (error) {
            dispatch(action(SignInActionTypes.AUTHENTICATE_ERROR, error));
        }
    }
};