import React, { useEffect } from 'react';
import { createPortal } from 'react-dom'
import { Container, Title, CloseButton, Icon, Backdrop } from './modal.styled';

export interface ModalProps {
    title?: string;
    toggle: () => void;
    isShown: boolean;
    width?: number;
    top?: number;
    target: HTMLElement | any;
    handleClose: () => void;
    background?: string;
}

const Modal: React.FC<ModalProps> = ({ title, toggle, children, isShown, target, handleClose, background , ...props }) => {
    const onHandleClose = () => {
        toggle();
        if(handleClose)
            return handleClose();
    }

    useEffect(() => {
        document.body.style.overflow = isShown ? 'hidden' : 'unset';
    }, [isShown])

    const content =
        <>
            <Backdrop onClick={() => toggle()} isShown={isShown} />
            <Container {...props} background={background}>
                <CloseButton onClick={() => onHandleClose()}>
                    <Icon src="/assets/img/inco-close.svg" />
                </CloseButton>
                {Title && <Title>{title}</Title>}
                {children}
            </Container>
        </>

    return isShown ? createPortal(content, target) : null;
}

export default Modal;