import React from "react";

function BrazilFlagIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            fill="none"
            viewBox="0 0 512 512"
        >
            <path
                fill="#6DA544"
                d="M256 512c141.385 0 256-114.615 256-256S397.385 0 256 0 0 114.615 0 256s114.615 256 256 256z"
            >
            </path>
            <path
                fill="#FFDA44"
                d="M256 100.174L467.478 256 256 411.826 44.523 256 256 100.174z"
            >
            </path>
            <path
                fill="#F0F0F0"
                d="M256 345.043c49.177 0 89.043-39.866 89.043-89.043 0-49.177-39.866-89.043-89.043-89.043-49.177 0-89.043 39.866-89.043 89.043 0 49.177 39.866 89.043 89.043 89.043z"
            >
            </path>
            <path
                fill="#0052B4"
                d="M211.478 250.436c-15.484 0-30.427 2.355-44.493 6.725.623 48.64 40.227 87.884 89.015 87.884 30.168 0 56.812-15.017 72.919-37.968-27.557-34.497-69.958-56.641-117.441-56.641zM343.394 273.06a89.45 89.45 0 001.651-17.06c0-49.178-39.866-89.043-89.043-89.043-36.694 0-68.194 22.201-81.826 53.899a183.693 183.693 0 0137.305-3.812c51.717-.001 98.503 21.497 131.913 56.016z"
            >
            </path>
        </svg>
    );
}

export default BrazilFlagIcon;
