import styled, { css } from "styled-components";
import { animated } from "react-spring";

export const FilterContainer = styled.div`
  position: relative;
`;

export const FilterButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  font-size: 1rem;
  color: #000;
  border: none;
  outline: none;
  margin: 2rem 0;

  span {
    padding: 0;
    padding-right: 1rem;
  }
`;

export const FilterLabel = styled.span`
  font-size: 1rem;
  font-weight: 800;
  color: #452470;
  margin: 0;
  margin-left: 0.5rem;
  white-space: nowrap;
`;

interface DropdownMenuProps {
    isShown?: boolean;
}
export const DropdownMenu = styled(animated.ul)<DropdownMenuProps>`
    all: unset;
    list-style: none;
    display: ${({ isShown }) => (isShown ? "flex" : "none")};
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 0.5rem;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
    padding: 1rem 0;
    background: #fff;
    width: fit-content;
    block-size: fit-content;

    position: absolute;
    z-index: 150;
    top: 2rem;
    right: 0rem;

    svg {
        color: #a44dda;
        font-size: 1rem;
    }
`;
  
interface DropdownItemProps {
    isActive?: boolean;
}
export const DropdownItem = styled.li<DropdownItemProps>`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    cursor: pointer;

    &:hover, &:active {
        background-color: rgba(63, 67, 80, 0.08);
    }

    ${({ isActive }) => !isActive &&
    css`
    svg {
        display: none;
    }
`}
`;
  
export const DropdownSpan = styled.span`
    white-space: nowrap;
    color: #3f4350 !important;
    margin-right: 1rem;
`;