
import React, { useCallback, useMemo } from 'react';
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { Container, IconButton, Page } from './pagination.styled';

interface PaginationProps {
    currentPage?: number;
    pageNumbers?: number;
    onPageChange?: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage = 0, pageNumbers = 0, onPageChange }) => {

    const pages = useMemo(() => {
        const pages = [];

        if (currentPage > 2) {
            pages.push(currentPage - 1);
        }

        pages.push(currentPage);

        if ((currentPage + 1) < pageNumbers) {
            pages.push(currentPage + 1);
        }

        return pages;
    }, [currentPage, pageNumbers]);

    const handlePageChange = useCallback((page: number) => {
        if (onPageChange) {
            onPageChange(page);
        }
    }, [onPageChange]);

    return (
        <Container>
            <IconButton
                type="button"
                disabled={currentPage <= 1}
                onClick={() => handlePageChange((currentPage - 1))}
            >
                <GrFormPrevious />
            </IconButton>

            {(currentPage > 1) &&
                <>
                    <Page
                        type="button"
                        onClick={() => handlePageChange(1)}
                    >
                        {1}
                    </Page>
                </>
            }

            {(currentPage > 3) && 
                <p>...</p>
            }

            {pages.map((page, key) =>
                <Page
                    type="button"
                    key={key}
                    onClick={() => handlePageChange(page)}
                    isCurrent={page === currentPage}
                >
                    {page}
                </Page>
            )}

            {(pageNumbers > 3 && pageNumbers > currentPage + 2) && 
                <p>...</p>
            }

            {(pageNumbers > currentPage) &&
                <>
                    <Page
                        type="button"
                        onClick={() => handlePageChange(pageNumbers)}
                    >
                        {pageNumbers}
                    </Page>
                </>
            }

            <IconButton
                type="button"
                disabled={currentPage >= pageNumbers}
                onClick={() => handlePageChange((currentPage + 1))}
            >
                <GrFormNext />
            </IconButton>
        </Container>
    );
}

export default Pagination;
