import React from "react";
import { useTranslation } from "react-i18next";
import { Status } from "../../../../../../../components/talent/components/feedback-status/feedback-status.styled";
import { LastActionStatus } from "../../../../../../../models/last-action-status.enum";
import { selectedSchema, SelectedStatusProps } from "./constants";

const SelectedStatus: React.FC<SelectedStatusProps> = ({ talentStatus }) => {
  const { t } = useTranslation(["translation", "private-home-page"]);

  const whichStatusToShow =
    talentStatus?.last_action?.status !== LastActionStatus.INTERVIEW
      ? talentStatus?.last_action?.status
      : talentStatus?.job_offer?.status;

  if (!whichStatusToShow) {
    return (
      <Status
        border={selectedSchema.pending.border}
        background={selectedSchema.pending.background}
        color={selectedSchema.pending.color}
      >
        <p>{t(selectedSchema.pending.title)}</p>
      </Status>
    );
  }

  const status = whichStatusToShow?.toLocaleLowerCase();
  return (
    <Status
      border={selectedSchema[status].border}
      background={selectedSchema[status].background}
      color={selectedSchema[status].color}
    >
      <p>{t(selectedSchema[status].title)}</p>
    </Status>
  );
};

export default SelectedStatus;
