
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CandidateDetail } from '../../../../models/candidate-detail.model';
import { Item, List, SectionBlock, Text } from '../../talent.styled';
import { BulletTitle, TitleAbout } from '../about/about.styled';

interface AcademicFormationExperienceProps {
    candidate: CandidateDetail
}

const AcademicFormationExperience: React.FC<AcademicFormationExperienceProps> = ({ candidate }) => {
    const { t } = useTranslation('private-home-page');
    return (
        <SectionBlock>
            <TitleAbout>{t('talent.academicEducation')}</TitleAbout>
            <List>
                {candidate?.academic_formation.map(formation => (
                    <Item key={formation.id}>
                        <BulletTitle>{formation.institution.label}</BulletTitle>
                        <Text><strong>{formation.course}</strong></Text>

                        <Text>
                            {formation.level_name}
                        </Text>
                    </Item>
                ))}
            </List>
        </SectionBlock>
    );
}

export default AcademicFormationExperience;
