import React from "react";
import { jobOfferStatusTypes } from "../../../../models/candidate.model";
import { Container } from "./job-offer-status.styled";

import { Status } from "../../../../components/talent/components/feedback-status/feedback-status.styled";
import { t } from "i18next";

interface SchemaStyled {
  background: string;
  border: string;
  color: string;
  title: string;
  description: string;
}

interface JobOfferProps {
  [key: string]: SchemaStyled;
}

interface JobOfferStatusProps {
  status: jobOfferStatusTypes;
  isDetailed: boolean;
}

const JobOfferSchema: JobOfferProps = {
  PENDING: {
    background: "#e7efff",
    border: "#cadcff",
    color: "#0c5dff",
    title: "private-home-page:jobOffer.status.pending",
    description: "private-home-page:jobOffer.description.pending",
  },
  MATCH: {
    background: "#F5EDFF",
    border: "#E2D0F8",
    color: "#452470",
    title: "private-home-page:jobOffer.status.match",
    description: "private-home-page:jobOffer.description.match",
  },
  REJECTED: {
    background: "#FFF9F0",
    border: "#FFE8BE",
    color: "#E2B76B",
    title: "private-home-page:jobOffer.status.rejected",
    description: "private-home-page:jobOffer.description.notInterested",
  },
};

const JobOfferStatus: React.FC<JobOfferStatusProps> = ({
  status,
  isDetailed,
}) => {
  return (
    <Container>
      <div>
        <Status
          border={JobOfferSchema[status].border}
          background={JobOfferSchema[status].background}
          color={JobOfferSchema[status].color}
        >
          <p>{t(JobOfferSchema[status].title)}</p>
        </Status>
        {isDetailed && <p>{t(JobOfferSchema[status].description)}</p>}
      </div>
    </Container>
  );
};

export default JobOfferStatus;
