import i18n from "i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useDialog } from "../../hooks";
import useQuery from "../../hooks/use-query";
import { AppState } from "../../store/app-state";
import {
  fetchCandidate,
  notifyOpenCandidateDetail,
} from "../../store/candidate/candidate-actions";
import { LoadingIcon } from "../content/loading/loading.styled";
import JobOfferModal from "../job-offer";
import About from "./components/about/about";
import Actions from "./components/actions/actions";
import CandidateSkills from "./components/candidate-skills/candidate-skills";
import Header from "./components/header/header";
import JobOffer from "./components/job-offer";
import PcDs from "./components/pcd/pcd";
import Spotlight from "./components/spotlight/spotlight";
import TimeAvailability from "./components/time-availability";
import useCandidateActions from "./hooks/use-candidate-actions";

import { LastActionStatus } from "../../models/last-action-status.enum";
import FeedbackStatus from "./components/feedback-status/feedback-status";
import JobOfferStatus from "./components/job-offer/job-offer-status";
import Language from "./components/language/language";
import {
  AboutContent,
  Container,
  Content,
  LoadingContent,
  Section,
  SectionBlock,
  Sidebar,
  Text,
  Title,
} from "./talent.styled";
import ModalMatch from "../modal-match";
import { Skill } from "./components/candidate-skills/candidate-skills.styled";
import ProfessionalMoment from "./components/professional-moment";
import { fetchProfessionalMoment } from "../../store/application/application-actions";

interface TalentProps {
  talentId: string;
  userId?: string;
  trackId?: string;
  ecosystem?: string;
  isNotifyOpenCandidateDetail?: boolean;
  showJobOrigin?: boolean;
  jobName?: string;
}

const Talent: React.FC<TalentProps> = ({
  talentId,
  userId,
  trackId: selectedTrack,
  ecosystem,
  isNotifyOpenCandidateDetail,
  showJobOrigin,
  jobName,
}) => {
  const { t } = useTranslation();
  const { createDialog } = useDialog();
  const dispatch = useDispatch();
  const [query] = useQuery<{ talentPool?: boolean; track?: boolean }>();
  const [isOpenJobOffer, setIsOpenJobOffer] = useState(false);
  const { currentEventTrackData } = useSelector(
    (state: AppState) => state.eventState
  );
  const { candidateDetailData } = useSelector(
    (state: AppState) => state.candidateState
  );
  const { data: selectedCandidatesData } = useSelector(
    (state: AppState) => state.eventState.selectedCandidatesData
  );
  const { data: professionalMomentData } = useSelector(
    (state: AppState) => state.applicationState.professionalMomentData
  );

  const selectedCandidateJobOffer = useMemo(() => {
    let candidateFromSelected =
      userId &&
      selectedCandidatesData?.results.find(
        (currentTalent) => currentTalent.user_id === userId
      )?.job_offer;

    return candidateFromSelected;
  }, [selectedCandidatesData, userId]);

  const trackId = useMemo(() => {
    return currentEventTrackData.data?.uuid || selectedTrack || "";
  }, [currentEventTrackData.data?.uuid, selectedTrack]);

  const isLocked = useMemo(() => {
    return (
      !!candidateDetailData.data &&
      candidateDetailData.data.last_action === null
    );
  }, [candidateDetailData.data]);

  const isJob = useMemo(() => {
    return currentEventTrackData.data?.is_from_job;
  }, [currentEventTrackData.data]);

  const { requestUnlockCandidate } = useCandidateActions({
    talentId,
    trackId,
    ecosystem,
  });

  const handleError = useCallback(
    async (error: any) => {
      await createDialog({
        title: t("error"),
        message: t("wantToTryAgain"),
      });
    },
    [t, createDialog]
  );

  const handleConfirm = useCallback(() => {
    requestUnlockCandidate();
    setIsOpenJobOffer(false);
  }, [requestUnlockCandidate]);

  const handleUnlockCandidate = useCallback(() => {
    if (query.talentPool || query.track) {
      return setIsOpenJobOffer(true);
    } else {
      handleConfirm();
    }
  }, [query, handleConfirm]);

  useEffect(() => {
    dispatch(fetchCandidate(talentId, trackId));
    dispatch(fetchProfessionalMoment());

    if (isNotifyOpenCandidateDetail) {
      dispatch(notifyOpenCandidateDetail(talentId, trackId));
    }
  }, [talentId, trackId, dispatch, isNotifyOpenCandidateDetail]);

  useEffect(() => {
    const error = candidateDetailData.error;
    if (error) {
      handleError(error);
    }
  }, [candidateDetailData.error, handleError]);

  const professionalMoment = useMemo(() => {
    return professionalMomentData?.find(
      (moment) => moment.value === candidateDetailData.data?.professional_moment
    );
  }, [professionalMomentData, candidateDetailData]);

  return (
    <Container>
      {candidateDetailData.loading ? (
        <LoadingContent>
          <LoadingIcon size={80} />
          {i18n.language !== "pt-BR" && (
            <Text>
              {t("talent.translateMessage", { ns: "private-home-page" })}
            </Text>
          )}
        </LoadingContent>
      ) : (
        <>
          <Header candidate={candidateDetailData.data} isLocked={isLocked} />
          {professionalMoment && (
            <ProfessionalMoment text={professionalMoment.label} />
          )}
          <AboutContent>
            {(query?.talentPool || query?.track) &&
              isOpenJobOffer &&
              (isJob ? (
                <ModalMatch
                  onClick={handleConfirm}
                  onClose={() => setIsOpenJobOffer(false)}
                />
              ) : (
                <JobOfferModal
                  isOpen={isOpenJobOffer}
                  onConfirm={handleConfirm}
                  onDismiss={() => setIsOpenJobOffer(false)}
                />
              ))}
            <Content>
              <About candidate={candidateDetailData.data} isLocked={isLocked} />
            </Content>
            <Sidebar>
              {showJobOrigin && jobName && (
                <SectionBlock>
                  <Title>
                    {t("talent.applied_job", { ns: "private-home-page" })}
                  </Title>
                  <Skill style={{ height: "fit-content" }}>{jobName}</Skill>
                </SectionBlock>
              )}
              {!candidateDetailData.loading && selectedCandidateJobOffer && (
                <SectionBlock>
                  <Title>
                    {t("talent.candidate_interest", {
                      ns: "private-home-page",
                    })}
                  </Title>

                  <JobOfferStatus
                    status={selectedCandidateJobOffer.status}
                    isDetailed={true}
                  />
                  <JobOffer
                    joboffer={selectedCandidateJobOffer}
                    isLocked={isLocked}
                  />
                </SectionBlock>
              )}
              {candidateDetailData.data &&
                candidateDetailData.data?.skills?.length > 0 && (
                  <CandidateSkills candidate={candidateDetailData.data} />
                )}

              <Spotlight candidate={candidateDetailData.data} />

              {candidateDetailData.data?.languages &&
                candidateDetailData.data?.languages.length > 0 && (
                  <Language languages={candidateDetailData.data?.languages} />
                )}

              <PcDs candidate={candidateDetailData.data} isLocked={isLocked} />
              {candidateDetailData.data &&
                candidateDetailData.data.time_availability &&
                candidateDetailData.data?.time_availability.length > 0 && (
                  <TimeAvailability
                    timeAvailability={
                      candidateDetailData.data.time_availability
                    }
                    isLocked={isLocked}
                  />
                )}
              {!candidateDetailData.data?.last_action ||
              candidateDetailData.data.last_action.status ===
                LastActionStatus.INTERVIEW ? (
                <Section>
                  <Actions
                    talentId={talentId}
                    trackId={trackId}
                    isLocked={isLocked}
                    onUnlockCandidate={handleUnlockCandidate}
                  />
                </Section>
              ) : (
                <FeedbackStatus
                  status={candidateDetailData.data.last_action.status}
                />
              )}
            </Sidebar>
          </AboutContent>
        </>
      )}
    </Container>
  );
};

export default Talent;
