import { StorageData } from "../storage-data";

export interface LoadState {
    isVisible: StorageData<boolean | null, any>;
}

export const INITIAL_STATE: LoadState = {
    isVisible: {
        data: false,
        loading: false,
        error: null
    }
}

export enum LoadActionTypes {
    SET_VISIBLE = '@loading/VISIBLE',
    SET_INVISIBLE = '@loading/INVISIBLE',
}
