import { StorageData } from "./../storage-data";

export interface ProfessionalMoment {
  label: string;
  value: string;
  open_to_work?: boolean;
}

export interface ApplicationState {
  professionalMomentData: StorageData<ProfessionalMoment[] | null, null | string>;
}

export const INITIAL_STATE: ApplicationState = {
  professionalMomentData: {
    data: null,
    loading: false,
    error: null,
  },
};

export enum ApplicationActionTypes {
  FETCH_PROFESSIONAL_MOMENTS = "@application/FETCH_PROFESSIONAL_MOMENTS",
  FETCH_PROFESSIONAL_MOMENTS_SUCCESS = "@application/FETCH_PROFESSIONAL_MOMENTS_SUCCESS",
  FETCH_PROFESSIONAL_MOMENTS_ERROR = "@application/FETCH_PROFESSIONAL_MOMENTS_ERROR",
}
