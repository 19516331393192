import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Message, SlideContainer } from "./slides.styled";

const Slide3: React.FC = () => {
    const { t } = useTranslation('private-home-page');
    const root = useCallback((key: string) => {
        return t(`job-board.instructions-modal.slide-3.${key}`);
    }, [t]);

    return (
        <SlideContainer>
            <Message>{root('message-1')}</Message>
            <Message>{root('message-2')}</Message>
            <Message>{root('message-3')}<br></br>{root('message-4')}</Message>
        </SlideContainer>
    );
}

export default Slide3;