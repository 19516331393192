import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { Error, InputContainer } from './input.styled';

interface InputProps {
    name: string;
    type?: string;
    value?: string;
    placeholder?: string;
    icon?: React.ComponentType<IconBaseProps>;
    error?: string;
    debounceDelay?: number;
    className?: string;
    onDeboncedValueChange?: (value?: string) => void;
    onValueChange?: (value?: string) => void;
    onChange?: (value?: string) => void;
    onClick?: () => void;
};

const Input: React.FC<InputProps> = ({ icon: Icon, type = 'text', placeholder, value, className, error, debounceDelay = 800, onDeboncedValueChange, onValueChange, onClick }) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!inputRef.current?.value);
    }, []);


    useEffect(() => {
        if (onValueChange && selectedValue !== value) {
            onValueChange(selectedValue ?? '');
        }
    }, [selectedValue, value, onValueChange]);

    useEffect(() => {
        setSelectedValue(value);
    }, [value])

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (onDeboncedValueChange && selectedValue !== value) {
                onDeboncedValueChange(selectedValue ?? '');
            }
        }, debounceDelay);

        return () => {
            clearTimeout(timeout);
        };
    }, [selectedValue, value, debounceDelay, onDeboncedValueChange]);

    return (
        <InputContainer
            isFocused={isFocused}
            isFilled={isFilled}
            isErrored={!!error}
            className={className}
        >
            <input
                type={type}
                value={selectedValue}
                ref={inputRef}
                placeholder={placeholder}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onChange={(e) => setSelectedValue(e.target.value)}
                onClick={onClick}
            />
            {Icon && <Icon />}

            {!!error && (
                <Error message={error}>
                    <FiAlertCircle />
                </Error>
            )}
        </InputContainer>
    );
}

export default Input;