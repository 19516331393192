import styled from "styled-components";

interface InfoTableContainerProps {
    width?: string;
}
export const InfoTableContainer = styled.div<InfoTableContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${({width}) => width ?? '100%'};
`;

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: 0;
    padding: 0;
`;

export const ContainerTable = styled.table`
    border-collapse: collapse;
    margin: 0 1rem;
    border-radius: 1rem !important;
    padding: 0.5rem;
`;

export const TableRow = styled.tr`
    font-size: 0.9rem;
    td:first-child, th:first-child {
        padding-left: 1rem;
    }

    &:hover{
        td {
            cursor: pointer;
            background-color: rgba(63, 67, 80, 0.03);
        }
    }

    &:first-child {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        th:first-child {
            border-top-left-radius: 0.5rem;
        }
        th:last-child {
            border-top-right-radius: 0.5rem;
        }
    }

    &:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        td:first-child {
            border-bottom-left-radius: 0.5rem;
        }
        td:last-child {
            border-bottom-right-radius: 0.5rem;
        }
    }
`;

interface DataCellProps {
    color?: string;
    bold?: number;
}
export const DataCell = styled.td<DataCellProps>`
    padding: .75rem .5rem;
    background-color: #fff;
    color: ${({color}) => color ?? "#6A6A6A"};
    font-weight: ${({bold}) => bold ?? 400};
`;