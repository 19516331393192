
import styled from 'styled-components';

export const Container = styled.footer`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    padding: 2rem 1rem;

    img {
        margin: 1rem;
        height: 40px;
    }
`;
