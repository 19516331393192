import { Container, Event, EventDate, EventDay, EventDescription, EventValue, Extract, Title } from './invoice-extract.styled';

const InvoiceExtract: React.FC = () => {
    return (
        <Container>
            <Title>Extrato</Title>

            <Extract>
                <Event>
                    <EventDate>
                        <EventDay>05</EventDay> qua
                    </EventDate>
                    <EventDescription>Mensalidade do mês, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.</EventDescription>
                    <EventValue>R$ 699,00</EventValue>
                </Event>

                <Event>
                    <EventDate>
                        <EventDay>05</EventDay> qua
                    </EventDate>
                    <EventDescription>Mensalidade do mês, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.</EventDescription>
                    <EventValue>R$ 699,00</EventValue>
                </Event>

                <Event>
                    <EventDate>
                        <EventDay>05</EventDay> qua
                    </EventDate>
                    <EventDescription>Mensalidade do mês, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.</EventDescription>
                    <EventValue>R$ 699,00</EventValue>
                </Event>

                <Event>
                    <EventDate>
                        <EventDay>05</EventDay> qua
                    </EventDate>
                    <EventDescription>Mensalidade do mês, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.</EventDescription>
                    <EventValue>R$ 699,00</EventValue>
                </Event>
            </Extract>
        </Container>
    );
};

export default InvoiceExtract;
