import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { action } from "typesafe-actions";
import history from "../../routes/history";
import { AppState } from './../app-state';
import { GlobalTypes } from './../global-types';
import { AuthActionTypes } from "./auth-types";

export const updateAuthenticationToken = (token: string) => action(AuthActionTypes.UPDATE_AUTHENTICATION_TOKEN, { token });

export const logout = () => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(GlobalTypes.CLEAR));
        history.push(`/`);
    }
};
