import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useDialog } from "../../../hooks";
import { AppState } from "./../../../store/app-state";
import {
  checkPermissionToUnlockCandidate,
  checkPermissionToUnlockCandidateVertical,
  confirmEcosystemAuthorizationRequest,
  confirmLeedRequest,
  confirmNotifyToUnlockCandidate,
  confirmPermissionToUnlockCandidate,
  UnlockCandidate,
  unlockCandidate,
} from "./../../../store/candidate/candidate-actions";

interface UseCandidateActionsProps {
  talentId: string;
  trackId?: string;
  ecosystem?: string;
}

interface CandidateActions {
  requestUnlockCandidate: () => void;
  requestUnlockCandidateVertical: () => void;
}

export default function useCandidateActions({
  talentId,
  trackId,
  ecosystem,
}: UseCandidateActionsProps): CandidateActions {
  const { t } = useTranslation("private-home-page");
  const dispatch = useDispatch();
  const { createDialog } = useDialog();
  const unlockCandidateData = useSelector(
    (state: AppState) => state.candidateState.unlockCandidateData.data
  );
  const jobOffer = useSelector(
    (state: AppState) => state.candidateState.jobOffer
  );

  const requestUnlockCandidate = useCallback(() => {
    dispatch(checkPermissionToUnlockCandidate(ecosystem));
  }, [ecosystem, dispatch]);

  const requestUnlockCandidateVertical = useCallback(() => {
    dispatch(checkPermissionToUnlockCandidateVertical());
  }, [dispatch]);

  const requestPermission = useCallback(async () => {
    dispatch(confirmPermissionToUnlockCandidate(true));
  }, [dispatch]);

  const confirmCandidateNotification = useCallback(async () => {
    dispatch(confirmNotifyToUnlockCandidate());

    let payload: UnlockCandidate = { talentId, trackId };

    if (jobOffer) {
      payload["metadata"] = {
        job_offer: jobOffer,
      };
    }

    dispatch(unlockCandidate(payload));
  }, [dispatch, talentId, trackId, jobOffer]);

  const confirmLeedSoldOff = useCallback(async () => {
    const confirmed = await createDialog({
      title: t("talent.attentionRecruiter"),
      message: `
                ${t("talent.creditLimitReached")}
                ${t("talent.wantToGetInTouchWithDIO")}
            `,
    });

    dispatch(confirmLeedRequest(!!confirmed));
  }, [t, createDialog, dispatch]);

  const confirmUnauthorizedEcosystem = useCallback(async () => {
    const confirmed = await createDialog({
      title: t("talent.attentionRecruiter"),
      message: `
                ${t("talent.upgradeContractPlan")}
                ${t("talent.wantToGetInTouchWithDIO")}
            `,
    });

    dispatch(confirmEcosystemAuthorizationRequest(!!confirmed));
  }, [t, createDialog, dispatch]);

  useEffect(() => {
    if (unlockCandidateData?.needPermission) {
      requestPermission();
    }

    if (unlockCandidateData?.confirmNotify) {
      confirmCandidateNotification();
    }

    if (unlockCandidateData?.needLeed) {
      confirmLeedSoldOff();
    }

    if (unlockCandidateData?.needEcosystem) {
      confirmUnauthorizedEcosystem();
    }
  }, [
    unlockCandidateData,
    requestPermission,
    confirmCandidateNotification,
    confirmLeedSoldOff,
    confirmUnauthorizedEcosystem,
  ]);

  return { requestUnlockCandidate, requestUnlockCandidateVertical };
}
