import { useMemo } from "react";
import TableFilter, { FilterOption } from "."
import { useQuery } from "../../../../../../../hooks";
import HomeDashboardPageQuery from "../../../../home-dashboard-page-query.model";

const PageSizeFilter: React.FC = () => {
    const [query, setQuery] = useQuery<HomeDashboardPageQuery>();

    const pageSizeOptions = useMemo<FilterOption[]>(() => ([
        {
            value: "page-size-10",
            action: () => setQuery({ ...query, pageSize: 10 })
        },
        {
            value: "page-size-15",
            action: () => setQuery({ ...query, pageSize: 15 })
        },
        {
            value: "page-size-25",
            action: () => setQuery({ ...query, pageSize: 25 })
        }
    ]), [query, setQuery]);

    const initial = useMemo<FilterOption>(() => {
        switch(query?.pageSize?.toString()){
            case "15":
                return pageSizeOptions[1];
            case "25":
                return pageSizeOptions[2];
            default:
                return pageSizeOptions[0];
        }
    }, [pageSizeOptions, query]);

    return (
        <TableFilter 
            label={'page-size'} 
            options={pageSizeOptions} 
            initial={initial}
        />
    );
};

export default PageSizeFilter;