import Plan from "../../models/plan.model";
import { ReducerBuilder } from "../../utils/redux/reducer-builder";
import { Invoice } from './../../models/invoice.model';
import { GlobalTypes } from './../global-types';
import { CompanyActionTypes, CompanyState, INITIAL_STATE } from './company-types';

const reduxBuilder = new ReducerBuilder<CompanyState>(INITIAL_STATE);

reduxBuilder.addReducer(GlobalTypes.CLEAR, () => {
    return INITIAL_STATE;
});

reduxBuilder.addMutableReducer(CompanyActionTypes.FETCH_LAST_INVOICE, (state) => {
    state.lastInvoiceData.loading = true;
    state.lastInvoiceData.error = null;
});

reduxBuilder.addMutableReducer<Invoice>(CompanyActionTypes.FETCH_LAST_INVOICE_SUCCESS, (state, payload) => {
    state.lastInvoiceData.loading = false;
    state.lastInvoiceData.data = payload;
});

reduxBuilder.addMutableReducer<any>(CompanyActionTypes.FETCH_LAST_INVOICE_ERROR, (state, error) => {
    state.lastInvoiceData.loading = false;
    state.lastInvoiceData.error = error;
});

reduxBuilder.addMutableReducer(CompanyActionTypes.FETCH_COMPANY_PLAN, (state) => {
    state.planData.loading = true;
    state.planData.error = null;
});

reduxBuilder.addMutableReducer<Plan>(CompanyActionTypes.FETCH_COMPANY_PLAN_SUCCESS, (state, payload) => {
    state.planData.loading = false;
    state.planData.data = payload;
});

reduxBuilder.addMutableReducer<any>(CompanyActionTypes.FETCH_COMPANY_PLAN_ERROR, (state, error) => {
    state.planData.loading = false;
    state.planData.error = error;
});

export default reduxBuilder.build();
