
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CandidateDetail } from '../../../../models/candidate-detail.model';
import { SectionBlock, Title } from '../../talent.styled';
import { Skill, SkillsList } from '../candidate-skills/candidate-skills.styled';

interface PcDsProps {
    timeAvailability?: CandidateDetail["time_availability"];
    isLocked: boolean;
}

const TimeAvailability: React.FC<PcDsProps> = ({ isLocked, timeAvailability }) => {
    const { t } = useTranslation('private-home-page');
    return (
        <>
            <SectionBlock>
                <Title>{t('talent.time_availability')}</Title>
                <SkillsList>
                    {timeAvailability?.map((time, idx) =>
                        <Skill key={`idx${idx * 2}`}>
                            {time.start.split(":")[0]}h - {time.end.split(":")[0]}h
                        </Skill>
                    )}
                </SkillsList>
            </SectionBlock>
        </>
    );
}

export default TimeAvailability;
