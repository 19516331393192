import React from "react";
import { useModal } from "../../../../hooks";
import { JobOfferPayload } from "../../../../models/candidate-action.model";
import { jobOfferStatusTypes } from "../../../../models/candidate.model";
import { Container, JobOfferUnlocked, ShowJobOffer } from "./job-offer.styled";
import Resume from "./resume";

interface JobOfferProps {
  isLocked: boolean;
  joboffer: {
    status: jobOfferStatusTypes;
    metadata: JobOfferPayload;
  };
}

const JobOfferTalentStatus: React.FC<JobOfferProps> = ({
  isLocked,
  joboffer,
}) => {
  const { isShown, toggle } = useModal();

  return (
    <Container>
      {!isLocked && (
        <JobOfferUnlocked>
          <Resume isShown={isShown} joboffer={joboffer} toggle={toggle} />
          <ShowJobOffer onClick={() => toggle()}>
            Resumo da vaga
            <img src="/assets/img/show-more.svg" alt="Resumo da Vaga" />
          </ShowJobOffer>
        </JobOfferUnlocked>
      )}
    </Container>
  );
};

export default JobOfferTalentStatus;
