
import { useTranslation } from 'react-i18next';
import { CandidateDetail } from '../../../../models/candidate-detail.model';
import { Section, Text } from '../../talent.styled';
import AcademicFormationExperience from '../academic-formation/academic-formation';
import Certificates from '../certificates/certificates';
import ProfessionalExperience from '../professional-experience/professional-experience';
import { Content, TitleAbout } from './about.styled';

interface AboutPropos {
    candidate?: CandidateDetail | null;
    isLocked?: boolean | true;
}

const About: React.FC<AboutPropos> = ({ candidate, isLocked }) => {
    const { t } = useTranslation('private-home-page');
    return (
        <Content>
            {candidate?.about &&
                <Section>
                    <TitleAbout>{t('talent.about')}</TitleAbout>
                    <Text>{candidate?.about}</Text>
                </Section>
            }
            {(candidate?.professional_experience && candidate?.professional_experience.length > 0) &&
                <ProfessionalExperience candidate={candidate} />
            }

            {(candidate?.academic_formation && candidate?.academic_formation.length > 0) &&
                <AcademicFormationExperience candidate={candidate} />
            }

            {candidate && candidate?.achievements?.length > 0 &&
                <Certificates candidate={candidate} isLocked={isLocked}/>
            }
        </Content>
    )
}

export default About;
