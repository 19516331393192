import styled, { css } from 'styled-components';

export const LanguagesList = styled.ul`
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const Language = styled.li`
    margin: .25rem;
`;

export const SelectLanguageButton = styled.button<{ active?: boolean }>`
    padding: 0;
    border: none;
    background-color: transparent;
    filter: grayscale(100%);
    transition: filter .4s;

    &:hover {
        filter: none;
    }

    ${({ active }) => active && css`
        filter: none;
    `};

    svg {
        width: 2rem;
        height: 2rem;
    }
`;
