import PaginatedResponse from '../../api/talent/models/paginated-response.model';
import CandidateStatus from '../../models/candidate-status.model';
import EventTrackStatistics from '../../models/event-track-statistics.model';
import { Event } from '../../models/event.model';
import { OriginFilter } from '../../models/origin-filter.model';
import Track from '../../models/track.model';
import { ReducerBuilder } from '../../utils/redux/reducer-builder';
import { Candidate } from './../../models/candidate.model';
import { NotificationModel, NOTIFICATION_STATUS } from './../../models/notification.model';
import { SelectedCandidate } from './../../models/selected-candidate.model';
import { GlobalTypes } from './../global-types';
import { EventActionTypes, EventState, INITIAL_STATE } from './event-types';

const reduxBuilder = new ReducerBuilder<EventState>(INITIAL_STATE);

reduxBuilder.addReducer(GlobalTypes.CLEAR, () => {
    return INITIAL_STATE;
});

reduxBuilder.addMutableReducer(EventActionTypes.FETCH_EVENT_DATA, (state) => {
    state.eventData.loading = true;
    state.eventData.error = null;
});

reduxBuilder.addMutableReducer<Event>(EventActionTypes.FETCH_EVENT_DATA_SUCCESS, (state, payload) => {
    state.eventData.loading = false;
    state.eventData.data = payload;
    state.eventData.data.start = new Date(state.eventData.data.start);
    state.eventData.data.end = new Date(state.eventData.data.end);
});

reduxBuilder.addMutableReducer<any>(EventActionTypes.FETCH_EVENT_DATA_ERROR, (state, payload) => {
    state.eventData.loading = false;
    state.eventData.error = payload;
});


reduxBuilder.addMutableReducer(EventActionTypes.FETCH_SELECTED_CANDIDATES, (state) => {
    state.selectedCandidatesData.loading = true;
    state.selectedCandidatesData.error = null;
});

reduxBuilder.addMutableReducer<PaginatedResponse<SelectedCandidate>>(EventActionTypes.FETCH_SELECTED_CANDIDATES_SUCCESS, (state, payload) => {
    state.selectedCandidatesData.loading = false;
    const candidates: Candidate[] = [];
    for (const selectedCandidate of payload.results) {
        const candidate = selectedCandidate.candidate;
        candidate.selection = {
            source_id: selectedCandidate.source_id,
            source: selectedCandidate.source,
            expire: new Date(selectedCandidate.expire),
            origin_name: selectedCandidate.origin_name,
            origin_type: selectedCandidate.origin_type,
        };
        candidates.push({ ...candidate, phone: selectedCandidate.phone, time_availability: selectedCandidate.time_availability });
    }

    const response = { ...payload, results: candidates };
    state.selectedCandidatesData.data = response;
});

reduxBuilder.addMutableReducer<any>(EventActionTypes.FETCH_SELECTED_CANDIDATES_ERROR, (state, payload) => {
    state.selectedCandidatesData.loading = false;
    state.selectedCandidatesData.error = payload;
});

reduxBuilder.addMutableReducer(EventActionTypes.FETCH_EVENT_TRACKS, (state) => {
    state.eventTracksData.loading = true;
    state.eventTracksData.error = null;
});

reduxBuilder.addMutableReducer<Track[]>(EventActionTypes.FETCH_EVENT_TRACKS_SUCCESS, (state, payload) => {
    state.eventTracksData.loading = false;
    state.eventTracksData.data = payload;

    const currentSelectedTrack = state.currentEventTrackData.data?.uuid;

    for (let i = 0; i < state.eventTracksData.data.length; i++) {
        const track = state.eventTracksData.data[i];

        if (track.end) {
            track.end = new Date(track.end);
        }

        if (track.uuid === currentSelectedTrack) {
            track.currentTrack = true;
        } else {
            track.currentTrack = false;
        }
    }
});

reduxBuilder.addMutableReducer<any>(EventActionTypes.FETCH_EVENT_TRACKS_ERROR, (state, payload) => {
    state.eventTracksData.loading = false;
    state.eventTracksData.error = payload;
});

reduxBuilder.addMutableReducer(EventActionTypes.FETCH_EVENT_TRACK, (state) => {
    state.currentEventTrackData.loading = true;
    state.currentEventTrackData.error = null;
});

reduxBuilder.addMutableReducer<Track>(EventActionTypes.FETCH_EVENT_TRACK_SUCCESS, (state, payload) => {
    state.currentEventTrackData.loading = false;
    payload.currentTrack = true;
    if (payload.end) {
        payload.end = new Date(payload.end);
    }
    state.currentEventTrackData.data = payload;
    state.currentTrackCandidatesData.data = null;

    if (state.eventTracksData.data) {
        for (let i = 0; i < state.eventTracksData.data.length; i++) {
            const track = state.eventTracksData.data[i];

            if (track.uuid === payload.uuid) {
                state.eventTracksData.data[i] = payload;
            } else {
                track.currentTrack = false;
            }
        }
    }
});

reduxBuilder.addMutableReducer<any>(EventActionTypes.FETCH_EVENT_TRACK_ERROR, (state, payload) => {
    state.currentEventTrackData.loading = false;
    state.currentEventTrackData.error = payload;
});

reduxBuilder.addMutableReducer<any>(EventActionTypes.CLEAR_CURRENT_EVENT_TRACK, (state) => {
    state.currentEventTrackData.loading = false;
    state.currentEventTrackData.data = null;
    state.currentEventTrackData.error = null;

    state.currentTrackCandidatesData.loading = false;
    state.currentTrackCandidatesData.data = null;
    state.currentTrackCandidatesData.error = null;

    for (const track of state.eventTracksData.data || []) {
        track.currentTrack = false;
    }
});

reduxBuilder.addMutableReducer(EventActionTypes.FETCH_TRACK_CANDIDATES, (state) => {
    state.currentTrackCandidatesData.loading = true;
    state.currentTrackCandidatesData.error = null;
});

reduxBuilder.addMutableReducer<PaginatedResponse<Candidate>>(EventActionTypes.FETCH_TRACK_CANDIDATES_SUCCESS, (state, payload) => {
    state.currentTrackCandidatesData.loading = false;
    state.currentTrackCandidatesData.data = payload;
});

reduxBuilder.addMutableReducer<any>(EventActionTypes.FETCH_TRACK_CANDIDATES_ERROR, (state, payload) => {
    state.currentTrackCandidatesData.loading = false;
    state.currentTrackCandidatesData.error = payload;
});

reduxBuilder.addMutableReducer(EventActionTypes.FETCH_TRACK_SKILLS, (state) => {
    state.currentTrackSkillsData.loading = true;
    state.currentTrackSkillsData.error = null;
});

reduxBuilder.addMutableReducer<string[]>(EventActionTypes.FETCH_TRACK_SKILLS_SUCCESS, (state, payload) => {
    state.currentTrackSkillsData.loading = false;
    state.currentTrackSkillsData.data = payload;
});

reduxBuilder.addMutableReducer<any>(EventActionTypes.FETCH_TRACK_SKILLS_ERROR, (state, payload) => {
    state.currentTrackSkillsData.loading = false;
    state.currentTrackSkillsData.error = payload;
});

reduxBuilder.addMutableReducer<{ eventSlug: string, trackUuid: string, statistics: EventTrackStatistics }>(EventActionTypes.UPDATE_EVENT_TRACK_STATISTICS, (state, payload) => {
    let eventStatistic = state.eventsStatisticsData[payload.eventSlug];
    if (!eventStatistic) {
        state.eventsStatisticsData[payload.eventSlug] = eventStatistic = {};
    }

    eventStatistic[payload.trackUuid] = payload.statistics;
});

reduxBuilder.addMutableReducer<NotificationModel>(EventActionTypes.ADD_NOTIFICATION, (state, payload) => {
    let localNotifications = JSON.parse(
        window.localStorage.getItem("notifications_readed") ?? '[]') as NotificationModel[];
        
    let status = NOTIFICATION_STATUS.NOT_READ;
    if(localNotifications.length > 20) {
        localNotifications = 
            localNotifications.slice(localNotifications.length - 10, localNotifications.length);
    }
    let hasLocal = localNotifications.some(n => n.message === payload.message 
        && n.timestamp === payload.timestamp);
        
    if(localNotifications.length !== 0 && hasLocal) {
        status = localNotifications.find(n => n.message === payload.message 
            && n.timestamp === payload.timestamp)?.status ?? NOTIFICATION_STATUS.NOT_READ;
    }

    let notifications = { ...payload, status: status };
    state.notifications.push({ ...notifications });

    if(!hasLocal) {
        window.localStorage.setItem("notifications_readed", JSON.stringify([...localNotifications, notifications]));
    }
    
});

reduxBuilder.addMutableReducer<CandidateStatus>(EventActionTypes.UPDATE_CANDIDATE_STATUS, (state, payload) => {
    state.candidatesStatus[payload.id] = payload;
});

reduxBuilder.addMutableReducer<CandidateStatus>(EventActionTypes.READ_NOTIFICATIONS, (state) => {
    let localNotifications = JSON.parse(
        window.localStorage.getItem("notifications_readed") ?? '[]') as NotificationModel[];
    
    localNotifications = localNotifications.map(l => { return {...l, status: NOTIFICATION_STATUS.READ} });

    window.localStorage.setItem("notifications_readed", 
            JSON.stringify(localNotifications));
    
    if(localNotifications.length > 0) {
      state.notifications = localNotifications;
    }
});

reduxBuilder.addMutableReducer(EventActionTypes.FETCH_ORIGIN_FILTER, (state) => {
    state.originFilterData = INITIAL_STATE.originFilterData;
});

reduxBuilder.addMutableReducer<OriginFilter[]>(EventActionTypes.FETCH_ORIGIN_FILTER_SUCCESS, (state, payload) => {
    state.originFilterData = payload;
});

reduxBuilder.addMutableReducer(EventActionTypes.FETCH_ORIGIN_FILTER_ERROR, (state) => {
    state.originFilterData = INITIAL_STATE.originFilterData;
});

export default reduxBuilder.build();
