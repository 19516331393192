import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RiAddCircleLine, RiArrowLeftLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../hooks";
import useLocalStorage from "../../../hooks/use-local-storage";
import DetailModal from "./components/detail-modal";
import InstructionModal from "./components/instruction-modal";
import JobList from "./components/job-list";
import { JobBoardPageQuery } from "./job-board-page-query.model";
import { AddButton, Back, MainContainer, Title, TitleContainer } from "./job-board-page.styled";


const JobBoardPage: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation('private-home-page');
    const [query, setQuery] = useQuery<JobBoardPageQuery>();
    const [showModal, setShowModal] = useLocalStorage(
        "show-modal-job-board",
        true
    );

    const onClickCreate = useCallback(() => {
        setShowModal(false);
        history.push(`/jobs/new`);
    }, [history, setShowModal]);

    return (
        <MainContainer>
            {showModal && (
                <InstructionModal onClickCreate={onClickCreate}/>
            )}
            {query.job && (
                <DetailModal 
                    onClose={() => setQuery({ ...query, job: undefined })}
                    job_id={query.job}
                />
            )}
            <Back onClick={() => history.push(`/home/talent-match?talentPool=true`)}>
                <RiArrowLeftLine />{t('job-board.back')}
            </Back>
            <TitleContainer>
                <Title>{t('job-board.title')}</Title>
                <AddButton onClick={() => history.push(`/jobs/new`)}>
                    <RiAddCircleLine />{t('job-board.add-job')}
                </AddButton>
            </TitleContainer>
            <JobList onClickCard={(id: string) => setQuery({ ...query, job: id })}/>
        </MainContainer>
    )
}

export default JobBoardPage;