import styled from "styled-components";

interface HeadCellProps {
    width?: string;
}
export const HeadCell = styled.th<HeadCellProps>`
    width: ${({width}) => width ?? "auto"};
    block-size: fit-content;
    padding: 0.5rem;
    text-align: left;
    background-color: #452470;
    color: #fff;
    font-weight: 400;
`;