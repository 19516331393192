
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Tab from "../../../../../components/content/tab/tab";
import { useQuery } from "../../../../../hooks";
import { HomePageContent } from "../../../../../models/home-page-content.enum";
import Track from "../../../../../models/track.model";
import { AppState } from "../../../../../store/app-state";
import HomeDashboardPageQuery from "../../home-dashboard-page-query.model";
import { Container, TracksContainer } from "./tabs-menu.styled";

interface TabsMenuProps {
    onSelectedTrack?: (track: Track) => void;
    onSelectedCandidates?: () => void;
    onSelectedTalentPool?: () => void;
}

const TabsMenu: React.FC<TabsMenuProps> = ({ onSelectedTrack, onSelectedCandidates, onSelectedTalentPool }) => {
    const { t } = useTranslation('private-home-page');
    const [query] = useQuery<HomeDashboardPageQuery>();
    const { data: trackings } = useSelector((state: AppState) => state.eventState.eventTracksData);
    const content: HomePageContent = useMemo(() => {
        if (query.track) {
            return HomePageContent.TRACKING;
        }

        if (query.talentPool) {
            return HomePageContent.TALENT_POOL;
        }

        if (query.myBusiness) {
            return HomePageContent.MY_BUSINESS;
        }

        return HomePageContent.SELECTED_CANDIDATES;
    }, [query]);

    const clearFilterLocalStorage = useCallback(() => {
        let lastFilterStorage = window.localStorage.getItem('lastFilter');
        return lastFilterStorage && window.localStorage.removeItem('lastFilter');
    }, []);

    const handleTrackingTabClick = useCallback((track: Track) => {
        if (onSelectedTrack) {
            onSelectedTrack(track);
        }
        clearFilterLocalStorage();
    }, [clearFilterLocalStorage, onSelectedTrack]);

    const handleSelectedCandidatesClick = useCallback(() => {
        if (onSelectedCandidates) {
            onSelectedCandidates();
        }
        clearFilterLocalStorage();
    }, [clearFilterLocalStorage, onSelectedCandidates]);

    const handleSelectedTalentPoolClick = useCallback(() => {
        if (onSelectedTalentPool) {
            onSelectedTalentPool();
        }
        clearFilterLocalStorage();
    }, [clearFilterLocalStorage, onSelectedTalentPool]);

    return (
        <Container>
            <Tab
                title={t('private-home-page:tabs.selecteds.title')}
                isActive={content === HomePageContent.SELECTED_CANDIDATES}
                onClick={handleSelectedCandidatesClick}
            />

            <Tab
                title={t('private-home-page:tabs.talentPool.title')}
                isActive={content === HomePageContent.TALENT_POOL}
                onClick={handleSelectedTalentPoolClick}
            />

            <TracksContainer>
                {trackings && trackings.map((tracking, key) => (
                    <Tab
                    title={tracking.origin_name || tracking.name}
                    key={`${tracking.uuid}:${tracking.currentTrack}:${key}`}
                    isActive={tracking.currentTrack}
                    onClick={() => { handleTrackingTabClick(tracking) }}
                    />
                ))}
            </TracksContainer>
        </Container>
    );
}

export default TabsMenu;
