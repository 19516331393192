import i18n from "i18next";
import axios from "axios";
import { store } from "../../store/store-config";

const talentApi = axios.create({
  baseURL: process.env.REACT_APP_TALENT_API_URL,
});

talentApi.interceptors.request.use((config) => {
  const state = store.getState();
  const hasState =
    state &&
    state.authState &&
    state.authState.authenticated &&
    state.authState.token;

  if (hasState) {
    config.headers.Authorization = `Token ${state.authState.token}`;
  }

  config.headers.Language = i18n.language || "pt-BR";

  return config;
});

export default talentApi;
