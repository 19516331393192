import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
`;

export const Button = styled.button<{show: boolean}>`
    border: none;
    background: none;
    color: #b4a7c5;
    font-size: 2rem;
    visibility: ${({show}) => show ? 'visible' : 'hidden'};
    
    &:hover {
        color: #57397e;
    }
`;