import { setLightness } from 'polished';
import styled, { DefaultTheme } from 'styled-components';

interface ContainerProps {
    isActive?: boolean;
    color: keyof DefaultTheme['colors'] | 'white';
}

export const Container = styled.button<ContainerProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1.25rem;
    margin: .25rem;
    height: 2.5rem;
    white-space: nowrap;
    border: none;
    border-radius: .5rem;
    background-color: ${({ theme, color, isActive }) => isActive
        ? color === 'white' ? '#fff' : theme.colors[color].main
        : setLightness(.65, theme.typography.regular.color)};
    transition: color .2s;
    
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 1rem;
    color: ${({ theme, color }) => color === 'white' ? '#fff' : theme.colors[color].text};

    &:hover, &:focus, &:active {
        background-color: ${({ theme, color }) => color === 'white' ? '#fff' : theme.colors[color].main};
    }
`;
