import React, { Fragment } from "react";
import Backdrop from "../backdrop/backdrop";
import {
    Button,
    ButtonContainer,
    Menu,
    MenuDiv,
    MenuItem,
    MenuItemClick
} from "./index.styled";

export interface MenuOption {
  option: JSX.Element;
  onClick: (e?: any) => void;
}

interface MenuCrossButtonInterface {
  isOpen: boolean;
  onClick: () => void;
  options: MenuOption[];
}
const MenuCrossButton: React.FC<MenuCrossButtonInterface> = ({
  isOpen,
  onClick,
  options,
}) => {
  return (
    <ButtonContainer onClick={onClick}>
      <Button className={isOpen ? "cross" : "bar"} />
      {isOpen && (
        <Backdrop toggle={onClick} isShown={isOpen} zIndex={100} transparent />
      )}
      <Menu open={isOpen}>
        {!!options &&
          options.map((o, i) => (
            <Fragment key={`menu-item-${i}`}>
              {i === options.length - 1 && <MenuDiv />}
              <MenuItem>
                <MenuItemClick onClick={o.onClick}>{o.option}</MenuItemClick>
              </MenuItem>
            </Fragment>
          ))}
      </Menu>
    </ButtonContainer>
  );
};

export default MenuCrossButton;
