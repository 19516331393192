import { RiCloseFill } from "react-icons/ri";
import { Backdrop, ButtonContainer, CancelButton, CloseButton, Container, Description, SendButton, Title } from "./index.styled";

interface ModalMatchProps {
    onClick: () => void;
    onClose: () => void;
}

const ModalMatch: React.FC<ModalMatchProps> = ({ onClick, onClose }) => {
    return (<>
        <Backdrop onClick={onClose}/>
        <Container>
            <CloseButton onClick={onClose}><RiCloseFill /></CloseButton>
            <Title>Você está prestes a dar um match!</Title>
            <Description>O candidato irá receber um e-mail comunicando seu interesse em continuar com ele no processo seletivo. Deseja continuar?</Description>
            <ButtonContainer>
                <CancelButton onClick={onClose}>Cancelar</CancelButton>
                <SendButton onClick={onClick}>Enviar</SendButton>
            </ButtonContainer>
        </Container>
        </>);
}

export default ModalMatch;