import { Button, Container } from "./index.styled";
import { RiArrowLeftCircleLine, RiArrowRightCircleLine } from "react-icons/ri";

interface SliderProps {
    onPrev: () => void;
    onNext: () => void;
    showPrev: boolean;
    showNext: boolean;
}
const Slider: React.FC<SliderProps> = ({ onPrev, onNext, showPrev, showNext, children }) => {
    return (
        <Container>
            <Button show={showPrev} onClick={onPrev}>
                <RiArrowLeftCircleLine />
            </Button>
            {children}
            <Button show={showNext} onClick={onNext}>
                <RiArrowRightCircleLine />
            </Button>
        </Container>
    );
}

export default Slider;