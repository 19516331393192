import { combineReducers } from "redux";
import applicationState from "./application/application-reducer";
import authState from "./auth/auth-reducer";
import candidateState from "./candidate/candidate-reducer";
import companyState from "./company/company-reducer";
import eventState from "./event/event-reducer";
import jobBoardState from "./job-board/job-board-reducer";
import loadState from "./load/load-reducer";
import signInState from "./sign-in/sign-in-reducer";
import tabChangedState from "./tab-changed/tab-changed-reducer";

const reducers = combineReducers({
  authState,
  signInState,
  eventState,
  candidateState,
  companyState,
  tabChangedState,
  loadState,
  jobBoardState,
  applicationState,
});

export default reducers;
