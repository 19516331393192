
import InvoiceExtract from '../current-invoice/invoice-extract/invoice-extract';
import { Container } from './invoice-history.styled';

const InvoiceHistory: React.FC = () => {
    return (
        <Container>
            <InvoiceExtract />
        </Container>
    )
}

export default InvoiceHistory;
