import { AxiosResponse } from "axios";
import CandidateAction from "../../models/candidate-action.model";
import { CandidateDetail } from "../../models/candidate-detail.model";
import Plan from "../../models/plan.model";
import { Candidate } from "./../../models/candidate.model";
import { CandidateQuery } from "./models/candidates-query.model";
import PaginatedResponse from "./models/paginated-response.model";
import API from "./talent-api-config";

class TalentApi {
  async fetchTalentPlan(): Promise<AxiosResponse<Plan>> {
    return API.get(`/talent/pool/plan/`);
  }

  async fetchTalent(
    talentId: string,
    trackId?: string
  ): Promise<AxiosResponse<CandidateDetail>> {
    if (!trackId) {
      return API.get(`/talent/pool/user/${talentId}/`);
    }

    return API.get(`/talent/track/${trackId}/candidate/${talentId}/`);
  }

  async fetchDeclineCandidateReasons(): Promise<AxiosResponse> {
    return API.get(`/candidate-reasons/`);
  }

  async sendAction(
    candidateAction: CandidateAction,
    talentId: string,
    trackId?: string
  ): Promise<AxiosResponse<CandidateDetail>> {
    if (!trackId) {
      return API.post(`/talent/pool/user/${talentId}/`, candidateAction);
    }
    return API.post(
      `/talent/track/${trackId}/candidate/${talentId}/`,
      candidateAction
    );
  }

  async fetchCandidatePool(
    ecosystemId: string,
    query?: CandidateQuery
  ): Promise<AxiosResponse<PaginatedResponse<Candidate>>> {
    const page = query?.candidatePoolPage
      ? parseInt(query?.candidatePoolPage)
      : 1;
    const page_size = query?.pageSize || 10;
    const params = {
      ...query,
      page_size: page_size,
      pageSize: undefined,
      page,
    };
    const response = await API.get<PaginatedResponse<Candidate>>(
      `/talent/pool/ecosystem/${ecosystemId}/`,
      { params }
    );
    response.data.current_page = page;
    response.data.page_size = page_size;

    if (response.data?.results) {
      for (let i = 0; i < response.data?.results.length; i++) {
        const candidate = response.data?.results[i];
        candidate.is_pool = true;
      }
    }

    return response;
  }

  async fetchSkillsOfCandidatePool(
    ecosystemId: string,
    query?: CandidateQuery
  ): Promise<AxiosResponse<string[]>> {
    const page = query?.candidatePoolPage
      ? parseInt(query?.candidatePoolPage)
      : 1;
    const params = { page_size: 10, page };
    return await API.get<string[]>(
      `/talent/pool/ecosystem/${ecosystemId}/skills/`,
      { params }
    );
  }

  async fetchProfessionalMoment(): Promise<AxiosResponse<string[]>> {
    return await API.get<string[]>(`/professional-moments/`);
  }
}

export const talentApi = new TalentApi();
