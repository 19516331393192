import styled from "styled-components";

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 200vw;
    height: 200vh;
    z-index: 500;
    overflow: hidden;

    transform: translate(-25%, -25%);

    background: rgba(69, 36, 112, 0.7);
    backdrop-filter: blur(2px);
`;

export const Container = styled.div`
    position: fixed;
    top: 12.5rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 45rem;
    height: fit-content;

    display: flex;
    flex-direction: column;
    padding: 2rem;
    padding-top: 3.25rem;
    
    background-color: #fff;
    border-radius: 1rem;
    z-index: 1000;
`;

const TextModel = styled.span`
    font-family: 'Open Sans';
    font-size: 1.125rem;
`;

export const Title = styled(TextModel)`
    font-weight: 700;
    color: #452470;
    margin-bottom: 0.75rem;
`;

export const Description = styled(TextModel)`
    color: #000;
`;

export const ButtonContainer = styled.div`
    margin-top: 2.8rem;
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    gap: 1rem;
`;

const ButtonModel = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7.3rem;
    height: 2.3rem;
    border-radius: 0.25rem;
    border: none;
    font-size: 0.875rem;
    font-weight: 700;
`;

export const SendButton = styled(ButtonModel)`
    background-color: #452470;
    color: #fff;
`;

export const CancelButton = styled(ButtonModel)`
    background-color: transparent;
    border: 1px solid #BF76AE;
    color: #BF76AE;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    border: none;
    color: #452470;
    font-size: 1.5rem;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
`;