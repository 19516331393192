
import styled from 'styled-components';
import LanguageSelection from '../../../../../components/language-selection';

export const Container = styled.div`
    position: relative;
`;

export const Banner = styled.img`
    width: 100%;
`;

export const LogoDio = styled.img`
    position: absolute;
    top: 10%;
    left: 10%;
    width: 180px;
`;

export const Languages = styled(LanguageSelection)`
    position: absolute;
    top: .5rem;
    right: .5rem;
`;