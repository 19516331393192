import styled from 'styled-components';

export const Container = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .email {
        width: 100%;
        margin: 1rem 0;
    }
`;

export const Title = styled.p`
    font-size: 1rem;
    text-align: center;
    margin: 0;
`;