import { differenceInDays } from "date-fns";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { JobListInterface } from "../../../../job-board-form.model";
import { Attribute, AttributeRow, ClosedJob, Container, DateRow, Name, New } from "./index.styled";

interface CardProps {
    job: JobListInterface;
    onClick: () => void;
}
const Card: React.FC<CardProps> = ({ job, onClick }) => {
    const { t } = useTranslation('private-home-page');

    const remainingDays = useMemo(() => {
        if(!job.end_date){
            return null;
        }
        const end_date = new Date(job.end_date);
        return differenceInDays(end_date, new Date());
    }, [job.end_date]);

    const newJob = useMemo(() => {
        return job.created_humanize === 'agora';
    }, [job]);

    const handleOnClick = useCallback(() => {
        if(job.active){
            onClick();
        }
    }, [job, onClick]);

    return (
        <Container closed={!job.active} onClick={handleOnClick}>
            {(newJob && !job.active) && (<New>New</New>)}
            <Name>{job.name}</Name>
            {job.location && (<Attribute>{job.location.name}</Attribute>)}
            <AttributeRow>
                <Attribute>{job.language.name}</Attribute>
                <Attribute>{job.currency}</Attribute>
                <Attribute>
                    {t(`job-board.form-fields.work_models.${job.work_model.toLowerCase()}`)}
                </Attribute>
                <Attribute>{job.hire_type.name}</Attribute>
            </AttributeRow>
            <DateRow>
                <Attribute>{job.created_humanize.replace('faz', '')}</Attribute>
                {job.active ? (
                    !!job.end_date && <Attribute>{t('job-board.card.remaining-days', {days: remainingDays})}</Attribute>
                ) : (
                    <ClosedJob>{t('job-board.card.closed-job')}</ClosedJob>
                )}
            </DateRow>
        </Container>
    );
}

export default Card;