import styled from "styled-components";

export const FormContainer = styled.div`
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border: 1px solid #452470;
    border-radius: 0.5rem;
    background-color: #fffa;
`;

export const FormRow = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

export const TextFieldContent = styled.div`
    margin-bottom: 1.75rem;
    position: relative;
    width: 24%;
`;

export const TitleFieldContent = styled(TextFieldContent)`
    width: 49.5%;
`;

export const Title = styled.p`
  margin: .5rem 0 .5rem 0;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary.main};
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const Range = styled.div`
    display: flex;
    align-items: center;
`;

export const Line = styled.div`
    align-items: center;
    background: #D9D9D9;
    display: flex;
    width: 1.9rem;
    height: 0.063rem;
    margin-left: .3rem;
    margin-right: .3rem;
`;

export const DateInput = styled.input`
    background: #FFFFFF;
    border-radius: 0.3125rem;
    border: 1px solid #D9D9D9;
    height: 2.125rem;
    width: 100%;
    padding: 0 1rem;

    &:focus {
        color: #452470;
        border: 1px solid #452470;
    }
`;

export const DateFieldContent = styled(TextFieldContent)`

    .calender-icon {
        position: absolute;
        right: 1rem;
        bottom: 0.4rem;
        margin: 0;
        padding: 0;
        width: 1.25rem;
        height: 1.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #452470;
    }

    .date-popper {
        .past {
            color: #aaa !important;
            pointer-events: none;
        }

        width: 100%;
        .react-datepicker {
            width: 100%;
            border: 1px solid #D9D9D9;
            border-radius: 0.3125rem;
            
            .react-datepicker__month-container {
                width: 100%;
                .react-datepicker__header {
                    background-color: #452470;
    
                    * {
                        color: #fff;
                    }

                    .react-datepicker__current-month {
                        text-transform: capitalize;
                    }
                }
                
                .react-datepicker__month {
                    .react-datepicker__day {
                        color: #452470;

                        &:hover,  {
                            background-color: #45247055;
                        }
                    }
                    
                    .react-datepicker__day--keyboard-selected {
                        background-color: #45247055;
                    }

                    .react-datepicker__day--selected {
                        background-color: #452470;
                        color: #fff;
                    }
                }
            }
        }
    }
`;

export const DescriptionFieldContent = styled.div`
    width: 49.5%;

    .quill {
        .ql-toolbar {
            background: #452470;
            border: none;
            border-start-start-radius: 0.5rem;
            border-start-end-radius: 0.5rem;
            .ql-picker-item {
                color: #452470;
            }

            *, .ql-active {
                color: #eee;
                &:hover {
                    color: #fff;
                }

                svg {
                    filter: brightness(0%) invert(90%);

                    &:hover {
                        filter: brightness(0%) invert(100%);
                    }
                }
            }
        }

        .ql-container {
            min-height: 5rem;
            border-end-start-radius: 0.5rem;
            border-end-end-radius: 0.5rem;

            .ql-editor {
                h1, h2, h3 {
                    color: #452470;
                }

                p {
                    color: #222;
                }
            }
        }
    }
`;

export const SubmitButton = styled.button`
    margin-top: 1rem;
    align-self: flex-end;
    border: none;
    border-radius: 0.5rem;
    background-color: #452470;
    color: #fff;
    padding: 0.75rem 1.25rem;
    width: fit-content;
    height: auto;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    svg {
        font-size: 1.25rem;
    }
    
    &:hover {
        background-color: #6A4F8C;
    }

    &:disabled {
        background: transparent;
        border: 1px solid #452470;
        color: #452470;
        cursor: default;
    }
`;