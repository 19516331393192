
import { AxiosResponse } from 'axios';
import Diversity from '../../models/diversity.model';
import talentApi from './talent-api-config';

class DiversitiesApi {

    async fetchDiversitiesData(): Promise<AxiosResponse<Diversity[]>> {
        return talentApi.get<Diversity[]>(`/diversities/`);
    }

}

export const diversitiesApi = new DiversitiesApi();
