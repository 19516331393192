import styled from 'styled-components';
import Wrapper from '../../components/modal/modal';


const LayoutCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Modal = styled(Wrapper)`
    height: fit-content;
    margin-top: 100px;

    h3{
        color: ${({ theme }) => theme.colors.primary.main};
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
    }
`;

export const Message = styled.p`
    font-weight: 400;
    font-size: 1rem;
    color: #434343;
`;

export const Buttons = styled(LayoutCenter)`
    button {
        margin: 0 10px;
    }
`;

export const Footer = styled(LayoutCenter)``;

export const Logo = styled.img`
width: 60px;
margin: 2rem 0;
`;