import { JobOfferPayload } from "../../../../../../../models/candidate-action.model";
import { jobOfferStatusTypes } from "../../../../../../../models/candidate.model";
import { LastActionStatus } from "../../../../../../../models/last-action-status.enum";

interface SchemaStyled {
  background: string;
  border: string;
  color: string;
  title: string;
}

interface SelectedSchemaProps {
  [key: string]: SchemaStyled;
}

export interface SelectedStatusProps {
  talentStatus: {
    job_offer?: {
      status: jobOfferStatusTypes;
      metadata: JobOfferPayload;
    };
    last_action?: {
      status: LastActionStatus;
    };
  };
}

export const selectedSchema: SelectedSchemaProps = {
  hired: {
    background: "#E5FDED",
    border: "#B6E6C3",
    color: "#499f68",
    title: "private-home-page:actions.hired",
  },
  not_approved: {
    background: "#FFF2F0",
    border: "#FFBEBE",
    color: "#F11C1C",
    title: "private-home-page:actions.not_approved",
  },
  deny_job_offer: {
    background: "#FFEFF7",
    border: "#F9B4CD",
    color: "#C9678A",
    title: "private-home-page:actions.deny_job_offer",
  },
  not_interviewed: {
    background: "#FAF0FF",
    border: "#FABEFF",
    color: "#E26BC8",
    title: "private-home-page:actions.not_interviewed",
  },
  pending: {
    background: "#e7efff",
    border: "#cadcff",
    color: "#0c5dff",
    title: "private-home-page:jobOffer.status.pending",
  },
  match: {
    background: "#F5EDFF",
    border: "#E2D0F8",
    color: "#452470",
    title: "private-home-page:jobOffer.status.match",
  },
  rejected: {
    background: "#FFF9F0",
    border: "#FFE8BE",
    color: "#E2B76B",
    title: "private-home-page:jobOffer.status.rejected",
  },
};
