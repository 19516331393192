import { fetchCompanyPlan } from "./../company/company-actions";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { action } from "typesafe-actions";
import { CandidateQuery } from "../../api/talent/models/candidates-query.model";
import CandidateAction from "../../models/candidate-action.model";
import { PlanType } from "../../models/plan-type.enum";
import { CompanyActionTypes } from "../company/company-types";
import { diversitiesApi } from "./../../api/talent/diversities-services";
import { ecosystemApi } from "./../../api/talent/ecosystem-services";
import { talentApi } from "./../../api/talent/talent-service";
import { AppState } from "./../app-state";
import { CandidateActionTypes } from "./candidate-types";
import { JobOfferPayload } from "../../models/candidate-action.model";

export interface UnlockCandidate {
  talentId: string;
  trackId?: string;
  metadata?: {
    job_offer: JobOfferPayload;
  };
}

export const clearCandidateDetail = () =>
  action(CandidateActionTypes.CLEAR_CANDIDATE_DETAIL);

export const fetchCandidate = (talentId: string, trackId: string) => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(action(CandidateActionTypes.FETCH_CANDIDATE));
    try {
      const response = await talentApi.fetchTalent(talentId, trackId);
      dispatch(
        action(CandidateActionTypes.FETCH_CANDIDATE_SUCCESS, response.data)
      );
    } catch (error) {
      dispatch(action(CandidateActionTypes.FETCH_CANDIDATE_ERROR, error));
    }
  };
};

export const notifyOpenCandidateDetail = (
  talentId: string,
  trackId: string
) => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(action(CandidateActionTypes.NOTIFY_OPEN_CANDIDATE_DETAIL));

    try {
      const payload: CandidateAction = { action: "open" };
      const response = await talentApi.sendAction(payload, talentId, trackId);
      dispatch(
        action(
          CandidateActionTypes.NOTIFY_OPEN_CANDIDATE_DETAIL_SUCCESS,
          response.data
        )
      );
    } catch (error) {
      dispatch(
        action(CandidateActionTypes.NOTIFY_OPEN_CANDIDATE_DETAIL_ERROR, error)
      );
    }
  };
};

export const checkPermissionToUnlockCandidateVertical = () => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(action(CandidateActionTypes.UNLOCK_CANDIDATE));

    const response = await talentApi.fetchTalentPlan();

    // Updating company plan
    dispatch(
      action(CompanyActionTypes.FETCH_COMPANY_PLAN_SUCCESS, response.data)
    );

    return dispatch(
      action(CandidateActionTypes.UNLOCK_CANDIDATE_CONFIRM_NOTIFY, true)
    );
  };
};

export const checkPermissionToUnlockCandidate = (ecosystem?: string) => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(action(CandidateActionTypes.UNLOCK_CANDIDATE));

    const response = await talentApi.fetchTalentPlan();

    // Updating company plan
    dispatch(
      action(CompanyActionTypes.FETCH_COMPANY_PLAN_SUCCESS, response.data)
    );

    const planData = response.data;
    const isSponsor = planData.plan === PlanType.SPONSOR;

    if (!isSponsor) {
      const isBasic = planData.plan === PlanType.BASIC;

      if (isBasic) {
        return dispatch(
          action(CandidateActionTypes.UNLOCK_CANDIDATE_NEED_PERMISSION, true)
        );
      } else {
        const hasLeed = (planData.total_leed || 0) > (planData.used_leed || 0);
        if (!hasLeed) {
          return dispatch(
            action(CandidateActionTypes.UNLOCK_CANDIDATE_NEED_LEED, true)
          );
        }
        if (ecosystem) {
          const hasEcosystem = planData.ecosystems?.includes(ecosystem);
          if (!hasEcosystem) {
            return dispatch(
              action(CandidateActionTypes.UNLOCK_CANDIDATE_NEED_ECOSYSTEM, true)
            );
          }
        }
      }
    }

    return dispatch(
      action(CandidateActionTypes.UNLOCK_CANDIDATE_CONFIRM_NOTIFY, true)
    );
  };
};

export const confirmPermissionToUnlockCandidate = (confirm: boolean) => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(
      action(
        CandidateActionTypes.UNLOCK_CANDIDATE_CONFIRM_PERMISSION_TO_UNLOCK,
        confirm
      )
    );

    if (confirm) {
      window.Intercom(
        "showNewMessage",
        `Olá, gostaria de realizar assinatura na Talent Match para contratar os melhores talentos do mercado para a minha empresa`
      );
    }
  };
};

export const confirmLeedRequest = (confirm: boolean) => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(
      action(
        CandidateActionTypes.UNLOCK_CANDIDATE_CONFIRM_PERMISSION_TO_UNLOCK,
        confirm
      )
    );

    if (confirm) {
      window.Intercom(
        "showNewMessage",
        `Olá, gostaria de solicitar mais créditos de contatos`
      );
    }
  };
};

export const confirmEcosystemAuthorizationRequest = (confirm: boolean) => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(
      action(
        CandidateActionTypes.UNLOCK_CANDIDATE_CONFIRM_PERMISSION_TO_UNLOCK,
        confirm
      )
    );

    if (confirm) {
      window.Intercom(
        "showNewMessage",
        `Olá, gostaria de solicitar acesso a um novo ecossistema`
      );
    }
  };
};

export const confirmNotifyToUnlockCandidate = () => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(
      action(CandidateActionTypes.UNLOCK_CANDIDATE_CONFIRM_NOTIFY, false)
    );
  };
};

export const createJobOffer = (jobOffer: JobOfferPayload) => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(action(CandidateActionTypes.JOB_OFFER, jobOffer));
  };
};

export const unlockCandidate = ({
  talentId,
  trackId,
  metadata,
}: UnlockCandidate) => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(action(CandidateActionTypes.UNLOCK_CANDIDATE));
    try {
      const payload: CandidateAction = { action: "interview" };

      if (metadata) {
        payload["metadata"] = metadata;
      }
      const response = await talentApi.sendAction(payload, talentId, trackId);

      dispatch(
        action(CandidateActionTypes.UNLOCK_CANDIDATE_SUCCESS, response.data)
      );

      // Updating leed
      dispatch(fetchCompanyPlan());
    } catch (error) {
      dispatch(action(CandidateActionTypes.UNLOCK_CANDIDATE_ERROR, error));
    }
  };
};

export const declineCandidate = (talentId: string, trackId: string) => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(action(CandidateActionTypes.DECLINE_CANDIDATE));

    try {
      const payload: CandidateAction = { action: "not_approved" };
      const response = await talentApi.sendAction(payload, talentId, trackId);
      dispatch(
        action(CandidateActionTypes.DECLINE_CANDIDATE_SUCCESS, response.data)
      );
    } catch (error) {
      dispatch(action(CandidateActionTypes.DECLINE_CANDIDATE_ERROR, error));
    }
  };
};

export const candidateNotInterviewed = (talentId: string, trackId: string) => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(action(CandidateActionTypes.CANDIDATE_NOT_INTERVIEWED));

    try {
      const payload: CandidateAction = { action: "not_interviewed" };
      const response = await talentApi.sendAction(payload, talentId, trackId);
      dispatch(
        action(
          CandidateActionTypes.CANDIDATE_NOT_INTERVIEWED_SUCCESS,
          response.data
        )
      );
    } catch (error) {
      dispatch(
        action(CandidateActionTypes.CANDIDATE_NOT_INTERVIEWED_ERROR, error)
      );
    }
  };
};

export const candidateHired = (talentId: string, trackId: string) => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(action(CandidateActionTypes.CANDIDATE_HIRED));

    try {
      const payload: CandidateAction = { action: "hired" };
      const response = await talentApi.sendAction(payload, talentId, trackId);
      dispatch(
        action(CandidateActionTypes.CANDIDATE_HIRED_SUCCESS, response.data)
      );
    } catch (error) {
      dispatch(action(CandidateActionTypes.CANDIDATE_HIRED_ERROR, error));
    }
  };
};

export const denyJobOffer = (
  talentId: string,
  trackId: string,
  reasonId: string
) => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(action(CandidateActionTypes.DENY_JOB_OFFER));

    try {
      const payload: CandidateAction = {
        action: "deny_job_offer",
        reason_uuid: reasonId,
      };
      const response = await talentApi.sendAction(payload, talentId, trackId);
      dispatch(
        action(CandidateActionTypes.DENY_JOB_OFFER_SUCCESS, response.data)
      );
    } catch (error) {
      dispatch(action(CandidateActionTypes.DENY_JOB_OFFER_ERROR, error));
    }
  };
};

export const fetchDeclineCandidateReasons = () => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(action(CandidateActionTypes.FETCH_DECLINE_CANDIDATE_REASONS));

    try {
      const response = await talentApi.fetchDeclineCandidateReasons();
      dispatch(
        action(
          CandidateActionTypes.FETCH_DECLINE_CANDIDATE_REASONS_SUCCESS,
          response.data
        )
      );
    } catch (error) {
      dispatch(
        action(
          CandidateActionTypes.FETCH_DECLINE_CANDIDATE_REASONS_ERROR,
          error
        )
      );
    }
  };
};

export const fetchCandidateDiversities = () => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(action(CandidateActionTypes.FETCH_CANDIDATE_DIVERSITIES));

    try {
      const response = await diversitiesApi.fetchDiversitiesData();
      dispatch(
        action(
          CandidateActionTypes.FETCH_CANDIDATE_DIVERSITIES_SUCCESS,
          response.data
        )
      );
    } catch (error) {
      dispatch(
        action(CandidateActionTypes.FETCH_CANDIDATE_DIVERSITIES_ERROR, error)
      );
    }
  };
};

export const fetchCandidateEcosystems = () => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(action(CandidateActionTypes.FETCH_CANDIDATE_ECOSYSTEMS));

    try {
      const response = await ecosystemApi.fetchEcosystemsData();
      dispatch(
        action(
          CandidateActionTypes.FETCH_CANDIDATE_ECOSYSTEMS_SUCCESS,
          response.data
        )
      );
    } catch (error) {
      dispatch(
        action(CandidateActionTypes.FETCH_CANDIDATE_ECOSYSTEMS_ERROR, error)
      );
    }
  };
};

export const fetchCandidatePool = (
  ecosystemId: string,
  query: CandidateQuery
) => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(action(CandidateActionTypes.FETCH_CANDIDATE_POOL));
    try {
      const response = await talentApi.fetchCandidatePool(ecosystemId, query);
      dispatch(
        action(CandidateActionTypes.FETCH_CANDIDATE_POOL_SUCCESS, response.data)
      );
    } catch (error) {
      dispatch(action(CandidateActionTypes.FETCH_CANDIDATE_POOL_ERROR, error));
    }
  };
};

export const fetchSkillsOfCandidatePool = (
  ecosystemId: string,
  query: CandidateQuery
) => {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch(action(CandidateActionTypes.FETCH_CANDIDATE_POOL_SKILLS));
    try {
      const response = await talentApi.fetchSkillsOfCandidatePool(
        ecosystemId,
        query
      );
      const sortedData = response.data.sort();
      dispatch(
        action(
          CandidateActionTypes.FETCH_CANDIDATE_POOL_SUCCESS_SKILLS,
          sortedData
        )
      );
    } catch (error) {
      dispatch(
        action(CandidateActionTypes.FETCH_CANDIDATE_POOL_SKILLS_ERROR, error)
      );
    }
  };
};

