import { format, toDate } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { CandidateDetail } from "../../../../models/candidate-detail.model";
import { getLocale } from "../../../../utils/redux/date";
import { SectionBlock, Text } from "../../talent.styled";
import { BulletTitle, TitleAbout } from "../about/about.styled";

interface ProfessionalExperienceProps {
  candidate: CandidateDetail;
}

const ProfessionalExperience: React.FC<ProfessionalExperienceProps> = ({
  candidate,
}) => {
  const { t } = useTranslation("private-home-page");
  return (
    <SectionBlock>
      <TitleAbout>{t("talent.professionalExperiences")}</TitleAbout>

      {candidate?.professional_experience.map((exp) => (
        <div key={exp.id}>
          <BulletTitle>{exp.company_name}</BulletTitle>
          <Text>
            <strong>{exp.office}
              {" ("}{`${format(toDate(Date.parse(exp.start_date)), "MMMM yyyy", {
                locale: getLocale(),
              })} -
            ${exp.exit_date
                  ? format(toDate(Date.parse(exp.exit_date)), "MMMM yyyy", {
                    locale: getLocale(),
                  })
                  : t("talent.currentJob")
                }`} {")"}</strong>
          </Text>

          <Text>{exp.description}</Text>
        </div>
      ))}
    </SectionBlock>
  );
};

export default ProfessionalExperience;
