import { useCallback, useEffect, useState } from "react";
import { Transition, useSpring } from "react-spring";
import { DropdownItem, DropdownMenu, DropdownSpan, FilterButton, FilterContainer, FilterLabel } from "./index.styled";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import Backdrop from "../../../../../../../components/backdrop/backdrop";
import { useTranslation } from "react-i18next";
import { IoMdCheckmark } from "react-icons/io";

export interface FilterOption {
    value: string;
    action: () => void;
};

interface TableFilterProps {
    options: FilterOption[];
    label?: string;
    initial?: FilterOption;
    i18n_item?: boolean;
}
const TableFilter: React.FC<TableFilterProps> = ({ options, label, initial, i18n_item=true }) => {
    const { t } = useTranslation("private-home-page");
    const root = (key: string) => t(`ranking-table.filters.${key}`);

    const [dropdown, setDropdown] = useState<boolean>(false);
    const [current, setCurrent] = useState<FilterOption>(options[0]);
    const styledMenu = useSpring({
        transform: dropdown ? "translate3D(0,25px,0)" : "translate3D(0,-40px,0)",
        opacity: dropdown ? 1 : 0,
    });

    const handleDropdownSelect = useCallback(
    (item: FilterOption) => {
        setCurrent(item);
        setDropdown(false);
        item.action();
    }, []);

    useEffect(() => {
        if(initial && current && initial.value !== current.value){
            setCurrent(initial);
        }
    }, [current, initial]);

    return (
    <FilterContainer>
        <FilterButton onClick={() => setDropdown((prev) => !prev)}>
            {root(label || "filter-by")}
            <FilterLabel>{i18n_item ? root(current.value) : current.value}</FilterLabel>
            {dropdown ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
        </FilterButton>
        {dropdown && (
            <Backdrop
            toggle={() => setDropdown(false)}
            isShown={dropdown}
            zIndex={100}
            transparent
            />
        )}
        <Transition items={dropdown}>
            {(styles, item) =>
                item && (
                    <DropdownMenu style={styledMenu} isShown={dropdown}>
                    {options.map((item) => (
                        <DropdownItem
                            key={item.value}
                            onClick={() => handleDropdownSelect(item)}
                            isActive={item.value === current.value}
                        >
                            <DropdownSpan>{i18n_item ? root(item.value) : item.value}</DropdownSpan>
                            <IoMdCheckmark />
                        </DropdownItem>
                    ))}
                    </DropdownMenu>
                )
            }
        </Transition>
    </FilterContainer>
    );
};

export default TableFilter;