import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/app-state";
import { Container } from "./footer.styled";

const Footer: React.FC = () => {
    const { eventData } = useSelector((state: AppState) => state.eventState);
    const logo = eventData.data?.image_footer || "https://hermes.dio.me/talent/event/cdf22254-c17d-4a0a-99fc-88e5d91fec16.png";

    return (
        <Container>
            <img src={logo} alt="Talent Match" />
            <img src="https://hermes.digitalinnovation.one/assets/diome/logo.svg" alt="DIO" className="logo-dio" />
        </Container>
    );
}

export default Footer;