import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineSearch } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../../../components/content/pagination/pagination';
import Modal from '../../../../../components/modal/modal';
import Talent from '../../../../../components/talent/talent';
import { useModal, useQuery } from '../../../../../hooks';
import useCandidateStatus from '../../../../../hooks/use-candidate-status';
import { Candidate } from '../../../../../models/candidate.model';
import { OriginType } from '../../../../../models/track-type.enum';
import { AppState } from '../../../../../store/app-state';
import { clearCandidateDetail } from '../../../../../store/candidate/candidate-actions';
import { fetchOriginFilter, fetchSelectedCandidates, fetchTrackCandidates } from '../../../../../store/event/event-actions';
import { fetchSetInvisible, fetchSetVisible } from '../../../../../store/load/load-actions';
import HomeDashboardPageQuery from '../../home-dashboard-page-query.model';
import FilterForm from '../filter-form/filter-form';
import Header from '../header/header';
import Table from '../table';
import TableCounter from '../table/components/counter';
import TableFilter, { FilterOption } from '../table/components/filter';
import { HeaderItem } from '../table/components/header';
import SelectedStatus from '../table/components/selected-status';
import { DataCell, FilterContainer, InfoTableContainer, TableRow } from '../table/index.styled';
import { getCandidateSalary } from '../table/utils/table.utils';
import { CandidatesNotFound, Container, Content, ViewDesktopFilters } from './selected-candidates-content.styled';

const DEFAULT_ECOSYSTEM = { id: 'ad63a367-a5e5-47b6-a9bf-0a6dfb5fe1ff', name: 'Java' };
const modalTalent = document.getElementById("modal-talent");

interface SelectedCandidatesContentProps {
    eventSlug: string;
}

const SelectedCandidatesContent: React.FC<SelectedCandidatesContentProps> = ({ eventSlug }) => {
    const { t } = useTranslation('private-home-page');
    const dispatch = useDispatch();
    const [query, setQuery] = useQuery<HomeDashboardPageQuery>();
    const user = useSelector((state: AppState) => state.authState.user);
    const devTableRef = useRef<HTMLDivElement>(null);
    const { selectedCandidatesData } = useSelector((state: AppState) => state.eventState);
    const { isShown, toggle } = useModal();
    const [candidate, setCandidate] = useState<Candidate>();
    const candidateStatus = useCandidateStatus({ candidateId: candidate?.id || '' });
    const { currentEventTrackData, originFilterData } = useSelector(
        (state: AppState) => state.eventState
    );

    useEffect(() => {
        if(!selectedCandidatesData.loading) {
            dispatch(fetchSetInvisible());
        }
    }, [selectedCandidatesData.loading, dispatch])

    useEffect(() => {
        if (eventSlug && (query.selectedCandidates && (!query.track && !query.talentPool))) {
            dispatch(fetchSelectedCandidates(eventSlug, query));
            dispatch(fetchSetVisible());
        }
    }, [eventSlug, query, dispatch]);

    useEffect(() => {
        if(eventSlug){
            dispatch(fetchOriginFilter(eventSlug));
        }
    }, [eventSlug, dispatch]);

    const handlePageChange = useCallback((page: number) => {
        query.selectedCandidatesPage = page.toString();
        setQuery(query);

        if (devTableRef.current) {
            window.scrollTo({
                behavior: 'smooth',
                top: devTableRef.current.offsetTop,
            });
        }
    }, [query, setQuery, devTableRef]);

    const handleOpen = useCallback(() => {
        toggle();
    }, [toggle]);

    const handleClose = () => {
        if (currentEventTrackData.data?.register) {
            // TODO: Se possível toda atualização vier pelo realtime também
            dispatch(fetchTrackCandidates(currentEventTrackData.data.uuid, query));
        }
        dispatch(clearCandidateDetail());
    };

    const handleModal = useCallback((candidate: Candidate, candidateStatus) => {
        (!candidateStatus?.hired ||
            candidateStatus?.hired === user?.corporate.id ||
            candidate.company_hired === user?.corporate.id) &&
            handleOpen();
    }, [handleOpen, user]);

    const filterOrigins: FilterOption[] = useMemo(() => {
        return [
            {
                value: t("ranking-table.filters.all"),
                action: () => setQuery({ ...query, origin: undefined })
            },
            {
                value: t("ranking-table.filters.ranking"),
                action: () => setQuery({ ...query, origin: 'ranking' })
            },
            ...originFilterData.map((origin): FilterOption => ({
                value: origin.name,
                action: () => setQuery({ ...query, origin: origin.id })
            }))
        ]
    }, [originFilterData, query, setQuery, t]);

    const originFilterInitial: FilterOption = useMemo(() => {
        if(!query.origin || query.origin === undefined){
            return filterOrigins[0];
        }
        if(query.origin === 'ranking'){
            return filterOrigins[1];
        }
        const origin = originFilterData.find((origin) => origin.id === query.origin);
        if(!origin){
            return filterOrigins[0]; 
        }
        return {
            value: origin.name,
            action: () => setQuery({ ...query, origin: origin.id })
        };
    }, [query, originFilterData, filterOrigins, setQuery]);

    const filterOptions: FilterOption[] = [
        {
            value: "show-all",
            action: () => setQuery({ ...query, status: undefined })
        },
        {
            value: "my-candidates",
            action: () => setQuery({ ...query, status: 'my_candidates' })
        }
    ];

    const tableHeaderItems: HeaderItem[] = [
        {
            value: "candidate",
            width: "15%"
        },
        {
            value: "main-skills",
            width: "10%"
        },
        {
            value: "contact",
            width: "14%"
        },
        {
            value: "experience",
            width: "12%"
        },
        {
            value: "wage-claim",
            width: "13%"
        },
        {
            value: "status",
            width: "14%"
        },
        {
            value: "responsable",
            width: "15%"
        }
    ];

    return (<>
        <Modal
            width={850}
            isShown={isShown}
            toggle={handleOpen}
            target={modalTalent}
            handleClose={handleClose}
            background={'#F3F3F3'}
        >
            <Talent
                talentId={
                    candidate ?
                        candidate?.is_pool && candidate?.user_id
                            ? candidate?.user_id
                            : candidate?.id
                        : ''
                }
                userId={candidate?.user_id || ''}
                trackId={candidate?.selection?.source_id}
                ecosystem={query.ecosystem || DEFAULT_ECOSYSTEM.id}
                isNotifyOpenCandidateDetail={!candidate?.selection}
                showJobOrigin={candidate?.selection?.origin_type === OriginType.JOB}
                jobName={candidate?.selection?.origin_name}
            />
        </Modal>
        <Container>
            <Header title={t('tabs.selecteds.title')} subtitle={user?.corporate.name} isSelecteds={true} />
            <ViewDesktopFilters>
                <FilterForm
                    initialMaxSalary={50000}
                    isSelectedsCandidate={true}
                    skills={[]}
                />
            </ViewDesktopFilters>
            {(!selectedCandidatesData.loading && selectedCandidatesData.data?.results) && 
                (selectedCandidatesData.data?.results.length > 0 ? (
                <Content>
                    <InfoTableContainer>
                        <TableCounter
                            count={selectedCandidatesData.data?.results.length || 0}
                            total={selectedCandidatesData.data?.count || 0}
                        />
                        <FilterContainer>
                            <TableFilter 
                                label={'origin'}
                                options={filterOrigins}
                                initial={originFilterInitial}
                                i18n_item={false}
                            />
                            <TableFilter 
                                label={'show'}
                                options={filterOptions}
                                initial={query?.status === 'my_candidates'
                                    ? filterOptions[1]
                                    : filterOptions[0]
                                }
                            />
                        </FilterContainer>
                    </InfoTableContainer>
                    <Table headerItems={tableHeaderItems}>
                        {selectedCandidatesData.data?.results.map((c, key) => (
                            <TableRow 
                                key={key}
                                onClick={() => {
                                    setCandidate(c);
                                    handleModal(c, candidateStatus)
                                }}
                            >
                                <DataCell color={'#452470'} bold={600}>{c?.full_name}</DataCell>
                                <DataCell>{c?.main_skills.join('/')}</DataCell>
                                <DataCell>{c?.phone}</DataCell>
                                <DataCell>{c?.experience_time}</DataCell>
                                <DataCell>{getCandidateSalary(c, t)}</DataCell>
                                <DataCell>{
                                    !!(c?.job_offer || c?.last_action) && (
                                        <SelectedStatus
                                        talentStatus={{
                                            last_action: c?.last_action,
                                            job_offer: c?.job_offer,
                                        }}
                                        />
                                    )
                                }</DataCell>
                                <DataCell>{c?.last_action?.recruiter.name}</DataCell>
                            </TableRow>
                        ))}
                    </Table>
                    {(selectedCandidatesData.data && selectedCandidatesData.data.results?.length > 0) &&
                        <Pagination
                        currentPage={selectedCandidatesData.data?.current_page}
                        pageNumbers={selectedCandidatesData.data?.total_pages}
                        onPageChange={handlePageChange}
                        />
                    }
                </Content>
            ) : (
                <Content>
                    <CandidatesNotFound>
                        <AiOutlineSearch className='icon-search'/>
                        Você ainda não possui nenhum talento selecionado.
                        Comece agora mesmo!
                    </CandidatesNotFound>
                </Content>
            ))}
        </Container>
    </>);
}

export default SelectedCandidatesContent;
