import { JobBoardFormFields, JobInterface } from "./job-board-form.model";

export const convertJobToPostBody = (job: JobInterface): JobBoardFormFields => ({
    name: job.name,
    hire_type_id: job.hire_type.id,
    degree_id: job.degree?.id,
    city_id: job.city?.id,
    country_id: job.country.id,
    experience_time_id: job.experience_time.id,
    experience_level_id: job.experience_level.id,
    min_salary: parseInt(`${job.min_salary}`),
    max_salary: parseInt(`${job.max_salary}`),
    currency_id: job.currency.id,
    language_id: job.language.id,
    description: job.description,
    end_date: job.end_date,
    work_model: job.work_model,
    skills_id: job.skills.map((s) => s.id),
    diversitys_id: job.diversitys.map((d) => d.id),
});