import styled from "styled-components";


export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    overflow: hidden;

    background: rgba(69, 36, 112, 0.7);
    backdrop-filter: blur(2px);
`;

export const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 5rem;
    margin: auto;
    z-index: 550;
    padding: 2rem;

    width: fit-content;
    height: fit-content;
    min-width: 30rem;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    background: #fff;
    border-radius: 0.75rem;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 1.75rem;
    color: #452470;
    background: none;
`;

export const Message = styled.span`
    color: #452470;
`;

export const Title = styled(Message)`
    font-size: 1.5rem;
    font-weight: 600;
`;

export const CloseContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.5rem;
    gap: 1rem;
`;

export const RemainingDays = styled.span`
    color: #452470;
`;

export const CloseJobButton = styled.button`
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 0.75rem;
    width: 10rem;
    background-color: #452470;
    color: #fff;

    &:hover {
        background-color: #6A4F8C;
    }
`;

export const CancelButton = styled(CloseJobButton)`
    background-color: transparent;
    color: #452470;
    border: 1px solid #452470;

    &:hover {
        color: #6A4F8C;
        border: 1px solid #6A4F8C;
    }
`;

export const ConfirmCancelContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
`;