import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../store/app-state";
import { ButtonPrimary, ButtonSecondary } from "../form/button/button.styled";
import { Buttons, Footer, Logo, Message, Modal } from "./dialog.styled";

const DialogElement = document.getElementById("dialog-confirm");

const Dialog: React.FC<any> = ({
  isOpen,
  title,
  message,
  action,
  onConfirm,
  onDismiss,
  toggle,
  ...props
}) => {
  const { eventData } = useSelector((state: AppState) => state.eventState);
  const logo = eventData.data?.image_footer || "/assets/img/tmw-1.svg";

  return (
    <Modal
      {...props}
      top={15}
      width={500}
      title={title}
      isShown={isOpen}
      toggle={onDismiss}
      target={DialogElement}
    >
      <Message>{message}</Message>
      <Buttons>
        <ButtonSecondary onClick={onDismiss}>Cancelar</ButtonSecondary>
        <ButtonPrimary onClick={onConfirm}>Enviar</ButtonPrimary>
      </Buttons>
      <Footer>
        <Logo src={logo} />
      </Footer>
    </Modal>
  );
};

export default Dialog;
