import { Key, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Transition } from 'react-spring';
import { ArrowIcon, CloseIcon, CloseSelectionList, SelectContainer, Selection, SelectionItem, SelectionList, SelectionListItem, CloseListItem } from './select.styled';
import useOnclickOutside from "react-cool-onclickoutside";

interface SelectProps<T> {
    value?: T;
    values?: T[];
    placeholder?: string;
    onChange?: (value: T) => void;
    getElementKey?: (value: T, index: number) => Key;
    getElementLabel: (value: T, index?: number) => string;
}

const ESC_KEY_CODE = 27;

const Select = <T extends unknown>({
    value,
    values,
    placeholder = '',
    onChange,
    getElementKey,
    getElementLabel
}: SelectProps<T>): ReactElement | null => {
    const [isOpen, setIsOpen] = useState(false);
 
    const hasValue = useMemo(() => {
        return !!value;
    }, [value]);

    const handleOnChange = useCallback((value: T) => {
        if (onChange) {
            onChange(value);
        }
        setIsOpen(false);
    }, [onChange, setIsOpen]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const handleEscPressed = useCallback((event) => {
        if (event.keyCode === ESC_KEY_CODE) {
            setIsOpen(false);
        }
    }, [setIsOpen]);

       
    const ref = useOnclickOutside(() => {
        handleClose();
    });

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('keydown', handleEscPressed, false);

            return () => {
                document.removeEventListener('keydown', handleEscPressed, false);
            };
        }
    }, [isOpen, handleEscPressed]);

    return (
        <SelectContainer ref={ref}>
            <Selection
                onClick={() => setIsOpen(!isOpen)}
                hasValue={hasValue}
            >
                {hasValue && value ?
                    <>
                        {getElementLabel(value)}
                    </>
                    :
                    <>
                        {placeholder}
                    </>
                }

                <ArrowIcon />
            </Selection>

            <Transition
                items={isOpen}
                config={{ duration: 100 }}
                from={{ opacity: 0 }}
                enter={{ opacity: 1 }}
                leave={{ opacity: 0 }}
                trail={0}
            >
                {(styles, item) => item &&
                    <SelectionList style={styles}>
                        <CloseListItem>
                            <CloseSelectionList onClick={handleClose}>
                                <CloseIcon />
                            </CloseSelectionList>
                        </CloseListItem>

                        {values?.map((value, index) =>
                            <SelectionListItem
                                key={getElementKey ? getElementKey(value, index) : index}
                            >
                                <SelectionItem
                                    onClick={() => handleOnChange(value)}
                                >
                                    {getElementLabel(value, index)}
                                </SelectionItem>
                            </SelectionListItem>
                        )}
                    </SelectionList>
                }
            </Transition>
        </SelectContainer>
    );
}
export default Select;