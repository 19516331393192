import React from "react";
import { OriginType } from "../../../../../models/track-type.enum";
import { Container, HeaderContent } from "./header.styled";

interface HeaderPropos extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    subtitle?: string;
    originType?: OriginType;
    isSelecteds?: boolean;
    isControl?: boolean;
}

const Header: React.FC<HeaderPropos> = ({ className, title, subtitle, ...props }) => {
    return (
        <Container {...props} className={className}>
            <HeaderContent>
            </HeaderContent>
        </Container>
    );
}

export default Header;