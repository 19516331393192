import { ReducerBuilder } from "../../utils/redux/reducer-builder";
import { GlobalTypes } from './../global-types';
import { INITIAL_STATE, TabChangedState, TabChangedTypes } from "./tab-changed-types";

const reduxBuilder = new ReducerBuilder<TabChangedState>(INITIAL_STATE);

reduxBuilder.addReducer(GlobalTypes.CLEAR, () => {
    return INITIAL_STATE;
});

reduxBuilder.addMutableReducer(TabChangedTypes.SET_IS_CHANGED, (state) => {
    state.isChanged.data = false;
    state.isChanged.data = true;
});

export default reduxBuilder.build();