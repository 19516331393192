import React from 'react';
import { Redirect, Router, Switch } from 'react-router-dom';
import MainLayout from '../layouts/main-layout/main-layout';
import HomeDashboardPage from '../pages/private/home-dashboard/home-dashboard-page';
import JobBoardFormPage from '../pages/private/job-board/job-board-form-page';
import JobBoardPage from '../pages/private/job-board/job-board-page';
import HomePage from '../pages/public/home/home-page';
import historyProvider from './history';
import PrivateRoute from './private-route';
import PublicRoute from './public-route';

const ApplicationRoutes: React.FC = () => {
    return (
        <Router history={historyProvider}>
            <Switch>
                <PublicRoute path="/" exact>
                    <Redirect to='/talent-match' exact />
                </PublicRoute>
                <PrivateRoute path="/jobs/new" layout={MainLayout} component={JobBoardFormPage} exact />
                <PrivateRoute path="/jobs" layout={MainLayout} component={JobBoardPage} exact  />
                <PublicRoute path="/:event" component={HomePage} exact />
                <PrivateRoute path="/home/:event" layout={MainLayout} component={HomeDashboardPage} exact />
            </Switch>
        </Router>
    );
};

export default ApplicationRoutes;


