import { Invoice } from './../../models/invoice.model';
import { AxiosResponse } from 'axios';
import talentApi from './talent-api-config';
import { parseISO } from 'date-fns';


class CompanyApi {

    async fetchLastInvoice(): Promise<AxiosResponse<Invoice>> {
        const response = await talentApi.get<Invoice>(`/talent/invoice/last/`);

        if (response.data?.due) {
            response.data.due = parseISO(response.data.due.toString());
        }

        return response;
    }

}

export const companyApi = new CompanyApi();
