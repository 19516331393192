import { ReactElement, useCallback, useContext } from 'react';
import Select, { MultiValue } from 'react-select';
import { ThemeContext } from 'styled-components';

interface SelectTagProps<T> {
    options?: T[];
    values?: T[];
    placeholder?: string;
    onChange?: (value: T[]) => void;
    onInputChange?: (value: string) => void;
}

const SelectTag = <T extends unknown>({
    options,
    values,
    placeholder = '',
    onChange,
    onInputChange,
}: SelectTagProps<T>): ReactElement | null => {

    const theme = useContext(ThemeContext);

    const handleOnChange = useCallback((value: MultiValue<T>) => {
        if (onChange) {
            onChange(value.map(v => v));
        }
    }, [onChange]);

    const handleOnInputChange = useCallback((value: string) => {
        if(onInputChange) {
            onInputChange(value);
        }
    },[onInputChange]);
    
    const customStyles = {
        option: () => ({
            width: '100%',
            display: 'flex',
            flex: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '.5rem',
            cursor: 'pointer',
            color: theme.typography.regular.color,
            backgroundColor: 'transparent',
            border: '1px solid transparent',
            margin: '0',
        }),
        menuList: (provided: any, state: any) => ({
            ...provided,
            top: '-1px',
            left: '-1px',
            width: 'calc(100% + 2px)',
            margin: '0',
            padding: '.5rem',
            backgroundColor: 'red',
            listStyle: 'none',
            background: '#FFFFFF',
            borderRadius: '0.3125rem',
            color: theme.typography.strong.color,
            zindex: '1',
            boxShadow: '0px 15px 25px rgba(0, 0, 0, 0.15)'
        }),
        control:  () => ({
            background: '#FFFFFF',
            borderRadius: '0.3125rem',
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #D9D9D9',
            color: theme.typography.strong.color,
            minHeight: '2.125rem',
            fontSize: '0.875rem'
        }),
        multiValue: (control: any, state: any) => ({
            ...control,
            background: '#F5EDFF',
            borderRadius: '5px'
        }),
        dropdownIndicator: (control: any, state: any) => ({
            ...control,
            color: theme.typography.strong.color,
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
        }),
        clearIndicator: (control: any, state: any) => ({
            ...control,
            color: '#DDDDDD'
        }),
        multiValueRemove: (control: any, state: any) => ({
            ...control,
            "&:hover": {
                backgroundColor: "#c7a5f0",
                color: '#F5EDFF'
            }
        })
    }

    return (
        <div>
            <Select
                closeMenuOnSelect={false}
                isMulti
                value={values}
                options={options}
                styles={customStyles}
                placeholder={placeholder}
                onChange={(value) => handleOnChange(value)}
                onInputChange={(value) => handleOnInputChange(value)}
            />
        </div>
    );
}

export default SelectTag;
