

import { useCallback, useEffect, useState } from 'react';
import { DrawerParams } from '../pages/private/home-dashboard/components/drawer/drawer';
import useQuery from './use-query';


const useDrawer = (name: string): { isOpen: boolean; handleCloseDrawer: () => void; handleOpenDrawer: () => void; toggleDrawer: () => void; } => {
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useQuery<DrawerParams>();

    const handleCloseDrawer = useCallback(() => {
        if (!!query?.drawer) {
            let newQuery = query;
            delete newQuery?.drawer;
            setQuery(newQuery as DrawerParams);
        }
    }, [query, setQuery]);

    const handleOpenDrawer = useCallback(() => {
        setQuery({ ...query, drawer: name });
    }, [setQuery, query, name]);

    const toggleDrawer = useCallback(() => {
        if (isOpen) {
            return handleCloseDrawer();
        }
        return handleOpenDrawer();
    }, [handleCloseDrawer, handleOpenDrawer, isOpen]);

    useEffect(() => {
        if (query?.drawer) {
            return setIsOpen(true);
        };

        return setIsOpen(false);
    }, [handleOpenDrawer, query]);

    return { isOpen, handleCloseDrawer, handleOpenDrawer, toggleDrawer };
}

export default useDrawer;