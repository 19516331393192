import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../app-state";
import { action } from 'typesafe-actions';
import { TabChangedTypes } from "./tab-changed-types";

export const fetchTabChanged = () => {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        dispatch(action(TabChangedTypes.SET_IS_CHANGED));
    }
};