import styled, { css } from 'styled-components';


interface ContainerProps {
    width?: string;
    height?: string;
    fontSize?: string;
}

export const Container = styled.span<ContainerProps>`
    width: 170px;
    height: 170px;
    position: relative;

    ${({ width }) => width && css`
        width: ${width};
    `};

    ${({ height }) => height && css`
        height: ${height};
    `};

    ${({ fontSize }) => fontSize && css`
        font-size: ${fontSize};
    `};
`;

export const Info = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    line-height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.primary.main};
`

export const Total = styled.span`
    font-size: 3em;
    color: ${({ theme }) => theme.colors.primary.main};
`

export const Label = styled.span`
    font-size: .8rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.primary.main};
`

