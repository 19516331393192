
export enum LastActionStatus {
    OPEN = 'open',
    INTERVIEW = 'interview',
    APPROVED = 'approved',
    NOT_APPROVED = 'not_approved',
    NOT_INTERVIEWED = 'not_interviewed',
    HIRED = 'hired',
    DENY_JOB_OFFER = 'deny_job_offer',
}
