import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 97vw;
    margin: 0 auto;
    padding: 0;
    overflow-x: auto;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 0.4rem;
        height: 0.4rem;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background-color: #bdbdbd;
    }
`;

export const TracksContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;

`;
