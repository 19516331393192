
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2.25rem 0 ;
`;

export const Title = styled.h3`
    text-align: center;
    margin: 1rem 0;
    font-size: 1rem;
    color: #452470;
    font-weight: 600;
`;