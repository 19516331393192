import styled from "styled-components";
import { DESKTOP, TABLET } from "./../../../../styles/breakpoints";

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${TABLET}) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    width: 99vw;
  }
`;

export const Brandings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
  width: 100%;
  justify-content: space-between;

  h1 {
    color: ${({ theme }) => theme.colors.primary.main};
    margin: 1rem;
  }

  img {
    margin: 1rem 0;
  }
`;

export const MenuList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 1rem 0;
  padding: 0;
  list-style: none;
`;

export const MenuListItem = styled.li`
  margin: 0;
  padding: 0 1rem;
  color: ${({ theme }) => theme.typography.regular.color};
  border-right: 1px solid ${({ theme }) => theme.typography.regular.color};
  white-space: nowrap;
  position: relative;

  &:last-child {
    border-right: none;
  }
`;

export const MenuListItemHiddenMobile = styled(MenuListItem)`
  display: none;
  @media screen and (min-width: ${DESKTOP}) {
    display: flex;
  }

`;

export const MenuButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.typography.regular.color};
  transition: color 0.4s, background-color 0.4s;

  &:hover,
  &:focus,
  &:active {
    outline: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  svg {
    margin-left: 0.5rem;
  }
`;

export const MenuSandwitch = styled.img`
  background: 0;
  border: 0;  
`;

export const Logo = styled.img`
  width: 215px;
`;