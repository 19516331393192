import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loading from "../../../loading";

const LoadingElement: React.FC = () => {
    const { t } = useTranslation('private-home-page');
    const [current, setCurrent] = useState<number>(0);
    
    useEffect(() => {
        const interval = setInterval(() => setCurrent(current+1), 2000);
        return () => clearInterval(interval);
    }, [current]);

    return (
        <Loading message={t(`job-board.confirm-modal.publishing-${current%2 + 1}`)}/>
    );
}

export default LoadingElement;