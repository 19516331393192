
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CandidateDetail } from '../../../../models/candidate-detail.model';
import { Information, InformationList, Linked, LinkExt, SectionBlock, Title } from '../../talent.styled';
import { LockedContent } from '../locked-content/locked-content';

interface PcDsProps {
    candidate?: CandidateDetail | null;
    isLocked: boolean;
}

const PcDs: React.FC<PcDsProps> = ({ isLocked, candidate }) => {
    const { t } = useTranslation('private-home-page');
    return (
        <>
            {candidate && candidate?.pcd?.length > 0 &&
                <SectionBlock>
                    <Title>{t('talent.pcd')}</Title>

                    {isLocked ?
                        <LockedContent />
                        :
                        <InformationList>
                            {candidate?.pcd?.map(pcd =>
                                <Information key={pcd.name}>
                                    {pcd?.medical_report_url ?
                                        <Linked target="_blank" href={pcd.medical_report_url} rel="noopener noreferrer">
                                           {pcd.name}
                                            <LinkExt src="/assets/img/link-ext.svg" />
                                        </Linked>
                                        :
                                        <>
                                            {pcd.name}
                                            {pcd?.cid && <> CID - {pcd?.cid} </>}
                                        </>
                                    }
                                </Information>
                            )}
                        </InformationList>
                    }
                </SectionBlock>
            }
        </>
    );
}

export default PcDs;
