
import styled, { DefaultTheme } from 'styled-components';

interface ContainerProps {
    color?: keyof DefaultTheme['colors'];
}

export const Container = styled.button<ContainerProps>`
    border-radius: 30px;
    border: 0;
    padding: .75rem .5rem;
    font-size: .8rem;
    background: ${({ color = 'primary', theme }) => theme.colors[color].main};
    color: ${({ color = 'primary', theme }) => theme.colors[color].text};
    transition: background-color 0.2s ease-in-out;
    border-radius: 0.3rem;
    font-weight: 600;
    width: 100%;

    &:hover {
        cursor: pointer;
        background: ${({ color = 'primary', theme }) => theme.colors[color].hover};
    }

    &:disabled {
        cursor: default;
        opacity: .5;
        background: ${({ color = 'primary', theme }) => theme.colors[color].main};
    }

    &:focus {
        outline: 0;
    }
`;

export const ButtonPrimary = styled(Container)`
    max-width: 30rem;
    font-size: 0.875rem;
    text-transform: none;
    width: 100%;
    box-shadow: none;
    font-weight: bold;
    margin: 0.5rem;
    &:disabled {
        background: #DDDDDD;
        color: #767676;
    }
`;

export const ButtonSecondary = styled(ButtonPrimary)`
    background: #FFF;
    border: 1px solid ${({ color = 'primary', theme }) => theme.colors[color].hover};
    color: ${({ color = 'primary', theme }) => theme.colors[color].hover};
    font-weight: bold;
    &:hover {
        cursor: pointer;
        background: ${({ color = 'primary', theme }) => theme.colors[color].hover};
        color: ${({ color = 'primary', theme }) => theme.colors[color].text};
    }
`;