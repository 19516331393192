import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../store/app-state";
import { fetchJobs } from "../../../../../store/job-board/job-board-actions";
import Loading from "../loading";
import { Container, Message, MessageContainer } from "./index.styled";
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Card from "./components/card";

interface JobListProps {
    onClickCard: (id: string) => void;
}
const JobList: React.FC<JobListProps> = ({ onClickCard }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('private-home-page');
    const {data: jobs, loading} = useSelector((state: AppState) => state.jobBoardState.jobs);

    useEffect(() => {
        dispatch(fetchJobs());
    }, [dispatch]);

    return (
        loading ? (
            <Loading message={t('job-board.loading-jobs')}/>
        ) : jobs?.length ? (
            <Container>
                {jobs.map((job) => (
                    <Card onClick={() => onClickCard(job.id)} key={job.id} job={job}/>
                ))}
            </Container>
        ) : (
            <MessageContainer>
                <Message><AiOutlineQuestionCircle size={'4rem'}/></Message>
                <Message>{t('job-board.not-found-jobs-1')}</Message>
                <Message>{t('job-board.not-found-jobs-2')}</Message>
            </MessageContainer>
        )
    );
}

export default JobList;