import { differenceInDays } from "date-fns";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FiAlertCircle } from "react-icons/fi";
import { JobInterface } from "../../../../job-board-form.model";
import JobDetail from "../../../job-detail";
import { Alert } from "../../index.styled";
import { AcceptButton, ButtonContainer, CancelButton, Container, Message } from "./index.styled";

interface ConfirmProps {
    onAccept: () => void;
    onDecline: () => void;
    job: JobInterface;
}
const Confirm: React.FC<ConfirmProps> = ({ onAccept, onDecline, job }) => {
    const { t } = useTranslation('private-home-page');
    const root = useCallback((key: string) => {
        return t(`job-board.confirm-modal.${key}`);
    }, [t]);

    const nextWeek = useMemo((): boolean => {
        const end_date = new Date(job.end_date);
        const today = new Date();
        return differenceInDays(end_date, today) <= 7;
    }, [job]);

    return (
        <Container>
            <JobDetail job={job}/>
            {nextWeek && (
                <Alert><FiAlertCircle />{root('alert-end-date')}</Alert>
            )}
            <Message>{root('alert-message')}</Message>
            <Message>{root('confirm-message')}</Message>
            <ButtonContainer>
                <CancelButton onClick={onDecline}>{root('buttons.cancel')}</CancelButton>
                <AcceptButton onClick={onAccept}>{root('buttons.accept')}</AcceptButton>
            </ButtonContainer>
        </Container>
    );
}

export default Confirm;