import { DESKTOP } from './../../../../styles/breakpoints';
import styled from 'styled-components';
import Button from '../../../form/button/button';

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @media screen and (min-width: ${DESKTOP}) {
        align-items: center;
    }
`;

export const UnlockButton = styled(Button)`
    margin: 1rem;
`;
