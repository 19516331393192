import ErrorAnimation from "../../../../../components/error-animation";
import { Container, Message } from "./index.styled";

const Error: React.FC<{ message?: string }> = ({ message }) => {
    return (
        <Container>
            <ErrorAnimation />
            <Message>{message}</Message>
        </Container>
    );
}

export default Error;