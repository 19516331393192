import { shade } from 'polished';
import { DefaultTheme } from "styled-components";

const MAIN_THEME: DefaultTheme = {
    containers: {
        main: {
            backgroundColor: '#f4f4f6',
        },
        cards: {
            backgroundColor: '#fff',
        }
    },
    colors: {
        primary: {
            main: '#452470',
            hover: '#BF76AE',
            text: '#fff',
        },
        secondary: {
            main: '#ED6969',
            hover: '#EB1536',
            text: '#fff',
        },
        success: {
            main: '#452470',
            hover: '#BF76AE',
            text: '#fff',
        },
        error: {
            main: '#c53030',
            hover: shade(0.2, '#c53030'),
            text: '#fff',
        },
        track: {
            main: '#CC1FDB',
            hover: shade(0.2, '#CC1FDB'),
            text: '#fff',
        },
        bootcamp: {
            main: '#E5105D',
            hover: shade(0.2, '#E5105D'),
            text: '#fff',
        },
        acceleration: {
            main: '#17E0BE',
            hover: shade(0.2, '#17E0BE'),
            text: '#fff',
        },
        hired: {
            main: '#B68F53',
            hover: shade(0.2, '#B68F53'),
            text: '#fff',
        },
        candidateHired: {
            main: '#452470',
            hover: '#CBAB7A',
            text: '#fff',
        },
        candidateNotApproved: {
            main: '#BF76AE',
            hover: '#EB1536',
            text: '#fff',
        },
        candidateNotInterview: {
            main: '#48BAC6',
            hover: '#EB1536',
            text: '#fff',
        },
        candidateDenyJobOffer: {
            main: '#76DECB',
            hover: '#EB1536',
            text: '#fff',
        }
    },
    typography: {
        strong: {
            color: '#0b0f21'
        },
        regular: {
            color: '#858b8d'
        },
        primary: {
            color: '#452470'
        }
    }
};

export default MAIN_THEME;

