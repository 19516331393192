
import firebase from 'firebase';
import { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { realtimeDatabase } from '../fire';
import CandidateStatus from '../models/candidate-status.model';
import { Candidate } from '../models/candidate.model';
import EventTrackStatistics from '../models/event-track-statistics.model';
import { AppState } from '../store/app-state';
import { addEventNotification, updateCandidateStatus, updateEventTrackStatistic } from '../store/event/event-actions';

interface RealtimeContextData { }

const RealtimeContext = createContext<RealtimeContextData>({} as RealtimeContextData);

export const RealtimeProvider: React.FC = ({ children }) => {
    const dispatch = useDispatch();
    const eventData = useSelector((state: AppState) => state.eventState.eventData);
    const currentEventTrackData = useSelector((state: AppState) => state.eventState.currentEventTrackData);
    const currentTrackCandidatesData = useSelector((state: AppState) => state.eventState.currentTrackCandidatesData);
    const selectedCandidatesData = useSelector((state: AppState) => state.eventState.selectedCandidatesData);
    const candidatePoolData = useSelector((state: AppState) => state.candidateState.candidatePoolData);

    useEffect(() => {
        if (eventData.data?.slug) {
            const notificationsPath = `${eventData.data.slug}/events`;
            const realtimeNotifications = realtimeDatabase.ref(notificationsPath)
                .limitToLast(10);
            const listener = realtimeNotifications.on('child_added', (snap) => {
                const notification = snap.val() as Notification;
                dispatch(addEventNotification(notification));
            });

            return (): void => {
                realtimeNotifications.off('child_added', listener);
            }
        }
    }, [eventData, dispatch]);

    useEffect(() => {
        if (eventData.data?.slug && currentEventTrackData.data?.uuid) {
            const statisticPath = `${eventData.data.slug}/statistics/${currentEventTrackData.data.uuid}`;
            const realtimeStatistics = realtimeDatabase.ref(statisticPath);

            const listener = realtimeStatistics.on('value', (snap) => {
                const statistic = snap.val() as EventTrackStatistics;
                dispatch(updateEventTrackStatistic(eventData.data!.slug, currentEventTrackData.data!.uuid, statistic))
            });

            return (): void => {
                realtimeStatistics.off('value', listener);
            }
        }
    }, [eventData.data, currentEventTrackData.data, dispatch]);

    useEffect(() => {
        if (eventData.data?.slug) {
            const realtimeDevsRefs: {
                reference: firebase.database.Reference,
                listener: any
            }[] = [];

            let candidates: Candidate[] = [];

            if (currentTrackCandidatesData.data) {
                candidates = candidates.concat(currentTrackCandidatesData.data.results);
            }

            if (selectedCandidatesData.data) {
                candidates = candidates.concat(selectedCandidatesData.data.results);
            }

            if (candidatePoolData.data) {
                candidates = candidates.concat(candidatePoolData.data.results);
            }

            for (const candidate of candidates) {
                const devsInfoPath = candidate.is_pool ?
                    `${eventData.data.slug}/pool/devs/${candidate.user_id}`
                    :
                    `${eventData.data.slug}/devs/${candidate.id}`;
                    
                const reference = realtimeDatabase.ref(devsInfoPath);

                const realtimeDevRef = {
                    reference,
                    listener: reference.on('value', (snap) => {
                        const candidatesStatus = snap.val() as CandidateStatus;
                        if (candidatesStatus) {
                            candidatesStatus.id = candidate.id;
                            dispatch(updateCandidateStatus(candidatesStatus));
                        }
                    })
                }
                realtimeDevsRefs.push(realtimeDevRef);
            }

            return (): void => {
                for (const realtimeDevsRef of realtimeDevsRefs) {
                    realtimeDevsRef.reference.off('value', realtimeDevsRef.listener);
                }
            }
        }
    }, [eventData, currentTrackCandidatesData.data, selectedCandidatesData.data, candidatePoolData.data, dispatch]);

    return (
        <RealtimeContext.Provider value={{}}>
            {children}
        </RealtimeContext.Provider>
    );
}
