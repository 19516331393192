import PaginatedResponse from '../../api/talent/models/paginated-response.model';
import CandidateStatus from '../../models/candidate-status.model';
import EventTrackStatistics from '../../models/event-track-statistics.model';
import { Event } from '../../models/event.model';
import { NotificationModel } from '../../models/notification.model';
import { OriginFilter } from '../../models/origin-filter.model';
import Track from '../../models/track.model';
import { Candidate } from './../../models/candidate.model';
import { StorageData } from './../storage-data';

export interface EventState {
    eventData: StorageData<Event | null, any | null>;
    eventTracksData: StorageData<Track[], any | null>;
    currentEventTrackData: StorageData<Track | null, any | null>;
    currentTrackCandidatesData: StorageData<PaginatedResponse<Candidate> | null, any | null>;
    currentTrackSkillsData: StorageData<string[], any | null>;
    eventsStatisticsData: Record<string, Record<string, EventTrackStatistics>>;
    notifications: NotificationModel[];
    candidatesStatus: Record<string, CandidateStatus>;
    selectedCandidatesData: StorageData<PaginatedResponse<Candidate> | null, any | null>;
    originFilterData: OriginFilter[];
}

export const INITIAL_STATE: EventState = {
    eventData: {
        data: null,
        loading: false,
        error: null
    },
    selectedCandidatesData: {
        data: null,
        loading: false,
        error: null
    },
    eventTracksData: {
        data: [],
        loading: false,
        error: null
    },
    currentEventTrackData: {
        data: null,
        loading: false,
        error: null
    },
    currentTrackCandidatesData: {
        data: null,
        loading: false,
        error: null
    },
    currentTrackSkillsData: {
        data: [],
        loading: false,
        error: null
    },
    notifications: [],
    eventsStatisticsData: {},
    candidatesStatus: {},
    originFilterData: [],
}

export enum EventActionTypes {
    FETCH_EVENT_DATA = '@event/FETCH_EVENT_DATA',
    FETCH_EVENT_DATA_SUCCESS = '@event/FETCH_EVENT_DATA_SUCCESS',
    FETCH_EVENT_DATA_ERROR = '@event/FETCH_EVENT_DATA_ERROR',
    
    FETCH_EVENT_TRACKS = '@event/FETCH_EVENT_TRACKS',
    FETCH_EVENT_TRACKS_SUCCESS = '@event/FETCH_EVENT_TRACKS_SUCCESS',
    FETCH_EVENT_TRACKS_ERROR = '@event/FETCH_EVENT_TRACKS_ERROR',
    
    FETCH_EVENT_TRACK = '@event/FETCH_EVENT_TRACK',
    FETCH_EVENT_TRACK_SUCCESS = '@event/FETCH_EVENT_TRACK_SUCCESS',
    FETCH_EVENT_TRACK_ERROR = '@event/FETCH_EVENT_TRACK_ERROR',

    CLEAR_CURRENT_EVENT_TRACK = '@event/CLEAR_CURRENT_EVENT_TRACK',

    FETCH_TRACK_CANDIDATES = '@event/FETCH_TRACK_CANDIDATES',
    FETCH_TRACK_CANDIDATES_SUCCESS = '@event/FETCH_TRACK_CANDIDATES_SUCCESS',
    FETCH_TRACK_CANDIDATES_ERROR = '@event/FETCH_TRACK_CANDIDATES_ERROR',
    
    ADD_NOTIFICATION = '@event/ADD_NOTIFICATION',
    UPDATE_EVENT_TRACK_STATISTICS = '@event/UPDATE_EVENT_TRACK_STATISTICS',
    UPDATE_CANDIDATE_STATUS = '@event/UPDATE_CANDIDATE_STATUS',

    FETCH_TRACK_SKILLS = '@event/FETCH_TRACK_SKILLS',
    FETCH_TRACK_SKILLS_SUCCESS = '@event/FETCH_TRACK_SKILLS_SUCCESS',
    FETCH_TRACK_SKILLS_ERROR = '@event/FETCH_TRACK_SKILLS_ERROR',

    FETCH_SELECTED_CANDIDATES = '@event/FETCH_SELECTED_CANDIDATES',
    FETCH_SELECTED_CANDIDATES_SUCCESS = '@event/FETCH_SELECTED_CANDIDATES_SUCCESS',
    FETCH_SELECTED_CANDIDATES_ERROR = '@event/FETCH_SELECTED_CANDIDATES_ERROR',

    READ_NOTIFICATIONS= '@event/READ_NOTIFICATIONS',

    FETCH_ORIGIN_FILTER = '@event/FETCH_ORIGIN_FILTER',
    FETCH_ORIGIN_FILTER_SUCCESS = '@event/FETCH_ORIGIN_FILTER_SUCCESS',
    FETCH_ORIGIN_FILTER_ERROR = '@event/FETCH_ORIGIN_FILTER_ERROR',
}
