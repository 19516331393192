import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from './../store/app-state';

export default function useChat() {
    const user = useSelector((state: AppState) => state.authState.user);

    useEffect(() => {
        if (user?.name && user?.email && user?.id) {
            window.Intercom("boot", {
                app_id: "vdjpg93n",
                name: user.name,
                email: user.email,
                user_id: user.id
            });
        }
    }, [user]);
}
