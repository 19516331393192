import { DESKTOP } from './../../../../styles/breakpoints';
import styled from 'styled-components';
import { default as AvatarInitialsParent } from "../../../content/avatar/avatar";

interface AvatarProps {
    isLocked: boolean;
    theme: Object;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: ${DESKTOP}) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const Image = styled.img<AvatarProps>`
    width: 2rem;
    height: 2rem;
    transition: all .6s;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
`;
    
export const Name = styled.h2`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    margin: .5rem;
`;

export const AvatarInitials = styled(AvatarInitialsParent)``;

export const LockedIcon = styled.img`
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
`;
