import styled from "styled-components";


export const Container = styled.div`
    width: 100%;
    height: 100%;
    background: none;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`;

export const Message = styled.span`
    color: #452470;
`;