import { useMemo } from "react";
import { Ball, Container } from "./index.styled";

interface BallsProps {
    current: number;
    total: number;
    setCurrent: (n: number) => void;
}
const Balls: React.FC<BallsProps> = ({ current, total, setCurrent }) => {

    const range = useMemo((): number[] => {
        return [...Array(total)];
    }, [total]);

    return (
        <Container>
            {range.map((_, index) => (
                <Ball 
                    onClick={() => setCurrent(index)}
                    selected={index === current}
                />
            ))}
        </Container>
    );
}

export default Balls;