import React, { createContext, useState } from "react";
import Dialog from "../components/dialog/dialog";
import useModal from "../hooks/use-modal";

interface IDialogProps {
  title: string | null;
  message: string | null;
  actionCallback: (value: boolean) => void;
  reasons?: Array<{
    label: string;
    id: string;
  }>;
  action: string | null;
}

interface IDialogContext {
  openDialog: (value: any) => void;
  resetDialog: () => void;
  value?: Object;
}

const INITIAL_STATE_DIALOG: IDialogProps = {
  title: null,
  message: null,
  actionCallback: () => {},
  action: null,
};

const DialogContext = createContext<IDialogContext>({
  openDialog: () => {},
  resetDialog: () => {},
});

const DialogProvider: React.FC = ({ children }) => {
  const { isShown, toggle } = useModal();
  const [dialog, setDialog] = useState<IDialogProps>(INITIAL_STATE_DIALOG);
  
  const openDialog = ({
    title,
    message,
    actionCallback,
    action,
  }: IDialogProps) => {
    toggle();
    setDialog({ title, message, actionCallback, action });
  };

  const resetDialog = () => {
    toggle();
    setDialog(INITIAL_STATE_DIALOG);
  };

  const onConfirm = () => {
    resetDialog();
    dialog.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialog.actionCallback(false);
  };

  return (
    <DialogContext.Provider value={{ openDialog, resetDialog }}>
      <Dialog
        isOpen={isShown}
        title={dialog?.title}
        message={dialog?.message}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        action={dialog.action}
      />
      {children}
    </DialogContext.Provider>
  );
};

export default Dialog;
export { DialogProvider, DialogContext };
