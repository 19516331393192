import { createContext } from "react";
import Ecosystem from "../../models/ecosystem.model";

export enum ENGLISH_TYPE {
  BEGINNER = "beginner",
  INTERMEDIATE = "intermediate",
  ADVANCED = "advanced",
  FLUENT = "fluent",
}

export enum HIRE_TYPE {
  CLT = "clt",
  PJ = "pj",
  HYBRID = "hybrid",
}

export enum WORK_TYPE {
  PRESENTIAL = "Presencial",
  REMOTE = "Remoto",
  HYBRID = "Híbrido",
}

export interface SelectLabelValue {
  label: string;
  value: string;
}
export interface JobofferQuery {
  diversity?: string;
  ecosystem?: string;
  english?: string[] | string;
  experience?: string[];
  hireTypes?: string[];
  maxSalary?: string;
  minSalary?: string;
  skills?: string[];
  workplace?: string;
  workType?: string[] | string;
}

export interface JobofferForm {
  diversity?: SelectLabelValue[];
  ecosystem?: Ecosystem;
  english?: SelectLabelValue[];
  experience?: SelectLabelValue[];
  hireTypes?: SelectLabelValue[];
  maxSalary?: string;
  minSalary?: string;
  skills?: SelectLabelValue[];
  workplace?: string;
  workType?: SelectLabelValue[];
}

export interface IJobOfferProps {}

export interface IJobOfferModel {
  ecosystems?: Ecosystem[] | null | undefined;
  experienceTimes?: JobofferForm["experience"];
  feedbackVisible?: boolean;
  maxSalary?: string;
  minSalary?: string;
  skillsList?: JobofferForm["skills"];
  skillsSelectValues?: JobofferForm["skills"];
  workTypes?: JobofferForm["workType"];
  english?: SelectLabelValue[];
}

export interface IJobOfferController {
  handleFormChange: (part: Partial<JobofferForm>) => void;
  jobofferform?: JobofferForm;
  joinTextEnglish: (levels?: SelectLabelValue[]) => string | undefined;
  joinTextExperience: (exp: JobofferForm["experience"]) => string;
  joinTextSalaryRange: (
    maxSalary?: JobofferForm["maxSalary"],
    minSalary?: JobofferForm["minSalary"]
  ) => string;
  joinTextSkills: (skills?: SelectLabelValue[]) => string | undefined;
  joinTextDiversity: (divs?: SelectLabelValue[]) => string | undefined;
  joinTextWorkType: (wt: JobofferForm["workType"]) => string;
  onCreateJobOffer: () => void;
  updateJobOffer: () => void;
  onResetJobOffer: () => void;
}

export const JobOfferContext = createContext<IJobOfferContext>({
  handleFormChange: () => {},
  joinTextEnglish: () => "",
  joinTextExperience: () => "",
  joinTextSalaryRange: () => "",
  joinTextSkills: () => "",
  joinTextWorkType: () => "",
  joinTextDiversity: () => "",
  onCreateJobOffer: () => {},
  onResetJobOffer: () => {},
  updateJobOffer: () => {},
});

export const INITIAL_STATE_JOBOFFER: IJobOfferProps = {};

export interface IJobOfferContext extends IJobOfferModel, IJobOfferController {}
