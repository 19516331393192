
import { createGlobalStyle } from 'styled-components';


export default createGlobalStyle`
    * {
        font-family: 'Open Sans', sans-serif;
        box-sizing: border-box;
    }

    body {
        overflow-x: hidden;
        background-color: #fff;
        font-size: 16px;
        position: relative;
    }

    h1, h2 {
        line-height: 35px;
        color: ${({ theme }) => theme.typography.strong.color }
    }

    p {
        font-weight: 400;
        line-height: 24px;
        color: ${({ theme }) => theme.typography.regular.color }

    }

    strong {
        color: ${({ theme }) => theme.typography.strong.color }
    }

    button {
        cursor: pointer;
    }

    a {
        color: ${({ theme }) => theme.typography.regular.color };
        text-decoration: none;

        &:hover{
            color: ${({ theme }) => theme.colors.primary.main };
        }
    }

    #dialog-confirm {
        z-index: 200;
        top: 0;
        position: absolute;
        left: 0;
        right: 0;
    }
    #modal-joboffer {
        z-index: 201;
        top: 0;
        position: absolute;
        left: 0;
        right: 0;
    }
`;
