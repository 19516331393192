import { TFunction } from "react-i18next";
import { HIRE_TYPE } from "../../../../../../components/job-offer/context";
import { Candidate } from "../../../../../../models/candidate.model";

export const getCandidatePosition = (
    current_page: number | undefined, 
    page_size: number | undefined, 
    key: number
) => {
    return ((current_page ?? 1) - 1) * (page_size ?? 10) + (key + 1);
};

export const getCandidateHireModel = (candidate: Candidate) => {
    const { remote, work_model_presencial, work_model_hybrid } = candidate;
    let hires: Array<string> = [];
    if (work_model_presencial) hires.push('Presencial');
    if (remote) hires.push('Remoto');
    if (work_model_hybrid) hires.push('Híbrido');

    return hires.join('/');
};

export const getCandidateHireTypes = (candidate: Candidate, t: TFunction) => {
    return candidate.hire_types &&
        candidate.hire_types.length > 0 &&
        candidate.hire_types
            .filter(x => [HIRE_TYPE.CLT, HIRE_TYPE.PJ].map(ht => ht.toString()).includes(x.toLowerCase()))
            .map((hireType, index) => {
                const label = `private-home-page:filterForm.hireTypes${hireType.charAt(0).toUpperCase() +
                    hireType.slice(1).toLowerCase()
                    }`;
                return t(label);
            }).join('/');
};

export const getCandidateSalary = (candidate: Candidate, t: TFunction) => {
    const salary = parseFloat(candidate.wage_claim);
    return !isNaN(salary)
        ? t('ranking-table.salary', {salary: salary.toLocaleString()})
        : '';
};