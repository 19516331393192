
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../../../components/content/pagination/pagination';
import LockIco from '../../../../../components/ico/lock';
import Modal from '../../../../../components/modal/modal';
import Talent from '../../../../../components/talent/talent';
import { useModal, useQuery } from '../../../../../hooks';
import useCandidateStatus from '../../../../../hooks/use-candidate-status';
import { Candidate } from '../../../../../models/candidate.model';
import { OriginType } from '../../../../../models/track-type.enum';
import { AppState } from '../../../../../store/app-state';
import { clearCandidateDetail } from '../../../../../store/candidate/candidate-actions';
import { fetchTrackCandidates, fetchTrackSkills, updateCurrentEventTrackData } from '../../../../../store/event/event-actions';
import { fetchSetInvisible, fetchSetVisible } from '../../../../../store/load/load-actions';
import HomeDashboardPageQuery from '../../home-dashboard-page-query.model';
import Countdown from '../countdown/countdown';
import FilterForm from '../filter-form/filter-form';
import Header from '../header/header';
import Table from '../table';
import TableCounter from '../table/components/counter';
import TableFilter, { FilterOption } from '../table/components/filter';
import PageSizeFilter from '../table/components/filter/page-size-filter';
import { HeaderItem } from '../table/components/header';
import { DataCell, InfoTableContainer, TableRow } from '../table/index.styled';
import {
    getCandidateHireModel,
    getCandidateHireTypes, 
    getCandidatePosition,
    getCandidateSalary
} from '../table/utils/table.utils';
import { CardCountdown, ContainerTable, TrackTitle, ViewDesktopFilters } from '../track-content/track-content.styled';
import { Container, Content, DashboardHeader, NotificationRow } from './track-content.styled';

const DEFAULT_ECOSYSTEM = { id: 'ad63a367-a5e5-47b6-a9bf-0a6dfb5fe1ff', name: 'Java' };
const modalTalent = document.getElementById("modal-talent");

const TrackContent: React.FC = () => {
    const { isShown, toggle } = useModal();
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.authState.user);
    const { t } = useTranslation('private-home-page');
    const [query, setQuery] = useQuery<HomeDashboardPageQuery>();
    const {
        currentEventTrackData,
        currentTrackCandidatesData,
        currentTrackSkillsData
    } = useSelector((state: AppState) => state.eventState);
    const devTableRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const ecosystem = currentEventTrackData.data?.ecosystem;
        const is_techimpact = currentEventTrackData.data?.is_techimpact;
        if(is_techimpact && query?.orderBy === 'resume' && !query?.ecosystem)
            setQuery({ ...query, ecosystem: ecosystem ? ecosystem.id : DEFAULT_ECOSYSTEM.id});
    }, [query, setQuery, currentEventTrackData]);

    useEffect(() => {
        if (query.track) {
            dispatch(updateCurrentEventTrackData(query.track));
            dispatch(fetchTrackCandidates(query.track, query));
            dispatch(fetchTrackSkills(query.track));
            dispatch(fetchSetVisible());
        }
    }, [query, dispatch]);

    useEffect(() => {
        if (!currentTrackCandidatesData.loading) {
            dispatch(fetchSetInvisible());
        }
    }, [currentTrackCandidatesData.loading, dispatch])

    const showCountdown = useMemo(() => {
        return !!currentEventTrackData.data?.end;
    }, [currentEventTrackData.data]);

    const title = useMemo(() => {
        if (currentEventTrackData.data?.origin_type === OriginType.ACCELERATION) {
            return t('tabs.trackContent.acceleration');
        }
        if (currentEventTrackData.data?.origin_type === OriginType.BOOTCAMP) {
            return t('tabs.trackContent.bootcamp');
        }
        return t('tabs.trackContent.vertical');
    }, [t, currentEventTrackData.data]);

    const subtitle = useMemo(() => {
        return currentEventTrackData.data?.origin_name || currentEventTrackData.data?.name;
    }, [currentEventTrackData.data]);

    const isJob = useMemo(() => {
        return currentEventTrackData.data?.is_from_job
      }, [currentEventTrackData.data]);

    const handlePageChange = useCallback((page: number) => {
        query.trackingPage = page.toString();
        setQuery(query);

        if (devTableRef.current) {
            window.scrollTo({
                behavior: 'smooth',
                top: devTableRef.current.offsetTop,
            });
        }
    }, [query, setQuery, devTableRef]);


    const tableHeaderItems: HeaderItem[] = [
        { value: 'position', width: '2%' },
        { value: 'name', width: '2%' },
        { value: 'main-skills', width: '20%' },
        { value: 'contact', width: '3%' },
        { value: 'experience', width: '12%' },
        { value: 'wage-claim', width: '13%' },
        { value: 'work-model', width: '15%' },
        { value: 'hire-types', width: '15%' },
    ];

    const handleOpen = useCallback(() => {
        toggle();
    }, [toggle]);

    const handleClose = () => {
        if (currentEventTrackData.data?.register) {
            // TODO: Se possível toda atualização vier pelo realtime também
            dispatch(fetchTrackCandidates(currentEventTrackData.data.uuid, query));
        }
        dispatch(clearCandidateDetail());
    };
    const [candidate, setCandidate] = useState<Candidate>();

    const candidateStatus = useCandidateStatus({ candidateId: candidate?.id || '' });

    const handleModal = useCallback((candidate: Candidate, candidateStatus) => {
        (!candidateStatus?.hired ||
            candidateStatus?.hired === user?.corporate.id ||
            candidate.company_hired === user?.corporate.id) &&
            handleOpen();
    }, [handleOpen, user]);

    const orderOptions: FilterOption[] = [
        {
            value: "performance",
            action: () => setQuery({ ...query, orderBy: 'performance'})
        },
        {
            value: "resume",
            action: () => setQuery({ ...query, orderBy: 'resume'})
        }
    ];

    const filterOptions: FilterOption[] = [
        {
            value: "show-all",
            action: () => setQuery({ ...query, status: undefined })
        },
        {
            value: "interview",
            action: () => setQuery({ ...query, status: 'interview' })
        },
        {
            value: "hired",
            action: () => setQuery({ ...query, status: 'hired' })
        },
        {
            value: "not-approved",
            action: () => setQuery({ ...query, status: 'not_approved' })
        }
    ];

    const getInitialFilterOption = () => {
        switch(query?.status){
            case 'interview':
                return filterOptions[1];
            case 'hired':
                return filterOptions[2];
            case 'not_approved':
                return filterOptions[3];
            default:
                return filterOptions[0];
        }
    };

    return (<>
        <Modal
            width={850}
            isShown={isShown}
            toggle={handleOpen}
            target={modalTalent}
            handleClose={handleClose}
            background={'#F3F3F3'}
        >
            <Talent
                talentId={
                    candidate ?
                        candidate?.is_pool && candidate?.user_id
                            ? candidate?.user_id
                            : candidate?.id
                        : ''
                }
                userId={candidate?.user_id || ''}
                trackId={candidate?.selection?.source_id}
                ecosystem={query.ecosystem || DEFAULT_ECOSYSTEM.id}
                isNotifyOpenCandidateDetail={!candidate?.selection}
            />
        </Modal>
        <Container>
            <Header
                title={title}
                subtitle={subtitle}
                originType={currentEventTrackData.data?.origin_type}
            />

            <Content>
                {isJob && (
                    <TrackTitle>
                        {currentEventTrackData.data?.origin_name || currentEventTrackData.data?.name}
                    </TrackTitle>
                )}
                <DashboardHeader showCountdown={showCountdown}>
                    <ViewDesktopFilters>
                        <FilterForm
                            initialMaxSalary={50000}
                            isTrack={true}
                            grid
                            skills={currentTrackSkillsData?.data?.map(s => ({ value: s, label: s })) || []}
                            reset={true}
                        />
                    </ViewDesktopFilters>
                    {showCountdown && (
                        <NotificationRow>
                            <CardCountdown>
                                <Countdown
                                    title={t('tabs.trackContent.run')}
                                    loading={currentEventTrackData.loading}
                                    endDate={currentEventTrackData.data?.end}
                                />
                            </CardCountdown>
                        </NotificationRow>
                    )}
                </DashboardHeader>
                {currentEventTrackData.data?.register &&
                    <>
                        <ContainerTable>
                            <InfoTableContainer>
                                <TableCounter
                                    count={currentTrackCandidatesData.data?.results.length || 0}
                                    total={currentTrackCandidatesData.data?.count || 0}
                                />
                                <InfoTableContainer width="fit-content">
                                    <PageSizeFilter />
                                    {currentEventTrackData.data?.is_techimpact
                                    ? <TableFilter 
                                        label={'order-by'} 
                                        options={orderOptions} 
                                        initial={query?.orderBy === 'resume'
                                            ? orderOptions[1]
                                            : orderOptions[0]
                                        }
                                    />
                                    : <TableFilter 
                                        options={filterOptions} 
                                        initial={getInitialFilterOption()} 
                                    />}
                                </InfoTableContainer>
                            </InfoTableContainer>
                            <Table headerItems={tableHeaderItems}>
                                {currentTrackCandidatesData.data?.results.map((c, key) => (
                                    <TableRow key={key}
                                        onClick={() => {
                                            setCandidate(c);
                                            handleModal(c, candidateStatus)
                                        }}
                                    >
                                        <DataCell>{
                                            getCandidatePosition(
                                                currentTrackCandidatesData.data?.current_page, 
                                                currentTrackCandidatesData.data?.page_size,
                                                key
                                            )
                                        }º</DataCell>
                                        <DataCell color={'#452470'} bold={600}>{c.initials_name.replace(' ', '')}</DataCell>
                                        <DataCell>{c.main_skills.join('/')}</DataCell>
                                        <DataCell><LockIco /></DataCell>
                                        <DataCell>{c.experience_time}</DataCell>
                                        <DataCell>{getCandidateSalary(c, t)}</DataCell>
                                        <DataCell>{getCandidateHireModel(c)}</DataCell>
                                        <DataCell>{getCandidateHireTypes(c, t)}</DataCell>
                                    </TableRow>))}
                            </Table>
                        </ContainerTable>
                        {(currentTrackCandidatesData.data && currentTrackCandidatesData.data.results?.length > 0) &&
                            <Pagination
                                currentPage={currentTrackCandidatesData.data?.current_page}
                                pageNumbers={currentTrackCandidatesData.data?.total_pages}
                                onPageChange={handlePageChange}
                            />
                        }
                    </>
                }
            </Content>
        </Container>
    </>);
}

export default TrackContent;
