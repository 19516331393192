import styled from 'styled-components';
import { TABLET } from './../../../styles/breakpoints';

export const Container = styled.div``;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-bottom: 4rem;

    @media(min-width: ${TABLET}) {
        flex-direction: row;
    }
`;

export const Merchandising = styled.p`
    text-align: center;
    font-size: 1.25rem;
    margin: 1rem auto;
    padding: 1rem;
    max-width: 1024px;
`;

export const MagicLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const MagicLinkSentMessage = styled.p`
    text-align: center;
    font-size: 1.25rem;
    margin: 1rem 0;
    color: ${({ theme }) => theme.colors.success.main};
`

export const MagicLinkSentError = styled.p`
    text-align: center;
    font-size: 1.25rem;
    margin: 1rem 0;
    color: ${({ theme }) => theme.colors.error.main};
`;
