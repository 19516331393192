
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CandidateDetail } from '../../../../models/candidate-detail.model';
import Tooltip from '../../../content/tooltip/tooltip';
import { SectionBlock, Title } from '../../talent.styled';
import { ShowMore, Skill, SkillsList } from './candidate-skills.styled';

interface CandidateSkillsProps {
    candidate: CandidateDetail
}

const CandidateSkills: React.FC<CandidateSkillsProps> = ({ candidate }) => {
    const [isShowAllSkills, setIsShowAllSkills] = useState<boolean>(false);
    const { t } = useTranslation('private-home-page');

    const openMoreSkills = () => {
        setIsShowAllSkills(true);
    };

    const skillsWithNotMainSkill = (skills: CandidateDetail["skills"]) =>
        skills.filter((skill) => !candidate.main_skills.some((main) => main === skill.name));

    return (
        <SectionBlock>
            <Title>{t('talent.habilities')} <span>({candidate.skills.length})</span></Title>
            <SkillsList>
                {candidate?.main_skills?.map(skill =>
                    <Skill key={skill}>{skill}</Skill>
                )}
                {isShowAllSkills && skillsWithNotMainSkill(candidate.skills).map((skill, index) =>
                    <Skill key={index}>
                        <Tooltip message={skill.name}>
                            {skill.name}
                        </Tooltip>
                    </Skill>
                )}

                {!isShowAllSkills && candidate.skills.length > candidate.main_skills.length && <Skill className="show-more">
                    <ShowMore onClick={openMoreSkills}>
                        {t('talent.showMore')}
                        <img src="/assets/img/show-more.svg" alt="show more" />
                    </ShowMore>
                </Skill>}

            </SkillsList>
        </SectionBlock>
    );
}

export default CandidateSkills;
