import { Border, Circle, Container, Cross } from "./index.styled";

const ErrorAnimation: React.FC = () => {
    return (
        <Container>
            <Border />
            <Circle>
                <Cross />
            </Circle>
        </Container>
    );
}

export default ErrorAnimation;