import styled from "styled-components";

export const SkillsList = styled.ul`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const Skill = styled.li`
    height: 1.625rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem; 
    margin: 0 0.3125rem 0.3125rem 0;
    color: #452470;

    &:not(.show-more) {
        padding: .5rem;
        background: #F5EDFF;
        border: 1px solid transparent;
    }
`;

export const ShowMore = styled.button`
    padding: .5rem;
    background: #FFFFFF;
    font-size: .75rem;
    border: 0.5px solid #452470;
    border-radius: 0.3125rem;
    color: #452470;
    position: relative;
    padding-right: 1.3125rem;
    height: 1.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .5rem;

    img {
        position: absolute;
        content: "";
        right: 5px;

    }
`;
