import React from "react";

function UnitedStatesFlagIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            fill="none"
            viewBox="0 0 512 512"
        >
            <g clipPath="url(#clip0)">
                <path
                    fill="#F0F0F0"
                    d="M256 511.999c141.385 0 256-114.615 256-256s-114.615-256-256-256-256 114.615-256 256 114.615 256 256 256z"
                >
                </path>
                <path
                    fill="#D80027"
                    d="M244.87 256H512c0-23.106-3.08-45.49-8.819-66.783H244.87V256zM244.87 122.434h229.556a257.354 257.354 0 00-59.07-66.783H244.87v66.783zM256 512c60.249 0 115.626-20.824 159.356-55.652H96.644C140.374 491.176 195.75 512 256 512zM37.574 389.564h436.852a254.474 254.474 0 0028.755-66.783H8.819a254.475 254.475 0 0028.755 66.783z"
                >
                </path>
                <path
                    fill="#0052B4"
                    d="M118.584 39.977h23.329l-21.7 15.765 8.289 25.509-21.699-15.765-21.699 15.765 7.16-22.037a257.407 257.407 0 00-49.652 55.337h7.475l-13.813 10.035a255.58 255.58 0 00-6.194 10.938l6.596 20.301-12.306-8.941a253.567 253.567 0 00-8.372 19.873l7.267 22.368h26.822l-21.7 15.765 8.289 25.509-21.699-15.765-12.998 9.444A258.468 258.468 0 000 255.999h256v-256c-50.572 0-97.715 14.67-137.416 39.978zm9.918 190.422l-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765 8.289 25.509zm-8.289-100.083l8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm100.115 100.083l-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765 8.289 25.509zm-8.289-100.083l8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm0-74.574l8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765z"
                >
                </path>
            </g>
            <defs>
                <clipPath id="clip0">
                    <path
                        fill="#fff"
                        d="M0 0H512V512H0z"
                        transform="translate(0 -.001)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default UnitedStatesFlagIcon;
