
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Tab from '../../../../../components/content/tab/tab';
import { AppState } from '../../../../../store/app-state';
import Header from '../header/header';
import CurrentInvoice from './components/current-invoice/current-invoice';
import InvoiceHistory from './components/invoice-history/invoice-history';
import { Container, Content, TabContent, Tabs } from './my-business-content.styled';

enum CurrentContent {
    INVOICE = 'INVOICE',
    INVOICE_HISTORY = 'INVOICE_HISTORY',
    CONTRACTS = 'CONTRACTS'
}

const MyBusinessContent: React.FC = () => {
    const user = useSelector((state: AppState) => state.authState.user);
    const [currentContent, setCurrentContent] = useState(CurrentContent.INVOICE);

    return (
        <Container>
            <Header
                title={`Controle`}
                subtitle={`${user?.corporate.name}`}
                isControl={true}
            />

            <Content>
                <Tabs>
                    <Tab
                        title="Fatura"
                        color='white'
                        isActive={currentContent === CurrentContent.INVOICE}
                        onClick={() => setCurrentContent(CurrentContent.INVOICE)}
                    />
                    <Tab
                        title="Faturas Anteriores"
                        color='white'
                        isActive={currentContent === CurrentContent.INVOICE_HISTORY}
                        onClick={() => setCurrentContent(CurrentContent.INVOICE_HISTORY)}
                    />
                    <Tab
                        title="Lista de Contratados"
                        color='white'
                        isActive={currentContent === CurrentContent.CONTRACTS}
                        onClick={() => setCurrentContent(CurrentContent.CONTRACTS)}
                    />
                </Tabs>

                <TabContent>
                    {currentContent === CurrentContent.INVOICE &&
                        <CurrentInvoice />
                    }

                    {currentContent === CurrentContent.INVOICE_HISTORY &&
                        <InvoiceHistory />
                    }

                    {currentContent === CurrentContent.CONTRACTS &&
                        <CurrentInvoice />
                    }
                </TabContent>
            </Content>
        </Container>
    );
}

export default MyBusinessContent;
