import React, { useMemo } from 'react';
import { CandidateDetail } from '../../../../models/candidate-detail.model';
import ContactIco from '../../../ico/contact';
import { Container, Image, Name } from './avatar.styled';

export interface AvatarProps {
    isLocked: boolean;
    candidate?: CandidateDetail | null;
}

const Avatar: React.FC<AvatarProps> = ({ isLocked, candidate }) => {
    const name = useMemo(() => {
        if (isLocked) {
            return candidate?.initials_name.replace(' ', '');
        }
        return candidate?.full_name;
    }, [candidate, isLocked]);

    return (
        <Container>
            {(candidate?.photo_url && !isLocked) ?
                <Image src={candidate?.photo_url} isLocked={isLocked} />
                :
                <><ContactIco/>{isLocked && (<Name>{candidate?.initials_name.split(" ").join("") || ''}</Name>)}</>
            }

            {!isLocked && (<Name>
                {name}
            </Name>)}
        </Container>
    )
}

export default Avatar;