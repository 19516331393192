import styled, { css } from 'styled-components';


export const Wrapper = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0.875rem;
    display: flex;
    justify-content: center;
    z-index: 999;
`;
export const Container = styled.div`
    min-width: 326px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-items: center;
    justify-content: center;
`;

export const Message = styled.div<{ isShow?: boolean, order?: number; }>`
    align-items: center;
    justify-content: flex-start;
    background: #BF76AE;
    border-radius: 10px;
    height: 3.375rem;
    justify-content: flex-start;
    padding: 0 2rem 0 1rem;
    position: relative;
    transition: all 1s;
    transform: translateY(-100rem) scale(1);
    display: none;
    margin-bottom: -2.8rem;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    width: calc(100% + 4rem);


    ${({ isShow, order }) => isShow && css`
        --size: calc(1 - ${order && order * 0.1});
        z-index: calc(5 - ${order});
        transform:  scale(var(--size));
        transition: all 2s;
        display: flex;
    `};
    p {
        color: #ffffff;
    }
`;


export const CloseButton = styled.button`
    background: 0;
    border: 0;
    position: absolute;
    right: 1.3125rem;
`;


export const CloseIcon = styled.img`

`;