import styled, { css } from 'styled-components';
import { OriginType } from './../../../../../models/track-type.enum';
import { TABLET } from './../../../../../styles/breakpoints';

interface ContainerProps {
    originType?: OriginType;
    isSelecteds?: boolean;
    isControl?: boolean;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    width: 100%;
    padding: 0 1rem;
    color: #fff;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 244px;
        background-color: ${({ theme }) => theme.colors.primary.main};
        z-index: -1;

        // ${({isSelecteds, theme}) => !isSelecteds && css`
        //     background: linear-gradient(180deg, ${theme.colors.track.main} 0%, ${theme.colors.primary.main} 100%), #fff;
        // `};

        // ${({isControl, theme}) => isControl && css`
        //     background: linear-gradient(180deg, ${theme.colors.acceleration.main} 0%, ${theme.colors.primary.main} 100%), #fff;
        // `};

        // ${({originType, theme}) => originType === OriginType.BOOTCAMP && css`
        //     background: linear-gradient(180deg, ${theme.colors.bootcamp.main} 0%, ${theme.colors.primary.main} 100%);
        // `};

        // ${({originType, theme}) => originType === OriginType.ACCELERATION && css`
        //     background: linear-gradient(180deg, ${theme.colors.acceleration.main} 0%, ${theme.colors.primary.main} 100%), 
        //                 linear-gradient(0deg, ${theme.colors.acceleration.main}, ${theme.colors.acceleration.main}), #fff;
        // `};
    }
`;

export const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    margin: 2rem auto;

    @media(min-width: ${TABLET}) {
        padding-left: 2rem;
    }
`;

export const Title = styled.h2`
    text-align: center;
    font-size: 2rem;
    font-weight: 800;
    margin: 1rem;
    line-height: 1;
    color: #fff;

    @media(min-width: ${TABLET}) {
        text-align: left;
        margin: 0;
    }
`;

export const Subtitle = styled.span`
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: uppercase;
`;
