import styled, { css } from "styled-components";
import { DESKTOP } from "../../styles/breakpoints";

export const ScrollableContent = css`
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: #bdbdbd;
  }
`;

export const Container = styled.div`
  overflow-y: auto;
  margin-top: 1rem;
  padding-right: 1rem;
  height: calc(100vh - 6rem);

  ${ScrollableContent};
`;

export const AboutContent = styled.div`
  height: calc(84vh - 5.5rem);

  @media screen and (min-width: ${DESKTOP}) {
    grid-template-columns: 70% 30%;
    display: grid;
    position: relative;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${DESKTOP}) {
    margin: 0;
  }
`;

export const LoadingContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 40rem;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Section = styled.section``;

export const SectionList = styled.section`
  padding: 0.5rem;
`;

interface SectionBlockProps {
  header?: string;
}

export const SectionBlock = styled.section<SectionBlockProps>`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 1.4375rem;
  margin: ${(props) => (props.header ? "0 0.2rem" : "1rem 0.5rem 0")};
  border-radius: 16px;
  height: ${(props) => (props.header ? "4rem" : null)};
  justify-content: ${(props) => (props.header ? "center" : null)};
`;

export const Title = styled.h4`
  margin: 0;
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 0.625rem;
  color: ${({ theme }) => theme.colors.primary.main};

  span {
    color: #9f9a9a;
    font-size: 0.75rem;
    font-weight: 400;
  }
`;

export const SubTitle = styled.h5`
  font-size: 1rem;
  font-weight: 400;
  margin: 1.5rem 0 0.5rem 0;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const Text = styled.p`
  margin: 0;
  font-size: 0.85rem;
  margin: 0.75rem 0 0.625rem !important;

  strong {
    color: ${({ theme }) => theme.typography.regular.color};
  }
`;

export const InformationList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Information = styled.li`
  margin: 0.2rem 0;
  color: ${({ theme }) => theme.typography.regular.color};
`;

export const InformationLink = styled.a`
  margin: 0.2rem 1rem;
  color: ${({ theme }) => theme.typography.regular.color};
  text-transform: underline;
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const Item = styled.li`
  padding: 0;
`;

interface LinkedProps {
  disabled?: boolean | false;
}
export const Linked = styled.a<LinkedProps>`
  color: #272727;
  display: flex;
  align-items: center;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "")};
`;

export const LinkExt = styled.img`
  margin-left: 0.4375rem;
`;

export const JobOffer = styled.div``;

export const ShowJobOffer = styled.button`
  padding: 0.5rem;
  background: #ffffff;
  font-size: 0.75rem;
  border: 0.5px solid #452470;
  border-radius: 0.3125rem;
  color: #452470;
  position: relative;
  padding-right: 1.3125rem;
  height: 1.625rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    content: "";
    right: 5px;
  }
`;

export const ProfessionalMomentContent = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0.5rem 1rem;

  svg {
    margin-right: 0.625rem;
  }
`;

export const FeaturedInformation = styled.p`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.2px;
  color: #452470;
`;
