import styled from 'styled-components';
import { DESKTOP } from '../../../../../styles/breakpoints';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media(min-width: ${DESKTOP}) {
        max-width: 1156px;
        margin: 0 auto;
    }
`;

export const DashboardHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const NotificationRow = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .5rem;
`;

export const StatisticsRow = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .5rem;
`;
