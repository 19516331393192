import React from "react";
import { useTranslation } from "react-i18next";
import { LastActionStatus } from "../../../../models/last-action-status.enum";
import { SectionBlock, Title } from "../../talent.styled";
import { Status } from "././feedback-status.styled";

interface SchemaStyled {
  background: string;
  border: string;
  color: string;
  title: string;
}

interface FeedbackProps {
  [key: string]: SchemaStyled;
}

interface FeedbackStatusProps {
  status: LastActionStatus;
}

const FeedbackSchema: FeedbackProps = {
  hired: {
    background: "#E5FDED",
    border: "#B6E6C3",
    color: "#499f68",
    title: "private-home-page:actions.hired",
  },
  not_approved: {
    background: "#FFF2F0",
    border: "1px solid #FFBEBE",
    color: "#F11C1C",
    title: "private-home-page:actions.not_approved",
  },
  deny_job_offer: {
    background: "#FFEFF7",
    border: "1px solid #F9B4CD",
    color: "#C9678A",
    title: "private-home-page:actions.deny_job_offer",
  },
  not_interviewed: {
    background: "#FAF0FF",
    border: "1px solid #FABEFF",
    color: "#E26BC8",
    title: "private-home-page:actions.not_interviewed",
  },
  match: {
    background: "#F5EDFF",
    border: "#E2D0F8",
    color: "#452470",
    title: "private-home-page:jobOffer.status.match",
  },
};

const FeedbackStatus: React.FC<FeedbackStatusProps> = ({ status }) => {
  const { t } = useTranslation(["translation", "private-home-page"]);

  return (
    <SectionBlock>
      <Title>{t("private-home-page:talent.enterTheCandidateStatus")}</Title>
      <div>
        <Status
          border={FeedbackSchema[status].border}
          background={FeedbackSchema[status].background}
          color={FeedbackSchema[status].color}
        >
          <p>{t(FeedbackSchema[status].title)}</p>
        </Status>
      </div>
    </SectionBlock>
  );
};

export default FeedbackStatus;
