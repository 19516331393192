import styled, { keyframes, DefaultTheme } from "styled-components";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

interface LoadingIconProps {
  color?: keyof DefaultTheme["colors"];
  size?: number;
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoadingIcon = styled(AiOutlineLoading3Quarters)<LoadingIconProps>`
  animation: ${rotate} 1.5s linear infinite;
  width: ${(props) => (props.size ? `${props.size}px` : "20px")};
  height: ${(props) => (props.size ? `${props.size}px` : "20px")};
  color: ${({ color = "primary", theme }) => theme.colors[color].main};
  align-self: center;
  justify-self: center;
  flex: 1;
`;
