import { formatDistanceToNowStrict } from "date-fns";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useNotifications from "../../../../../hooks/use-notifications";
import { NotificationModel, NOTIFICATION_STATUS } from "../../../../../models/notification.model";
import { readNotifications } from "../../../../../store/event/event-actions";
import { getLocale } from "../../../../../utils/redux/date";
import { Area, ImageButton, Item, List } from "./notification-area.styled";



const NotificationButton: React.FC<{ hasNewNotifications: boolean, toggle: Dispatch<SetStateAction<boolean>> }> = ({ hasNewNotifications, toggle }) => {
    return (
        <ImageButton onClick={() => toggle(!hasNewNotifications)}>
            {hasNewNotifications ? (
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.4168 15.8335H1.5835V14.2502H2.37516V8.73304C2.37516 4.7842 5.56558 1.5835 9.50016 1.5835C13.4347 1.5835 16.6252 4.7842 16.6252 8.73304V14.2502H17.4168V15.8335ZM7.521 16.6252H11.4793C11.4793 17.1501 11.2708 17.6535 10.8996 18.0246C10.5285 18.3958 10.0251 18.6043 9.50016 18.6043C8.97525 18.6043 8.47185 18.3958 8.10068 18.0246C7.72951 17.6535 7.521 17.1501 7.521 16.6252Z" fill="#452470" />
                    <circle cx="15.0417" cy="3.16667" r="3.16667" fill="#FF0000" />
                </svg>

            ) : (
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.6252 14.7502H16.9168V15.3335H2.0835V14.7502H2.37516H2.87516V14.2502V8.73304C2.87516 5.05876 5.84331 2.0835 9.50016 2.0835C13.157 2.0835 16.1252 5.05876 16.1252 8.73304V14.2502V14.7502H16.6252ZM10.8923 17.1252C10.8192 17.3285 10.7018 17.5154 10.5461 17.6711C10.2687 17.9485 9.89246 18.1043 9.50016 18.1043C9.10786 18.1043 8.73163 17.9485 8.45423 17.6711C8.29852 17.5154 8.18111 17.3285 8.10806 17.1252H10.8923Z" stroke="#452470" />
                </svg>
            )}
        </ImageButton>
    );
};

const timeAgo = (date: NotificationModel["timestamp"]) => {
    return date && formatDistanceToNowStrict(new Date(date), {
        locale: getLocale(),
    })
}

const NotificationArea: React.FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { messages, hasNewNotifications } = useNotifications();
    const scrollRef = useRef<HTMLUListElement>(null)
    const { t } = useTranslation('private-home-page');

    const handleOpen = () => {
        setIsOpen(!isOpen);

    }
    
    const ref = useOnclickOutside(() => {
        setIsOpen(false);
    });

    
    useEffect(() => {
        if(scrollRef.current)
            scrollRef.current.scrollTop = -scrollRef.current.scrollHeight;
    }, [isOpen, messages])
   
    useEffect(() => {

        const readNotificationAfterOpen = setTimeout(() => {
            if (isOpen) {
              dispatch(readNotifications());
            }
        }, 1500);

        return () => {
            clearTimeout(readNotificationAfterOpen);
        }
    }, [isOpen, dispatch]);




    return (
        <div ref={ref}>
            <NotificationButton hasNewNotifications={hasNewNotifications} toggle={() => handleOpen()} />

                <Area id="notifications" isShown={isOpen}>
                    <h3>
                        {t('private-home-page:notifications.notifications')}
                    </h3>
                    <List ref={scrollRef}>
                        {messages.length > 0 && messages.map((notification: NotificationModel, index: number) => {
                            return (<Item key={`notification-${index}`} isNew={notification.status !== NOTIFICATION_STATUS.READ}>
                                {notification?.translation && (
                                    <p>
                                        <b>{notification.translation.featured}</b>
                                        {notification.translation.message}
                                    </p>
                                )}
                                <span>
                                    {notification?.timestamp && timeAgo(notification.timestamp)}
                                </span>
                            </Item>)
                        })}
                    </List>
                </Area>
            
        </div>
    );
}

export default NotificationArea;
