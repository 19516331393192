import { setLightness } from 'polished';
import styled from 'styled-components';
import { TABLET } from '../../../../../../../../styles/breakpoints';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
`;

export const Title = styled.h3`
    width: 100%;
    padding-bottom: 1rem;
    color: ${({ theme }) => theme.colors.primary.main};
    border-bottom: 1px solid ${({ theme }) => setLightness(.8, theme.typography.regular.color)};
    text-transform: uppercase;
    margin: 1rem 0 0 0;
`;

export const Extract = styled.ol`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const Event = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
    list-style: none;
    border-bottom: 1px solid ${({ theme }) => setLightness(.9, theme.typography.regular.color)};
    
    @media(min-width: ${TABLET}) {
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;
    }
`;

export const EventDate = styled.p`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 3.5rem;
    height: 3.5rem;
    margin: 0;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => setLightness(.8, theme.typography.regular.color)};
    text-align: center;
    text-transform: uppercase;
    font-size: .8rem;
    line-height: 1rem;
    font-weight: bold;
    color: ${({ theme }) => theme.typography.regular.color};

    @media(min-width: ${TABLET}) {
        margin: 0 auto;
    }
`;

export const EventDay = styled.span`
    font-size: 1.5rem;
`;

export const EventDescription = styled.p`
    margin: 1rem;
    text-align: center;

    @media(min-width: ${TABLET}) {
        text-align: left;
    }
`;

export const EventValue = styled.p`
    color: ${({ theme }) => theme.colors.primary.main};
    font-size: 1.5rem;
    margin: 0;

    @media(min-width: ${TABLET}) {
        margin: 0 auto;
    }
`;


