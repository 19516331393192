import styled, { keyframes } from "styled-components";

export const stroke = keyframes`
    100% {
        stroke-dashoffset: 0
    }
`;

export const scale = keyframes`
    0%, 100% {
        transform: none
    }

    50%{
        transform: scale3d(1.1, 1.1, 1)
    }
`;

export const fill = keyframes`
    100% {
        box-shadow: inset 0px 0px 0px 30px #452470
    }
`;

export const Container = styled.div`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: scale(1.14);
`;

export const CheckMark = styled.svg`
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #452470;
    animation: ${fill} .4s ease-in-out .4s forwards, ${scale} .3s ease-in-out .9s both;

    .circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: #452470;
        fill: none;
        animation: ${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    .check {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
    }
`;