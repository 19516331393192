import React, { useState, useEffect } from 'react';


import { Overlay, LoadingSpinner, SpinnerContainer, LoadingMsg } from './loading-page.styled';

interface LoadingPageProps{
    messages: string[];
};

const LoadingPage: React.FC<LoadingPageProps> = ({messages}) => {
  const [numMessage, setNumMessage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => setNumMessage(Math.floor(Math.random() * messages.length)), 4000);
    return () => clearInterval(interval);
  }, [numMessage, messages]);

  return (

    <Overlay>
      <SpinnerContainer>
        <LoadingSpinner />
      </SpinnerContainer>
      <LoadingMsg>{messages[numMessage % messages.length]}</LoadingMsg>
    </Overlay>

  );
};

export default LoadingPage;