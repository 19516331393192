import Joi from "joi";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineMail } from "react-icons/hi";
import Button from "../../../../../components/form/button/button";
import Input from "../../../../../components/form/Input/input";
import { Container, Title } from "./authentication-form.styled";

interface AuthenticationFormProps {
    loading?: boolean;
    onSubmit?(email: string): Promise<void>;
}

const authenticationSchema = Joi.object({
    email: Joi.string().email({ tlds: { allow: false } }).required(),
});

const AuthenticationForm: React.FC<AuthenticationFormProps> = ({ onSubmit, loading }) => {
    const { t } = useTranslation('public-home-page');
    const [email, setEmail] = useState<string>();
    const [isFormValid, setFormValid] = useState(false);

    useEffect(() => {
        const { error } = authenticationSchema.validate({ email });
        setFormValid(!error);
    }, [email, setFormValid]);

    const onLogin = useCallback((e: React.SyntheticEvent) => {
        if (onSubmit && email) {
            onSubmit(email);
        }
        e.preventDefault();
    }, [email, onSubmit]);

    return (
        <Container onSubmit={(e) => onLogin(e)}>
            <Title>{t('public-home-page:enterYourEmail')}</Title>
            <Input
                name="email"
                type="email"
                placeholder="E-mail"
                className="email"
                icon={HiOutlineMail}
                value={email || ''}
                onValueChange={(newValue) => setEmail(newValue)}
            />

            <Button
                type="submit"
                className="action"
                disabled={!isFormValid}
                loading={loading}
            >
                {t('public-home-page:getAccessLink')}
            </Button>
        </Container>
    );
}

export default AuthenticationForm;