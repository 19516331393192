import styled from 'styled-components';
import Card from '../../../../../components/content/card/card';
import { DESKTOP } from './../../../../../styles/breakpoints';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: .5rem;

    @media(min-width: ${DESKTOP}) {
        max-width: 1440px;
        margin: 0 auto;
    }
`;

export const Tabs = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    margin: 0 auto;
    overflow-x: auto;
    z-index: 2;
`;

export const TabContent = styled(Card)`
    background-color: #fff;
    width: 100%;
    z-index: 1;
    padding: 1rem;
`;
