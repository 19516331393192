
import styled, { css } from 'styled-components';
import Tooltip from '../../content/tooltip/tooltip';

interface ContainerProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
}

export const InputContainer = styled.div<ContainerProps>`
    align-items: center;
    background: #FFFFFF;
    border-radius: 0.3125rem;
    border: 1px solid #D9D9D9;
    height: 2.125rem;
    font-size: 0.875rem;
    color: #858B8D;
    color: ${({theme}) => theme.typography.strong.color};
    display: flex;
    padding: .5rem .75rem;

    ${props => props.isErrored && css`
        color: ${({theme}) => theme.colors.error.main};
        border-color: ${({theme}) => theme.colors.error.main};
    `}
    
    ${props => props.isFocused && css`
        border-color: ${({theme}) => theme.colors.primary.hover};
    `}

    & + div {
        margin-top: .5rem;
    }

    svg {
        margin-right: .5rem;
        color: ${({theme}) => theme.typography.regular.color};

        ${props => props.isErrored && css`
            color: ${({theme}) => theme.colors.error.main};
        `}

        ${props => props.isFocused && css`
            color: ${({theme}) => theme.colors.primary.hover};
        `}
    }

    input {
        flex: 1;
        background: transparent;
        border: 0;
        outline: 0;
        color: ${({theme}) => theme.typography.strong.color};
        line-height: 2;
        width: 100%;

        &:focus {
            outline: 0;
        }

        &::placeholder {
            color: ${({theme}) => theme.typography.regular.color};

            ${props => props.isFocused && css`
                color: ${({theme}) => theme.colors.primary.hover};
            `}
        }

        ${props => props.isErrored && css`
            color: ${({theme}) => theme.colors.error.main};
        `}

        ${props => props.isFocused && css`
            color: ${({theme}) => theme.colors.primary.hover};
        `}
    }
`;

export const Error = styled(Tooltip)`
    color: ${({theme}) => theme.colors.error.main};

    svg {
        margin: 0;
        margin-left: .5rem;
    }

    span {
        background: ${({theme}) => theme.colors.error.main};
        color: ${({theme}) => theme.colors.error.text};

        &::before {
            border-color: ${({theme}) => theme.colors.error.main} transparent;
        }
    }
`;
