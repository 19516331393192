
import styled from 'styled-components';

export interface BackdropProps {
  isShown: boolean;
}

interface ContainerProps {
    theme: Object;
    width?: number;
    background?: string;
}

export const Container = styled.div<ContainerProps>`
    background-color: ${(props) => props.background ? props.background : props.theme.containers.cards.backgroundColor};
    border-radius: 10px;
    border: 0;
    bottom: 1rem;
    font-size: 1rem;
    left: 1rem;
    margin: 0 auto;
    max-width: ${(props) => props.width}px;
    padding: 1.5rem 2rem 0;
    position: fixed;
    right: 1rem;
    top: 1rem;
    transform: translateY(0);
    transition: all 2s ease-in-out;
    z-index: 100;
`;

export const Title = styled.h3`
    font-size: 1rem;
    text-transform: uppercase;
    padding-bottom: 1rem;
    text-align: center;
    margin: 0;
    font-weight: 400;
`;


export const Backdrop = styled.div<BackdropProps>`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    backdrop-filter: blur(5px);
    z-index: 1;
    display: ${props => props.isShown ? 'flex' : 'none'};
    overflow: ${props => props.isShown && 'hidden'};
    background: linear-gradient(180deg,#12092b61 0%,rgba(255,255,255,0) 100%),#67acbb4a;
`;

export const Header = styled.div`
    display: flex;
`;

export const CloseButton = styled.button`
    background: 0;
    border: 0;
    width: 30px;
    height: 30px;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 20px;
`;

export const Icon = styled.img`
    width: 14px;
    height: 14px;
`;