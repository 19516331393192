import { Invoice } from '../../../../../../../../../models/invoice.model';
import PlanCard from '../../../plan-card/plan-card';
import InvoiceResume from './components/invoice resume/invoice resume';
import { Container } from './invoice-dashboard.styled';

interface InvoiceDashboardProps {
    invoice?: Invoice | null;
    loading?: boolean;
}

const InvoiceDashboard: React.FC<InvoiceDashboardProps> = ({ invoice, loading }) => {
    return (
        <Container>
            <PlanCard
                invoice={invoice}
                loading={loading}
            />
            <InvoiceResume
                invoice={invoice}
                loading={loading}
            />
        </Container>
    );
}

export default InvoiceDashboard;

