import styled from "styled-components";
import Wrapper from "../modal/modal";
import { DESKTOP } from "../../styles/breakpoints";
import { ScrollableContent } from "../talent/talent.styled";

const LayoutCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const Modal = styled(Wrapper)`
  height: fit-content;
  margin-top: 0.5rem;

  @media screen and (min-width: ${DESKTOP}) {
    height: fit-content;
    max-width: 726px;
    max-height: calc(100% - 3rem);
    overflow: scroll;
    ${ScrollableContent};
    border-radius: 0;
  }
`;

export const Message = styled.p`
  font-weight: 400;
  font-size: 1.125rem;
  color: #434343;
`;

export const Title = styled.p`
  margin-top: 0;
  font-weight: 700;
  font-size: 1.125rem;
  padding: 0;

  color: ${({ theme }) => theme.colors.primary.main};
`;

export const Buttons = styled(LayoutCenter)`
  padding-bottom: 1.5rem;

  button {
    margin: 0 0.5rem;
  }
`;

export const Footer = styled(LayoutCenter)``;

export const Logo = styled.img`
  width: 60px;
  margin: 2rem 0;
`;

export const Infos = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 0 1rem;
  padding: 0 1rem;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  padding-bottom: 0;
`;

export const Skill = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.primary.main};
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

export const Icon = styled.button`
  display: flex;
  border: none;
  background-color: white;
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  margin: 0;
  height: auto;

  p {
    font-weight: 400;
    padding-right: 1rem;
    padding-top: 0;
    color: #272727;
    margin: 5px;
  }
`;

export const EditField = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  min-height: 35px;
  margin: 0;
  min-height: 35px;

  p {
    font-weight: 400;
    padding-right: 1rem;
    padding-top: 0;
    color: #272727;
  }
`;

export const EditInfos = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  margin: 0.5rem 0.5rem 1.5rem;
  padding: 0 1rem;
  border: 1px solid #bf76ae;
  border-radius: 8px;
`;

export const SkillEdit = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.primary.main};
    padding-right: 0.5rem;
  }
`;

export const Range = styled.div`
  display: flex;
  align-items: center;
  max-width: 200px;
`;

export const Line = styled.div`
  align-items: center;
  background: #d9d9d9;
  display: flex;
  width: 0.688rem;
  height: 0.063rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
`;

export const Feedback = styled.div`
  display: flex;
`;

export const ErrorFeedback = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 209px;
  height: 30px;
  padding: 10px 15px;
  gap: 8px;
  background: #fdf3f2;
  border-radius: 6px;
  margin-left: 0.5rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`;

export const CloseFeedback = styled.button`
  border: none;
  background-color: #fdf3f2;
`;
