import { TABLET } from './../breakpoints';
import styled from 'styled-components';
import { DESKTOP } from '../breakpoints';


export const ViewDesktop = styled.div`
    display: none;

    @media(min-width: ${DESKTOP}) {
        display: flex;
        margin: 0 auto;
        max-width: 1440px;
    }
`;

export const ViewTablet = styled.div`
    display: none;

    @media(min-width: ${TABLET}) {
        display: flex;
    }
`;

export const ViewMobile = styled.div`
    display: flex;

    @media(min-width: ${TABLET}) {
        display: none;
    }
`;