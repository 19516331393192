import { Container, Technology } from "./technologies.styled";

const technologies = [
    'csharp',
    'css3',
    'docker',
    'git',
    'html5',
    'java',
    'js',
    'mongodb',
    'mysql',
    'nodejs',
    'php',
    'postgresql',
    'react',
];

const Technologies = () => {
    return (
        <Container>
            {technologies.map((technology, key) => (
                <Technology key={key}>
                    <svg>
                        <use xlinkHref={`/assets/img/sprite-technologies.svg#${technology}`}></use>
                    </svg>
                </Technology>
            ))}
        </Container >
    );
}

export default Technologies;