import { addDays, format } from "date-fns";
import { JobInterface, WorkModel } from "../../job-board-form.model";

export const WORK_MODELS = [
    WorkModel.PRESENTIAL,
    WorkModel.REMOTE,
    WorkModel.HYBRID,
];

const BRASIL_ID = '936e00f5-68c7-4a23-8ac8-fd9ca7ab2cea';
const JAVA_ID = 'a000ba41-408d-47be-b21a-72b97a1acded';
const CLT_ID = '8a987533-09fa-4d70-801f-3876fd5d20e5';
const NO_EXPERIENCE_ID = '71306be6-cdf4-4f7a-9fa1-3a046b429ee2';
const JUNIOR_ID = '6c7ca250-23c3-410a-9f7d-b0e88795439a';
const PORTUGUESE_ID = 'bd6d2632-4f02-48c7-92b9-11338efe97b2';
const REAL_ID = 1;

export const FORM_INITIAL_STATE: JobInterface = {
    name: "",
    description: "",
    end_date: format(addDays(new Date(), 2), "yyyy-MM-dd"),
    work_model: WorkModel.PRESENTIAL,
    hire_type: {
        id: CLT_ID,
        name: 'CLT'
    },
    country: {
        id: BRASIL_ID,
        name: 'Brasil'
    },
    experience_time: {
        id: NO_EXPERIENCE_ID,
        name: 'Sem experiência'
    },
    experience_level: {
        id: JUNIOR_ID,
        name: 'Junior'
    },
    language: {
        id: PORTUGUESE_ID,
        name: 'Português (Brasil)'
    },
    currency: {
        id: REAL_ID,
        name: 'Real(R$)'
    },
    skills: [{
        id: JAVA_ID,
        name: 'Java'
    }],
    diversitys:[]
}
